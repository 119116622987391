import React, { Component } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import { myConfig } from '../../constants/Constants';
import Select from 'react-select';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';
import cellEditFactory from 'react-bootstrap-table2-editor';
import ToolkitProvider, {
  Search,
  CSVExport,
} from 'react-bootstrap-table2-toolkit';
import DateTimeRangePicker from '@wojtekmaj/react-datetimerange-picker';

const SERVER_URL = myConfig.apiUrl;

const now = new Date();
const yesterdayBegin = new Date(
  now.getFullYear(),
  now.getMonth(),
  now.getDate(),
  now.getHours()
);
const todayNoon = new Date(
  now.getFullYear(),
  now.getMonth(),
  now.getDate(),
  now.getHours() + 1
);

toast.configure();
export class Credit extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: [yesterdayBegin, todayNoon],
      data_add_credit: [],
      input_add_money: '',
      count: 10,
    };
  }

  async componentDidMount() {
    await this.get_deposit_credit();

    // this.interval = setInterval(() => {
    //   if (this.state.count <= 0) {
    //     this.setState({
    //       count: 10,
    //     });
    //     this.setState(this.get_deposit_credit());
    //   } else {
    //     this.setState((prevState) => ({ count: prevState.count - 1 }));
    //   }
    // }, 1000);
  }

  onChange = (value) => {
    this.setState({ value });

    // var dateString = this.getCurrent(value[0]);
    // console.log(dateString);
  };

  get_deposit_credit = async () => {
    // clearInterval(this.interval);
    let URL_ENDPOINT;

    URL_ENDPOINT = `${SERVER_URL}/get_deposit_credit.php`;

    const res = await axios.get(URL_ENDPOINT);
    // console.log('res.data : ', res.data);
    if (res.data.length > 0) {
      this.setState({
        data_add_credit: res.data,
      });
    } else {
      this.setState({
        data_add_credit: [],
      });
    }
  };

  get_deposit_credit_search = async () => {
    // clearInterval(this.interval);

    let dateFrom = this.getCurrent(this.state.value[0]);
    let dateTo = this.getCurrent(this.state.value[1]);
    let URL_ENDPOINT;

    URL_ENDPOINT = `${SERVER_URL}/get_deposit_credit_search.php?start_dt=${dateFrom}&end_dt=${dateTo}`;

    const res = await axios.get(URL_ENDPOINT);
    // console.log('res.data : ', res.data);
    if (res.data.length > 0) {
      this.setState({
        data_add_credit: res.data,
      });
    } else {
      this.setState({
        data_add_credit: [],
      });
    }
  };

  getCurrent(getDate) {
    // var date = new Date();
    var date = getDate;
    var aaaa = date.getFullYear();
    var gg = date.getDate();
    var mm = date.getMonth() + 1;

    if (gg < 10) gg = '0' + gg;

    if (mm < 10) mm = '0' + mm;

    var cur_day = aaaa + '-' + mm + '-' + gg;

    var hours = date.getHours();
    var minutes = date.getMinutes();
    var seconds = date.getSeconds();

    if (hours < 10) hours = '0' + hours;

    if (minutes < 10) minutes = '0' + minutes;

    if (seconds < 10) seconds = '0' + seconds;

    return cur_day + ' ' + hours + ':' + minutes + ':' + seconds;
  }

  add_money = async () => {
    if (this.state.input_add_money.length !== 0) {
      //   console.log("this.state.input_add_money : ", this.state.input_add_money);
      //   console.log((this.state.input_add_money.match(/-/g) || []).length);
      let countString = (this.state.input_add_money.match(/-/g) || []).length;
      if (countString !== 3) {
        toast.error('กรอกข้อมูลไม่ถูกต้องกรุณาตรวจสอบ', {
          position: toast.POSITION.BOTTOM_RIGHT,
          autoClose: 5000,
        });
      } else {
        // Check after d or w is 4 digit
        let inputCommand = this.state.input_add_money;
        let cmdArr = inputCommand.split('-');

        let cmdType = cmdArr[0].charAt(0);
        let cmdUsername = cmdArr[0].substring(1, 5);
        let cmdMoney = cmdArr[1];
        let cmdBank = cmdArr[2];
        let cmdTimeSlip = cmdArr[3];

        // console.log('cmdType : ', cmdType);
        // console.log('cmdUsername : ', cmdUsername);
        // console.log('cmdMoney : ', cmdMoney);
        // console.log('cmdBank : ', cmdBank);
        // console.log('cmdTimeSlip : ', cmdTimeSlip);

        if (cmdUsername.length !== 4) {
          toast.error('username 4 ตัวเท่านั้น!!!', {
            position: toast.POSITION.BOTTOM_RIGHT,
            autoClose: 5000,
          });
        } else {
          let getUid = localStorage.getItem('uid');
          let getName = localStorage.getItem('name');
          let apiUrl = `${SERVER_URL}/add_vip_credit.php?credit_type=${cmdType}&b_usr=${cmdUsername}&b_cash=${cmdMoney}&p_bank=${cmdBank}&p_timeslip=${cmdTimeSlip}&c_name=${getName}&c_id=${getUid}`;

          const res = await axios.get(apiUrl);
          //   console.log(res.data);

          if (res.data.status === 1) {
            this.setState({
              input_add_money: '',
            });

            await this.get_deposit_credit();

            toast.success('ระบบได้เพิ่มข้อมูลเรียบร้อย', {
              position: toast.POSITION.BOTTOM_RIGHT,
              autoClose: 5000,
            });
          } else {
            toast.error('กรุณาตรวจสอบข้อมูล', {
              position: toast.POSITION.BOTTOM_RIGHT,
              autoClose: 5000,
            });
          }
        }
      }
    } else {
      toast.error('ไม่มีข้อมูล กรุณากรอกให้ครบถ้วน', {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 5000,
      });
    }
  };

  add_money_keydown = async (e) => {
    if (e.key === 'Enter') {
      if (this.state.input_add_money.length !== 0) {
        //   console.log("this.state.input_add_money : ", this.state.input_add_money);
        //   console.log((this.state.input_add_money.match(/-/g) || []).length);
        let countString = (this.state.input_add_money.match(/-/g) || []).length;
        if (countString !== 3) {
          toast.error('กรอกข้อมูลไม่ถูกต้องกรุณาตรวจสอบ', {
            position: toast.POSITION.BOTTOM_RIGHT,
            autoClose: 5000,
          });
        } else {
          // Check after d or w is 4 digit
          let inputCommand = this.state.input_add_money;
          let cmdArr = inputCommand.split('-');

          let cmdType = cmdArr[0].charAt(0);
          let cmdUsername = cmdArr[0].substring(1, 5);
          let cmdMoney = cmdArr[1];
          let cmdBank = cmdArr[2];
          let cmdTimeSlip = cmdArr[3];

          // console.log('cmdType : ', cmdType);
          // console.log('cmdUsername : ', cmdUsername);
          // console.log('cmdMoney : ', cmdMoney);
          // console.log('cmdBank : ', cmdBank);
          // console.log('cmdTimeSlip : ', cmdTimeSlip);

          if (cmdUsername.length !== 4) {
            toast.error('username 4 ตัวเท่านั้น!!!', {
              position: toast.POSITION.BOTTOM_RIGHT,
              autoClose: 5000,
            });
          } else {
            let getUid = localStorage.getItem('uid');
            let getName = localStorage.getItem('name');
            let apiUrl = `${SERVER_URL}/add_vip_credit.php?credit_type=${cmdType}&b_usr=${cmdUsername}&b_cash=${cmdMoney}&p_bank=${cmdBank}&p_timeslip=${cmdTimeSlip}&c_name=${getName}&c_id=${getUid}`;

            const res = await axios.get(apiUrl);
            //   console.log(res.data);

            if (res.data.status === 1) {
              this.setState({
                input_add_money: '',
              });

              await this.get_deposit_credit();

              toast.success('ระบบได้เพิ่มข้อมูลเรียบร้อย', {
                position: toast.POSITION.BOTTOM_RIGHT,
                autoClose: 5000,
              });
            } else {
              toast.error('กรุณาตรวจสอบข้อมูล', {
                position: toast.POSITION.BOTTOM_RIGHT,
                autoClose: 5000,
              });
            }
          }
        }
      } else {
        toast.error('ไม่มีข้อมูล กรุณากรอกให้ครบถ้วน', {
          position: toast.POSITION.BOTTOM_RIGHT,
          autoClose: 5000,
        });
      }
    }
  };

  rerun_handler = async (c_id) => {
    let URL_ENDPOINT;

    URL_ENDPOINT = `${SERVER_URL}/rerun_add_vip_credit.php?c_id=${c_id}`;

    const res = await axios.get(URL_ENDPOINT);
    // console.log('res.data : ', res.data);
    if (res.data.status === 1) {
      this.get_deposit_credit();

      toast.success('เริ่มต้นการทำใหม่!!!!', {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 5000,
      });
    } else {
      toast.error('ไม่สำเร็จ กรุณาลองใหม่อีกครั้ง', {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 5000,
      });
    }
  };

  cancel_handler = async (c_id) => {
    // kill_add_sbobet_credit
    let URL_ENDPOINT;

    URL_ENDPOINT = `${SERVER_URL}/kill_add_vip_credit.php?c_id=${c_id}`;

    const res = await axios.get(URL_ENDPOINT);
    // console.log('res.data : ', res.data);
    if (res.data.status === 1) {
      this.get_deposit_credit();

      toast.success('ยกเลิกเรียบร้อย', {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 5000,
      });
    } else {
      toast.error('ไม่สำเร็จ กรุณาลองใหม่อีกครั้ง', {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 5000,
      });
    }
  };

  render() {
    const { value } = this.state;

    const StatusFormatter = (cell, row, rowIndex) => {
      if (row.flag_status === 'wait process') {
        return (
          <div className='txt-status-1 exclamationcircle'>อยู่ระหว่างรอ</div>
        );
      } else if (row.flag_status === 'processing') {
        return <div className='txt-status-3 executecircle'>กำลังดำเนินการ</div>;
      } else if (row.flag_status === 'success') {
        return <div className='txt-status checkcircle'>สำเร็จ</div>;
      } else if (row.flag_status === 'cancelled') {
        return <div className='txt-status-2 pause-circle'>ยกเลิก</div>;
      } else {
        return <div className='txt-status-2 timescircle'>ไม่สำเร็จ</div>;
      }
    };

    const ActionFormatter = (cell, row, rowIndex) => {
      if (row.flag_status === 'cancelled') {
        return null;
      } else if (row.flag_status !== 'success') {
        return (
          <>
            <button
              className='button status bg-2'
              onClick={() => this.rerun_handler(parseInt(row.id))}
            >
              ทำใหม่
            </button>
            <button
              className='button status bg-1'
              onClick={() => this.cancel_handler(parseInt(row.id))}
            >
              ยกเลิก
            </button>
          </>
        );
      } else {
        return null;
      }
    };

    const TypeFormatter = (cell, row, rowIndex) => {
      if (row.g_type === 'Add') {
        return <div className='status bg-2'>ฝาก</div>;
      } else {
        return <div className='status bg-1'>ถอน</div>;
      }
    };

    const columnsAddCredit = [
      { dataField: 'id', text: 'id', hidden: true },
      { dataField: 'username', text: 'username', sort: true },
      {
        dataField: 'amount',
        text: 'ยอดเครดิต',
        headerAlign: 'center',
        align: 'center',
        sort: true,
      },
      {
        dataField: 'bank_name',
        text: 'ธนาคาร',
        headerAlign: 'center',
        align: 'center',
        sort: true,
      },
      {
        dataField: 'time_slip',
        text: 'วัน-เวลา',
        headerAlign: 'center',
        align: 'center',
        sort: true,
      },
      {
        dataField: 'g_type',
        text: 'ประเภท',
        headerAlign: 'center',
        align: 'center',
        sort: true,
        formatter: TypeFormatter,
      },
      {
        dataField: 'flag_status',
        text: 'สถานะ',
        headerAlign: 'center',
        align: 'center',
        sort: true,
        formatter: StatusFormatter,
      },
      {
        dataField: 'line_display_name',
        text: 'โดย',
        headerAlign: 'center',
        sort: true,
      },
      {
        dataField: 'date_added',
        text: 'เวลาที่เพิ่มข้อมูล',
        headerAlign: 'center',
        sort: true,
      },
      {
        dataField: 'action',
        text: 'Action',
        headerAlign: 'center',
        align: 'center',
        sort: true,
        formatter: ActionFormatter,
      },
    ];

    const defaultSorted = [
      {
        dataField: 'date_added',
        order: 'desc',
      },
    ];

    const { SearchBar } = Search;

    const sizePerPageOptionRenderer = ({ text, page, onSizePerPageChange }) => (
      <li key={text} role='presentation' className='dropdown-item'>
        <a
          href='#'
          tabIndex='-1'
          role='menuitem'
          data-page={page}
          onMouseDown={(e) => {
            e.preventDefault();
            onSizePerPageChange(page);
          }}
          style={{ color: 'red' }}
        >
          {text}
        </a>
      </li>
    );

    const options = {
      sizePerPageOptionRenderer,
    };

    return (
      <>
        {/* Content Header (Page header) */}
        <div className='content-header'>
          <div className='container-fluid'>
            <div className='row mb-2'>
              <div className='col-sm-6'>
                <h1 className='m-0 text-dark'>ฝาก/ถอน เครดิต</h1>
              </div>
              {/* /.col */}
              <div className='col-sm-6'>
                <ol className='breadcrumb float-sm-right'>
                  <li className='breadcrumb-item'>
                    <Link
                      to={{
                        pathname: '/Landing',
                      }}
                    >
                      Home
                    </Link>
                  </li>
                  <li className='breadcrumb-item active'>ฝาก/ถอน</li>
                </ol>
              </div>
              {/* /.col */}
            </div>
            {/* /.row */}
          </div>
          {/* /.container-fluid */}
        </div>
        {/* /.content-header */}
        <section className='content'>
          <div className='content_main'>
            <div className='container-fluid'>
              <div className='row'>
                <div className='col-12'>
                  <div className='card card-content'>
                    <div className='card-body'>
                      <div className='row'>
                        <div className='col-12 col-lg-9 col-md-7 mb-4 mb-md-0'>
                          {/* <div className='dataTables_length'>
                                  <label>Records per page:</label>
                                  <select className='form-control custom-select'>
                                    <option value={10}>10</option>
                                    <option value={25}>25</option>
                                    <option value={50}>50</option>
                                    <option value={100}>100</option>
                                  </select>
                                </div> */}
                        </div>
                        <div className='col-12 col-lg-3 col-md-5'>
                          {/* <form className='search-date d-flex align-items-center justify-content-end'>
                                  <input
                                    type='search'
                                    className='form-control'
                                    placeholder='Search...'
                                  />
                                  <button type='submit' className='btn btn-calendar bg-4'>
                                    <i className='fas fa-search' />
                                  </button>
                                </form> */}
                        </div>
                      </div>

                      <div className='table-responsive'>
                        <div className='row'>
                          <div className='col-12 col-md-12'>
                            <div
                              className='content_box'
                              style={{
                                backgroundColor: '#1f1d1d',
                                marginBottom: '20px',
                                padding: '.5rem 1.5rem',
                              }}
                            >
                              <div className='mb-2'>
                                <h4>เติม/ถอน เงินเครดิต</h4>
                              </div>
                              {/* <form className='needs-validation' noValidate> */}
                              <div className='form-row align-items-center'>
                                <div className='form-group col-12 col-md-10 mb-2 mb-md-0'>
                                  <div className='input-group'>
                                    <div className='input-group-prepend'>
                                      <span className='input-group-text'>
                                        <i className='fas fa-dollar-sign' />
                                      </span>
                                    </div>
                                    <input
                                      type='text'
                                      className='form-control'
                                      id='Deposit'
                                      placeholder='ยอดเงินที่ฝาก ตัวอย่าง : d5051-100-kb-10.20'
                                      value={this.state.input_add_money}
                                      onChange={(e) => {
                                        this.setState({
                                          input_add_money: e.target.value,
                                        });
                                      }}
                                      onKeyDown={(e) => {
                                        this.add_money_keydown(e);
                                      }}
                                      required
                                    />
                                    <div className='invalid-feedback'>
                                      คุณยังไม่ได้กรอกยอดเงินที่ฝาก
                                    </div>
                                  </div>
                                </div>
                                <div className='col-12 col-md-2'>
                                  <button
                                    className='button bg-4 text-nowrap d-block'
                                    style={{ width: '100%' }}
                                    onClick={this.add_money}
                                  >
                                    ทำรายการ
                                  </button>
                                </div>
                              </div>
                              {/* </form> */}
                            </div>
                          </div>

                          <div className='col-12 col-md-12'>
                            <div className='col-12 col-lg-9 col-md-7 mb-4 mb-md-0'>
                              <div className='dataTables_length'>
                                <label>กรุณาเลือกวันที่ :</label>
                                <DateTimeRangePicker
                                  onChange={this.onChange}
                                  value={value}
                                  className='DateTime_RangePicker'
                                />
                                <button
                                  class='button status bg-2'
                                  data-toggle='modal'
                                  data-target='#AddModal'
                                  onClick={() =>
                                    this.get_deposit_credit_search()
                                  }
                                >
                                  ค้นหา
                                </button>
                              </div>
                            </div>

                            <div className='table-responsive mt-4'>
                              <ToolkitProvider
                                keyField='id'
                                data={this.state.data_add_credit}
                                columns={columnsAddCredit}
                                search
                                exportCSV
                                striped
                                hover
                                condensed
                              >
                                {(props) => (
                                  <>
                                    <SearchBar {...props.searchProps} />
                                    {/* <hr /> */}
                                    {/* <MyExportCSV {...props.csvProps} />
                                          <hr /> */}
                                    {/* <div className='position-relative'>
                                      <div className='box-shadow'>
                                        <span>Refresh : </span>
                                        <span className='txt'>
                                          {' '}
                                          {this.state.count}
                                        </span>
                                      </div>
                                    </div> */}
                                    <BootstrapTable
                                      id='table-to-xls-1'
                                      pagination={paginationFactory(options)}
                                      defaultSorted={defaultSorted}
                                      {...props.baseProps}
                                    />
                                  </>
                                )}
                              </ToolkitProvider>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
    );
  }
}

export default Credit;
