import React, { Component } from 'react';
import axios from 'axios';
import Select from 'react-select';

import { myConfig } from '../../constants/Constants';
import { Link } from 'react-router-dom';

const SERVER_URL = myConfig.apiUrl;

const options = [
  { value: 'KBANK', label: 'ธนาคารกสิกรไทย' },
  { value: 'SCB', label: 'ธนาคารไทยพาณิชย์' },
  { value: 'TMB', label: 'ธนาคารทหารไทย' },
  { value: 'BBL', label: 'ธนาคารกรุงเทพ' },
  { value: 'BAY', label: 'ธนาคารกรุงศรีอยุธยา' },
];

export class Bank extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      dataBankEdit: [],
      checked: false,
      changeFlag: 0,
      checkedItems: new Map(),
      value: false,
      count: 15,
      inputBankName: '',
      inputUserFullname: '',
      inputBankAccNo: '',
      inputUsername: '',
      inputUserPassword: '',
      editBankId: 0,
      i_edit_bank_id: 0,
    };
    this.handleChange = this.handleChange.bind(this);
    this.onChangeInput = this.onChangeInput.bind(this);
    this.saveBank = this.saveBank.bind(this);
    this.updateBank = this.updateBank.bind(this);
  }

  async componentDidMount() {
    await this.getBankStatus();

    this.interval = setInterval(() => {
      if (this.state.count <= 0) {
        this.setState({
          count: 15,
        });
        this.setState(this.getBankStatus());
      } else {
        this.setState((prevState) => ({ count: prevState.count - 1 }));
      }
    }, 1000);
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  getBankStatus = async () => {
    // clearInterval(this.interval);
    let URL_ENDPOINT;

    URL_ENDPOINT = `${SERVER_URL}/bank_status.php`;

    const res = await axios.get(URL_ENDPOINT);
    // console.log('res.data : ', res.data);
    if (res.data.length > 0) {
      this.setState({
        data: res.data,
      });
    }
  };

  handleChange_bkk = (checked, e, id) => {
    console.log('id : ', id);
    console.log('checked : ', checked);

    let apiUrl;

    if (checked === 'false') {
      apiUrl = `${SERVER_URL}/kill_py_bank.php?b_id=${id}`;
    } else {
      apiUrl = `${SERVER_URL}/call_py_bank.php?b_id=${id}`;
    }

    const res = axios.get(apiUrl);
    this.getBankStatus();

    this.setState({
      checked: e.target.checked,
    });
  };

  handleChange_switch(e, bank_id) {
    const item = e.target.name;
    const isChecked = e.target.checked;
    this.setState((prevState) => ({
      checkedItems: prevState.checkedItems.set(item, isChecked),
    }));

    let apiUrl;
    console.log('isChecked : ', isChecked);

    if (isChecked === false) {
      apiUrl = `${SERVER_URL}/kill_py_bank.php?b_id=${parseInt(bank_id)}`;
    } else {
      apiUrl = `${SERVER_URL}/call_py_bank.php?b_id=${parseInt(bank_id)}`;
    }

    const res = axios.get(apiUrl);
    this.getBankStatus();
  }

  handleChange(bank_id, status) {
    let apiUrl;

    // console.log("status : ", status);

    if (status === 'stop') {
      apiUrl = `${SERVER_URL}/kill_py_bank.php?b_id=${parseInt(bank_id)}`;
    } else {
      apiUrl = `${SERVER_URL}/call_py_bank.php?b_id=${parseInt(bank_id)}`;
    }

    console.log('apiUrl : ', apiUrl);

    const res = axios.get(apiUrl);

    setTimeout(() => {
      this.getBankStatus();
    }, 400);
  }

  onChangeInput(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  saveBank() {
    if (
      this.state.inputBankName.length === 0 &&
      this.state.inputUserFullname.length === 0 &&
      this.state.inputBankAccNo === 0 &&
      this.state.inputUsername.length === 0 &&
      this.state.inputUserPassword.length === 0
    ) {
      alert('กรุณากรอกข้อมูลให้ครบถ้วน');
    } else {
      let url = `${SERVER_URL}/add_bank.php`;
      let param = JSON.stringify({
        bankName: this.state.inputBankName.value,
        fullName: this.state.inputUserFullname,
        bankAccountNo: this.state.inputBankAccNo,
        username: this.state.inputUsername,
        password: this.state.inputUserPassword,
      });

      // console.log('param : ', param);

      axios.post(url, param).then((data) => {
        // console.log('data : ', data);

        if (data) {
          // Check if user token found
          // console.log('data : ', data);

          if (data.data.status === 1) {
            this.getBankStatus();

            this.setState({
              inputBankName: '',
              inputUserFullname: '',
              inputBankAccNo: '',
              inputUsername: '',
              inputUserPassword: '',
            });
          }
        }
      });
    }
  }

  editBank = async (e, bank_id) => {
    let url = `${SERVER_URL}/get_bank_edit.php?bank_id=${bank_id}`;
    // console.log(url);

    const res = await axios.get(url);
    // console.log('res.data : ', res.data);
    if (res.data.length > 0) {
      this.setState({
        dataBankEdit: res.data,
        inputBankName: res.data[0].BankName,
        inputUserFullname: res.data[0].BankAccName,
        inputBankAccNo: res.data[0].BankAccNo,
        inputUsername: res.data[0].b_username,
        inputUserPassword: res.data[0].b_password,
        i_edit_bank_id: bank_id,
      });
    }
  };

  updateBank() {
    if (
      this.state.inputBankName.length === 0 &&
      this.state.inputUserFullname.length === 0 &&
      this.state.inputBankAccNo === 0 &&
      this.state.inputUsername.length === 0 &&
      this.state.inputUserPassword.length === 0
    ) {
      alert('กรุณากรอกข้อมูลให้ครบถ้วน');
    } else {
      let url = `${SERVER_URL}/bank_edit.php`;
      let param = JSON.stringify({
        bankName: this.state.inputBankName,
        fullName: this.state.inputUserFullname,
        bankAccountNo: this.state.inputBankAccNo,
        username: this.state.inputUsername,
        password: this.state.inputUserPassword,
        bank_id: this.state.i_edit_bank_id,
      });

      // console.log('param : ', param);

      axios.post(url, param).then((data) => {
        // console.log('data : ', data);

        if (data) {
          // Check if user token found
          // console.log('data : ', data);

          if (data.data.status === 1) {
            this.getBankStatus();

            this.setState({
              inputBankName: '',
              inputUserFullname: '',
              inputBankAccNo: '',
              inputUsername: '',
              inputUserPassword: '',
            });
          }
        }
      });
    }
  }

  deleteBank = () => {
    console.log('this.state.editBankId : ', this.state.editBankId);

    let url = `${SERVER_URL}/del_bank.php`;
    let param = JSON.stringify({
      bank_id: parseInt(this.state.editBankId),
    });

    axios.post(url, param).then((data) => {
      // console.log('data : ', data);

      if (data) {
        this.getBankStatus();
      }
    });
  };

  handleChangeBank = (selectedOption) => {
    this.setState({ inputBankName: selectedOption });
    // console.log(`Option selected:`, selectedOption.value);
  };

  render() {
    return (
      <>
        {/* Content Header (Page header) */}
        <div className='content-header'>
          <div className='container-fluid'>
            <div className='row mb-2'>
              <div className='col-sm-6'>
                <h1 className='m-0 text-dark'>ระบบธนาคาร</h1>
              </div>
              {/* /.col */}
              <div className='col-sm-6'>
                <ol className='breadcrumb float-sm-right'>
                  <li className='breadcrumb-item'>
                    <Link
                      to={{
                        pathname: '/Landing',
                      }}
                    >
                      Home
                    </Link>
                  </li>
                  <li className='breadcrumb-item active'>Bank</li>
                </ol>
              </div>
              {/* /.col */}
            </div>
            {/* /.row */}
          </div>
          {/* /.container-fluid */}
        </div>
        {/* /.content-header */}
        <section className='content'>
          <div className='content_main'>
            <div className='container-fluid'>
              <div className='row'>
                <div className='col-12'>
                  <div className='card card-content'>
                    <div className='card-header bg-10'>
                      <div className='d-flex justify-content-between align-items-center'>
                        <p className='card-text'>ธนาคาร</p>
                        <button
                          className='button status bg-2'
                          data-toggle='modal'
                          data-target='#AddModal'
                        >
                          เพิ่ม
                        </button>
                      </div>
                    </div>
                    <div className='card-body'>
                      <div className='row mb-3'>
                        <div className='col-12 col-lg-9 col-md-7 mb-4 mb-md-0'>
                          {/* <div className='dataTables_length'>
                        <label>Records per page:</label>
                        <select className='form-control custom-select'>
                          <option value={10}>10</option>
                          <option value={25}>25</option>
                          <option value={50}>50</option>
                          <option value={100}>100</option>
                        </select>
                      </div> */}
                        </div>
                        <div className='col-12 col-lg-3 col-md-5'>
                          {/* <form className='search-date d-flex align-items-center justify-content-end'>
                        <input
                          type='search'
                          className='form-control'
                          placeholder='Search...'
                        />
                        <button type='submit' className='btn btn-calendar bg-4'>
                          <i className='fas fa-search' />
                        </button>
                      </form> */}
                          <div className='dataTables_length justify-content-end'>
                            <div
                              className='box-shadow position-relative'
                              style={{ bottom: 0 }}
                            >
                              <span>Refresh : </span>
                              <span className='txt'> {this.state.count}</span>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className='table-responsive'>
                        <table id='sortby' className='table table-bordered'>
                          <thead className='thead-dark text-center'>
                            <tr>
                              <th scope='col' className='sortable asc'>
                                #
                              </th>
                              <th scope='col' className='sortable'>
                                ชื่อธนาคาร
                              </th>
                              <th scope='col' className='sortable'>
                                สัญลักษณ์
                              </th>
                              <th scope='col' className='sortable'>
                                ลายเซ็นต์ผู้เป็นเจ้าของบัตร
                              </th>
                              <th scope='col' className='sortable'>
                                หมายเลขบัญชีธนาคาร
                              </th>
                              <th scope='col' className='sortable'>
                                เงินเหลือบัญชี/ค่าการเตือนยอดคงเหลือ
                              </th>
                              <th scope='col' className='sortable'>
                                ปุ่มเปิด/ปิด
                              </th>
                              <th scope='col' className='sortable'>
                                สถานะ
                              </th>
                              <th scope='col' className='sortable'>
                                การจัดการ
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {this.state.data.map((bank, index) => (
                              <>
                                <tr key={index}>
                                  <td rowSpan={2} className='text-center'>
                                    {index + 1}
                                  </td>
                                  <td rowSpan={2}>{bank.NameOfBank}</td>
                                  <td rowSpan={2}>
                                    <div className='img_b'>
                                      <img
                                        src={bank.image}
                                        alt={bank.BankName}
                                      />
                                    </div>
                                  </td>
                                  <td>{bank.BankAccName}</td>
                                  <td className='text-center'>
                                    {bank.BankAccNo}
                                  </td>
                                  <td className='text-right'>
                                    {bank.total_balance}
                                  </td>
                                  <td rowSpan={2} className='text-center'>
                                    {parseInt(bank.flag) !== 0 && (
                                      <button
                                        className='button status bg-1 pause-circle'
                                        // data-toggle='modal'
                                        // data-target='#DeleteModal'
                                        onClick={() =>
                                          this.handleChange(
                                            parseInt(bank.id),
                                            'stop'
                                          )
                                        }
                                      >
                                        Stop
                                      </button>
                                    )}
                                    {parseInt(bank.flag) === 0 && (
                                      <button
                                        className='button status bg-3 play-circle'
                                        // data-toggle='modal'
                                        // data-target='#DeleteModal'
                                        onClick={() =>
                                          this.handleChange(
                                            parseInt(bank.id),
                                            'start'
                                          )
                                        }
                                      >
                                        Start
                                      </button>
                                    )}
                                  </td>
                                  <td rowSpan={2} className='text-center'>
                                    {parseInt(bank.flag) === 0 && (
                                      <div class='txt-status-2 timescircle'>
                                        Stop
                                      </div>
                                    )}
                                    {parseInt(bank.flag) === 1 && (
                                      <div className='txt-status checkcircle'>
                                        Running
                                      </div>
                                    )}
                                  </td>
                                  <td rowSpan={2} className='text-center'>
                                    <button
                                      className='button status bg-3'
                                      data-toggle='modal'
                                      data-target='#EditModal'
                                      onClick={(e) => this.editBank(e, bank.id)}
                                    >
                                      แก้ไข
                                    </button>
                                    <button
                                      className='button status bg-1'
                                      data-toggle='modal'
                                      data-target='#DeleteModal'
                                      onClick={() => {
                                        this.setState({ editBankId: bank.id });
                                      }}
                                    >
                                      ลบ
                                    </button>
                                  </td>
                                </tr>
                                <tr
                                  key={bank.id + index}
                                  style={{
                                    backgroundColor: 'rgb(31, 29, 29)',
                                    color: '#e2b920',
                                  }}
                                >
                                  <td colSpan={3} className='text-center'>
                                    เวลาที่อัพเดตครั้งสุดท้าย:{' '}
                                    {bank.date_updated}
                                  </td>
                                </tr>
                              </>
                            ))}
                          </tbody>
                        </table>
                      </div>
                      {/* <div className='row mt-4'>
                    <div className='col-sm-12 col-md-5 mb-4 mb-md-0'>
                      <div className='dataTables_info text-right text-md-left'>
                        Showing 1 to 10 of 57 entries
                      </div>
                    </div>
                    <div className='col-sm-12 col-md-7'>
                      <nav aria-label='Page navigation example'>
                        <ul className='pagination justify-content-end'>
                          <li className='page-item disabled'>
                            <a
                              className='page-link'
                              href='#'
                              tabIndex={-1}
                              aria-disabled='true'
                            >
                              Previous
                            </a>
                          </li>
                          <li className='page-item active'>
                            <a className='page-link' href='#'>
                              1
                            </a>
                          </li>
                          <li className='page-item'>
                            <a className='page-link' href='#'>
                              2
                            </a>
                          </li>
                          <li className='page-item'>
                            <a className='page-link' href='#'>
                              3
                            </a>
                          </li>
                          <li className='page-item'>
                            <a className='page-link' href='#'>
                              Next
                            </a>
                          </li>
                        </ul>
                      </nav>
                    </div>
                  </div> */}
                    </div>
                  </div>
                </div>
                {/* Modal */}

                <div className='modal_form modal fade' id='AddModal'>
                  <div className='modal-dialog modal-lg modal-dialog-centered'>
                    <div className='modal-content'>
                      <div className='modal-header'>
                        <h5
                          className='modal-title'
                          id='exampleModalCenterTitle'
                        >
                          เพิ่มธนาคาร
                        </h5>
                      </div>
                      <div className='modal-body' style={{ zIndex: 9 }}>
                        <div className='row'>
                          <div className='col-12 col-md-12'>
                            <form className='boder-style'>
                              <div className='form-group mb-3'>
                                <label htmlFor='inputAddress'>ธนาคาร</label>
                                <Select
                                  options={options}
                                  value={this.state.inputBankName}
                                  onChange={this.handleChangeBank}
                                />
                              </div>

                              <div className='form-row mb-3'>
                                <div className='form-group col-md-6'>
                                  <label htmlFor='inputUserFullname'>
                                    ชื่อ-นามสกุลเจ้าของบัญชี
                                  </label>
                                  <input
                                    type='text'
                                    className='form-control'
                                    id='inputUserFullname'
                                    placeholder='ชื่อ นามสกุล'
                                    name='inputUserFullname'
                                    value={this.state.inputUserFullname}
                                    onChange={this.onChangeInput}
                                    required
                                    data-error='Name is required'
                                  />
                                </div>
                                <div className='form-group col-md-6'>
                                  <label htmlFor='inputBankAccNo'>
                                    เลขที่บัญชี
                                  </label>
                                  <input
                                    type='text'
                                    className='form-control'
                                    id='inputBankAccNo'
                                    placeholder='เลขที่บัญชี'
                                    name='inputBankAccNo'
                                    value={this.state.inputBankAccNo}
                                    onChange={this.onChangeInput}
                                    required
                                    data-error='Name is required'
                                  />
                                </div>
                              </div>

                              <div className='form-row mb-3'>
                                <div className='form-group col-md-6'>
                                  <label htmlFor='inputEmail4'>
                                    รหัสผู้ใช้
                                  </label>
                                  <input
                                    type='text'
                                    className='form-control'
                                    id='inputUsernamet'
                                    placeholder='Username'
                                    name='inputUsername'
                                    value={this.state.inputUsername}
                                    onChange={this.onChangeInput}
                                    required
                                    // data-error='Name is required'
                                  />
                                </div>
                                <div className='form-group col-md-6'>
                                  <label htmlFor='inputPassword4'>
                                    รหัสผ่าน
                                  </label>
                                  <input
                                    type='password'
                                    className='form-control'
                                    id='inputUserPassword'
                                    placeholder='Password'
                                    name='inputUserPassword'
                                    value={this.state.inputUserPassword}
                                    onChange={this.onChangeInput}
                                    required
                                    // data-error='Name is required'
                                  />
                                </div>
                              </div>
                              {/* <div className='form-group'>
                            <label htmlFor='inputState'>เลือกกลุ่มการใช้งาน</label>
                           
                            <Select
                              value={selectedOption}
                              onChange={this.handleChange}
                              options={this.state.options}
                            />
                          </div> */}
                            </form>
                          </div>
                        </div>
                      </div>
                      <div className='modal-footer'>
                        <button
                          className='button bg-3'
                          data-dismiss='modal'
                          onClick={this.saveBank}
                        >
                          ยืนยัน
                        </button>
                        <button
                          type='button'
                          className='button bg-1'
                          data-dismiss='modal'
                        >
                          ปิด
                        </button>
                      </div>
                    </div>
                  </div>
                </div>

                {/* Modal */}
                <div className='modal_form modal fade' id='EditModal'>
                  <div className='modal-dialog modal-lg modal-dialog-centered'>
                    <div className='modal-content'>
                      <div className='modal-header'>
                        <h5
                          className='modal-title'
                          id='exampleModalCenterTitle'
                        >
                          แก้ไขธนาคาร
                        </h5>
                      </div>
                      <div className='modal-body' style={{ zIndex: 9 }}>
                        <div className='row'>
                          <div className='col-12 col-md-12'>
                            <form className='boder-style'>
                              <div className='form-group mb-3'>
                                <label htmlFor='inputAddress'>ธนาคาร</label>

                                <input
                                  type='text'
                                  className='form-control'
                                  id='inputBankName'
                                  placeholder='ธนาคาร'
                                  name='inputBankName'
                                  value={this.state.inputBankName}
                                  onChange={this.onChangeInput}
                                  required
                                  readOnly
                                  data-error='Name is required'
                                />
                              </div>

                              <div className='form-row mb-3'>
                                <div className='form-group col-md-6'>
                                  <label htmlFor='inputUserFullname'>
                                    ชื่อ-นามสกุลเจ้าของบัญชี
                                  </label>
                                  <input
                                    type='text'
                                    className='form-control'
                                    id='inputUserFullname'
                                    placeholder='ชื่อ นามสกุล'
                                    name='inputUserFullname'
                                    value={this.state.inputUserFullname}
                                    onChange={this.onChangeInput}
                                    required
                                    // readOnly
                                    data-error='Name is required'
                                  />
                                </div>
                                <div className='form-group col-md-6'>
                                  <label htmlFor='inputBankAccNo'>
                                    เลขที่บัญชี
                                  </label>
                                  <input
                                    type='text'
                                    className='form-control'
                                    id='inputBankAccNo'
                                    placeholder='เลขที่บัญชี'
                                    name='inputBankAccNo'
                                    value={this.state.inputBankAccNo}
                                    onChange={this.onChangeInput}
                                    required
                                    // readOnly
                                    data-error='Name is required'
                                  />
                                </div>
                              </div>

                              <div className='form-row mb-3'>
                                <div className='form-group col-md-6'>
                                  <label htmlFor='inputEmail4'>
                                    รหัสผู้ใช้
                                  </label>
                                  <input
                                    type='text'
                                    className='form-control'
                                    id='inputUsernamet'
                                    placeholder='Username'
                                    name='inputUsername'
                                    value={this.state.inputUsername}
                                    onChange={this.onChangeInput}
                                    required
                                    // data-error='Name is required'
                                  />
                                </div>
                                <div className='form-group col-md-6'>
                                  <label htmlFor='inputPassword4'>
                                    รหัสผ่าน
                                  </label>
                                  <input
                                    type='password'
                                    className='form-control'
                                    id='inputUserPassword'
                                    placeholder='Password'
                                    name='inputUserPassword'
                                    value={this.state.inputUserPassword}
                                    onChange={this.onChangeInput}
                                    required
                                    // data-error='Name is required'
                                  />
                                </div>
                              </div>
                              {/* <div className='form-group'>
                            <label htmlFor='inputState'>เลือกกลุ่มการใช้งาน</label>
                           
                            <Select
                              value={selectedOption}
                              onChange={this.handleChange}
                              options={this.state.options}
                            />
                          </div> */}
                            </form>
                          </div>
                        </div>
                      </div>
                      <div className='modal-footer'>
                        <button
                          className='button bg-3'
                          data-dismiss='modal'
                          onClick={this.updateBank}
                        >
                          ยืนยัน
                        </button>
                        <button
                          type='button'
                          className='button bg-1'
                          data-dismiss='modal'
                        >
                          ปิด
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                {/* Modal */}
                <div className='modal_form modal fade' id='DeleteModal'>
                  <div className='modal-dialog modal-dialog-centered modal-dialog-scrollable'>
                    <div className='modal-content'>
                      <div className='modal-header'>
                        <h1
                          className='modal-title'
                          id='exampleModalCenterTitle'
                        >
                          <div className='warning-sign'>
                            <i className='fas fa-exclamation' />
                          </div>
                        </h1>
                      </div>
                      <div className='modal-body'>
                        <h3 className='text-center'>ยืนยันการลบ?</h3>
                      </div>
                      <div className='modal-footer'>
                        <button
                          type='button'
                          className='button bg-10'
                          data-dismiss='modal'
                        >
                          ปิด
                        </button>
                        <button
                          className='button bg-1'
                          data-dismiss='modal'
                          onClick={this.deleteBank}
                        >
                          ยืนยัน
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
    );
  }
}

export default Bank;
