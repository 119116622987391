import React, { Component } from 'react';
import DateTimeRangePicker from '@wojtekmaj/react-datetimerange-picker';
import axios from 'axios';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';
import cellEditFactory from 'react-bootstrap-table2-editor';
import ToolkitProvider, {
  Search,
  CSVExport,
} from 'react-bootstrap-table2-toolkit';
import * as ReactBootStrap from 'react-bootstrap';
import { Button, Modal } from 'react-bootstrap';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { myConfig } from '../../constants/Constants';
import { Link } from 'react-router-dom';

const SERVER_URL = myConfig.apiUrl;

const now = new Date();
const yesterdayBegin = new Date(
  now.getFullYear(),
  now.getMonth(),
  now.getDate(),
  now.getHours()
);
const todayNoon = new Date(
  now.getFullYear(),
  now.getMonth(),
  now.getDate(),
  now.getHours() + 1
);

toast.configure();
export class ReCredit extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: [yesterdayBegin, todayNoon],
      data_re_credit: [],
      input_add_money: '',
      count: 10,
    };
  }

  async componentDidMount() {
    await this.get_re_credit();

    // this.interval = setInterval(() => {
    //   if (this.state.count <= 0) {
    //     this.setState({
    //       count: 10,
    //     });
    //     this.setState(this.get_deposit_credit());
    //   } else {
    //     this.setState((prevState) => ({ count: prevState.count - 1 }));
    //   }
    // }, 1000);
  }

  onChange = (value) => {
    this.setState({ value });

    // var dateString = this.getCurrent(value[0]);
    // console.log(dateString);
  };

  dateFormat(date, fstr, utc) {
    utc = utc ? 'getUTC' : 'get';
    return fstr.replace(/%[YmdHMS]/g, function (m) {
      switch (m) {
        case '%Y':
          return date[utc + 'FullYear'](); // no leading zeros required
        case '%m':
          m = 1 + date[utc + 'Month']();
          break;
        case '%d':
          m = date[utc + 'Date']();
          break;
        case '%H':
          m = date[utc + 'Hours']();
          break;
        case '%M':
          m = date[utc + 'Minutes']();
          break;
        case '%S':
          m = date[utc + 'Seconds']();
          break;
        default:
          return m.slice(1); // unknown code, remove %
      }
      // add leading zero if required
      return ('0' + m).slice(-2);
    });
  }

  getCurrent(getDate) {
    // var date = new Date();
    var date = getDate;
    var aaaa = date.getFullYear();
    var gg = date.getDate();
    var mm = date.getMonth() + 1;

    if (gg < 10) gg = '0' + gg;

    if (mm < 10) mm = '0' + mm;

    var cur_day = aaaa + '-' + mm + '-' + gg;

    var hours = date.getHours();
    var minutes = date.getMinutes();
    var seconds = date.getSeconds();

    if (hours < 10) hours = '0' + hours;

    if (minutes < 10) minutes = '0' + minutes;

    if (seconds < 10) seconds = '0' + seconds;

    return cur_day + ' ' + hours + ':' + minutes + ':' + seconds;
  }

  get_re_credit = async () => {
    let URL_ENDPOINT;

    URL_ENDPOINT = `${SERVER_URL}/get_re_credit.php?str_f=cur&str_start_date=0&str_end_date=0`;

    const res = await axios.get(URL_ENDPOINT);
    // console.log('res.data : ', res.data);
    if (res.data.length > 0) {
      this.setState({
        data_re_credit: res.data,
      });
    } else {
      this.setState({
        data_re_credit: [],
      });
    }
  };

  searchDataDep = async () => {
    // console.log('this.state.value : ', this.state.value);
    let dateFrom = this.getCurrent(this.state.value[0]);
    let dateTo = this.getCurrent(this.state.value[1]);

    if (dateFrom > dateTo) {
      alert('กรุณาใส่วันที่ให้ถูกต้อง');
    } else {
      let URL_ENDPOINT;

      URL_ENDPOINT = `${SERVER_URL}/get_re_credit.php?str_f=curr1&str_start_date=${dateFrom}&str_end_date=${dateTo}`;

      const res = await axios.get(URL_ENDPOINT);
      console.log('res.data : ', res.data);
      if (res.data.length > 0) {
        this.setState({
          data_re_credit: res.data,
        });
      } else {
        this.setState({
          data_re_credit: [],
        });
      }
    }
  };

  render() {
    const { value } = this.state;

    const { SearchBar } = Search;

    const MyExportCSV = (props) => {
      const handleClick = () => {
        props.onExport();
      };
      return (
        <div>
          <button className='btn btn-success' onClick={handleClick}>
            Export to CSV
          </button>
        </div>
      );
    };

    const columnsReCredit = [
      { dataField: 'id', text: 'id', hidden: true },
      { dataField: 'user_acc_no', text: 'Username', sort: true },
      { dataField: 'u_name', text: 'ชื่อ', sort: true },
      { dataField: 'current_balance', text: 'ยอดปัจจุยัน', sort: true },
      {
        dataField: 'current_balance_new',
        text: 'ยอดปัจจุยัน(ใหม่)',
        sort: true,
      },
      { dataField: 'balance_until', text: 'ยอดดุลจนกระทั่ง', sort: true },
      {
        dataField: 'balance_until_new',
        text: 'ยอดดุลจนกระทั่ง(ใหม่)',
        sort: true,
      },
      { dataField: 'ig_balance', text: 'IG Balance', sort: true },
      { dataField: 'ig_balance_new', text: 'IG Balance(ใหม่)', sort: true },
      {
        dataField: 'total_outstanding_sports',
        text: 'ยอดรวมเงินค้างชำระ',
        sort: true,
      },
      {
        dataField: 'total_outstanding_sports_new',
        text: 'ยอดรวมเงินค้างชำระ(ใหม่)',
        sort: true,
      },
      { dataField: 'total_bet_credit', text: 'เครดิตพนันรวม', sort: true },
      { dataField: 'total_bet_credit_new', text: 'เครดิตพนันรวม(ใหม่)', sort: true },
      { dataField: 'credit', text: 'เครดิต', sort: true },
      { dataField: 'credit_new', text: 'เครดิต(ใหม่)', sort: true },
      { dataField: 'remark', text: 'หมายเหตุ', sort: true },
      { dataField: 'date_insert', text: 'วันเวลา', sort: true },
    ];

    return (
      <>
        {/* Content Header (Page header) */}
        <div className='content-header'>
          <div className='container-fluid'>
            <div className='row mb-2'>
              <div className='col-sm-6'>
                <h1 className='m-0 text-dark'>รายงาน</h1>
              </div>
              {/* /.col */}
              <div className='col-sm-6'>
                <ol className='breadcrumb float-sm-right'>
                  <li className='breadcrumb-item'>
                    <Link
                      to={{
                        pathname: '/Landing',
                      }}
                    >
                      Home
                    </Link>
                  </li>
                  <li className='breadcrumb-item active'>Transfer</li>
                </ol>
              </div>
              {/* /.col */}
            </div>
            {/* /.row */}
          </div>
          {/* /.container-fluid */}
        </div>
        {/* /.content-header */}
        <section className='content'>
          <div className='content_main'>
            <div className='container-fluid'>
              <div className='row'>
                <div className='col-12'>
                  <div className='card card-content'>
                    <div className='card-header bg-10'>
                      <div className='d-flex justify-content-between align-items-center'>
                        <p className='card-text'>รายการ Re Credit</p>
                      </div>
                    </div>
                    <div className='card-body'>
                      <Tabs>
                        <TabList>
                          <Tab>Re Credit</Tab>
                          {/* <Tab>รายงานถอนเครดิต</Tab>
                          <Tab>รายงานดำเนินการเสร็จ</Tab>
                          <Tab>รายงานความเคลื่อนไหวแบงค์</Tab>
                          <Tab>รายการผูกบัญชีลูกค้า</Tab> */}
                        </TabList>

                        <TabPanel>
                          <div className='row mb-4'>
                            <div className='col-12 col-lg-9 col-md-7 mb-4 mb-md-0'>
                              <div className='dataTables_length'>
                                <label>กรุณาเลือกวันที่ :</label>
                                <DateTimeRangePicker
                                  onChange={this.onChange}
                                  value={value}
                                  className='DateTime_RangePicker'
                                />
                                <button
                                  class='button status bg-2'
                                  data-toggle='modal'
                                  data-target='#AddModal'
                                  onClick={() => this.searchDataDep()}
                                >
                                  ค้นหา
                                </button>
                              </div>
                            </div>

                            <div className='col-12 col-lg-3 col-md-5 mb-4 mb-md-0'>
                              <div className='d-flex align-items-center justify-content-end'>
                                <ReactHTMLTableToExcel
                                  id='test-table-xls-button'
                                  className='btn btn-calendar bg-4 w-150'
                                  table='table-to-xls-1'
                                  filename='ReportAddCredit'
                                  sheet='Sheet 1'
                                  buttonText='Download as XLS'
                                />
                              </div>
                            </div>
                            {/* <div className='col-12 col-lg-3 col-md-5'>
                          <form className='search-date d-flex align-items-center justify-content-end'>
                            <input
                              type='search'
                              className='form-control'
                              placeholder='Search...'
                            />
                            <button
                              type='submit'
                              className='btn btn-calendar bg-4'
                            >
                              <i className='fas fa-search' />
                            </button>
                          </form>
                        </div> */}
                          </div>

                          <div className='table-responsive'>
                            <ToolkitProvider
                              keyField='id'
                              data={this.state.data_re_credit}
                              columns={columnsReCredit}
                              search
                              exportCSV
                              striped
                              hover
                              condensed
                            >
                              {(props) => (
                                <>
                                  <SearchBar {...props.searchProps} />
                                  {/* <hr /> */}
                                  {/* <MyExportCSV {...props.csvProps} />
                              <hr /> */}
                                  <BootstrapTable
                                    id='table-to-xls-1'
                                    {...props.baseProps}
                                  />
                                </>
                              )}
                            </ToolkitProvider>
                          </div>
                        </TabPanel>
                      </Tabs>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
    );
  }
}

export default ReCredit;
