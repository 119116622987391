import React, { Component } from 'react';
import axios from 'axios';
import { withRouter } from 'react-router-dom';
import { PostData } from '../../service/PostData';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { myConfig } from '../../constants/Constants';

const SERVER_URL = myConfig.apiLoginUrl;

toast.configure();
export class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      username: '',
      password: '',
      redirectToReferrer: false,
    };
    this.login = this.login.bind(this);
    this.onChange = this.onChange.bind(this);

    // console.log("loging props : ", props)
  }

  componentDidMount() {
    if (localStorage.getItem('login')) {
    // if (sessionStorage.getItem('userData')) {
      this.props.history.push('/dashboard');
    }
  }

  async login() {
    // console.log('login!!!!');
    if (this.state.username && this.state.password) {
      // let url = 'https://www.apirpt.com/s186aat/kt188/login';
      let url = `${SERVER_URL}login`;
      // console.log(url);
      let param = JSON.stringify({
        username: this.state.username,
        password: this.state.password,
      });

      axios.post(url, param).then((data) => {
        // console.log('data : ', data);

        if (data) {
          if (data.data.userData) {
            sessionStorage.setItem(
              'userData',
              JSON.stringify(data.data.userData)
            );
            this.setState({ redirectToReferrer: true });

            localStorage.setItem('login', true);
            localStorage.setItem('name', data.data.userData.name);
            localStorage.setItem('uid', parseInt(data.data.userData.id));

            // this.props.history.push('/dashboard');

            PostData('login', this.state).then(async (result) => {
              let responseJson = result;
              let getName = result.userData.name;
              let getUid = result.userData.id;
      
              // console.log('responseJson : ', responseJson);
      
              if (responseJson.userData) {
                sessionStorage.setItem('userData', JSON.stringify(responseJson));
                this.setState({ redirectToReferrer: true });
      
                localStorage.setItem('login', true);
                localStorage.setItem('name', getName);
                localStorage.setItem('uid', parseInt(getUid));
      
                let url = `https://www.apirpt.com/s186aat/j888/get_group_user_per.php?u_id=${parseInt(
                  getUid
                )}`;
                // console.log(url);
      
                const res = await axios.get(url);
                // console.log('res.data : ', res.data);
                if (res.data.length > 0) {
                  // this.setState({
                  //   dataGroupUsers: res.data,
                  // });
                  this.props.changeData(res.data);

                  toast.success('เข้าสู่ระบบเรียบร้อย', {position: toast.POSITION.BOTTOM_RIGHT, autoClose: 5000});
                  this.props.history.push('/landing');
                }
              }
            });

          } else {
            if (
              data.data.error.text === 'Bad request wrong username and password'
            ) {
              // alert('Username หรือ Password ไม่ถูกต้อง !!!! ');
              // this.setState({
              //   password: ''
              // })

              toast.error('Username หรือ Password ไม่ถูกต้อง !!!! ', {position: toast.POSITION.BOTTOM_RIGHT, autoClose: 5000});
            }
          }
        }
      });

    }
  }

  onChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  goSubmit(e) {
    if (e.key === 'Enter') {
      this.login();
    }
  }

  render() {
    return (
      <div className='log-in w-100'>
        <div className='bg-login'>
          <div className='text-center'>
            <h3 className='title'>Welcome to</h3>
            <h1 className='head'>UFA - RIO77</h1>
            <h4 className='desc'>Cyber Auto Banking System</h4>
          </div>
        </div>
        <div className='form-login'>
          <div>
            <div className='logo'>
              <img src='assets/img/logo.png' alt='Cyber Auto Bank' />
            </div>
            <h1 className='head text-center mb-4'>LOG IN</h1>
            {/* <form className='needs-validation' noValidate> */}
            <div className='form-row'>
              <div className='col-12 mb-3'>
                <label htmlFor='username'>Username</label>
                <input
                  type='text'
                  className='form-control'
                  id='username'
                  placeholder='Username'
                  name='username'
                  required
                  onChange={this.onChange}
                />
                <div className='invalid-feedback'>Looks good!</div>
              </div>
              <div className='col-12 mb-3'>
                <label htmlFor='password'>Password</label>
                <input
                  type='password'
                  className='form-control'
                  id='password'
                  placeholder='Password'
                  name='password'
                  required
                  onChange={this.onChange}
                  onKeyDown={(e) => {
                    this.goSubmit(e);
                  }}
                />
                <div className='invalid-feedback'>
                  Please provide a password.
                </div>
              </div>
            </div>
            <div className='form-group form-check'>
              {/* <input
                  type='checkbox'
                  className='form-check-input'
                  id='exampleCheck1'
                />
                <label className='form-check-label' htmlFor='exampleCheck1'>
                  Remember me
                </label> */}
            </div>
            <button
              type='submit'
              className='btn btn-login'
              style={{width: "100%", background: "#e8c01d", borderRadius: "5px"}}
              onClick={() => this.login()}
            >
              เข้าสู่ระบบ
            </button>
            {/* </form> */}
            <div className='copy-right'>
              {' '}
              © 2020 | CyberAutoBank All Right Reserved
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(Login);
