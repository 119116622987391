import React, { Component } from 'react';
import axios from 'axios';

import { myConfig } from '../../constants/Constants';
import { Link } from 'react-router-dom';

const SERVER_URL = myConfig.apiUrl;

export class BankKtb extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      checked: false,
      changeFlag: 0,
      checkedItems: new Map(),
      value: false,
      count: 10,
    };
    this.handleChange = this.handleChange.bind(this);
  }

  async componentDidMount() {
    await this.getBankStatus();

    this.interval = setInterval(() => {
      if (this.state.count <= 0) {
        // clearInterval(this.interval);
        this.setState({
          count: 10,
        });
        this.setState(this.getBankStatus());
      } else {
        this.setState((prevState) => ({ count: prevState.count - 1 }));
      }
    }, 10000);
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  getBankStatus = async () => {
    // clearInterval(this.interval);
    let URL_ENDPOINT;

    URL_ENDPOINT = `${SERVER_URL}/bank_status_ktb.php`;

    const res = await axios.get(URL_ENDPOINT);
    // console.log('res.data : ', res.data);
    if (res.data.length > 0) {
      this.setState({
        data: res.data,
      });
    }
  };

  handleChange(bank_id, status) {
    let apiUrl;

    // console.log("status : ", status);

    if (status === 'stop') {
      apiUrl = `${SERVER_URL}/kill_py_bank.php?b_id=${parseInt(bank_id)}`;
    } else {
      apiUrl = `${SERVER_URL}/call_py_bank.php?b_id=${parseInt(bank_id)}`;
    }

    const res = axios.get(apiUrl);
    this.getBankStatus();
  }

  _handleKeyDown_bkk = async (
    e,
    bank_transaction_id,
    transactionDT,
    deposit,
    ShortNameEn
  ) => {
    if (e.key === 'Enter') {
      console.log('do validate : ', e.target.value.toLowerCase());
      let getUsername = e.target.value.toLowerCase();
      let line_display_name = 'Web';
      let line_id = 'Web';
      let tmpDeposit = deposit.split('.')[0];
      let tmpTranDt = transactionDT.split(' ')[1];
      let tmpTranDt2 = tmpTranDt.replace(':', '.');
      let lastTranId = parseInt(bank_transaction_id);
      let lastTranDt = tmpTranDt2.replace(':', '.');
      let lastDeposit = tmpDeposit.replace(',', '');
      let lastBankName = ShortNameEn;

      //   console.log('bank_transaction_id : ', parseInt(bank_transaction_id));
      //   console.log('transactionDT : ', transactionDT);
      //   console.log('deposit : ', lastDeposit);
      //   console.log('ShortNameEn : ', ShortNameEn);
      //   console.log('tmpTranDt : ', tmpTranDt);

      let apiUrl;

      if (getUsername.length === 4) {
        // console.log('handleKeyDown!!!!');

        if (getUsername !== 'done') {
          // https://www.finalgoal.online/bkoffice/api/credit/add_credit.php?a_usr=800a&credit=10&b_name=KBank&s_time=testing&ln=testing&l_id=testing
          apiUrl = `${SERVER_URL}/add_credit.php?a_usr=${getUsername}&credit=${lastDeposit}&b_name=${lastBankName}&s_time=${lastTranDt}&ln=${line_display_name}&l_id=${line_id}&b_trans_id=${lastTranId}`;
        } else {
          // console.log("Done")
          apiUrl = `${SERVER_URL}/add_credit2.php?a_usr=${getUsername}&credit=${lastDeposit}&b_name=${lastBankName}&s_time=${lastTranDt}&ln=${line_display_name}&l_id=${line_id}&b_trans_id=${lastTranId}`;
        }

        console.log(apiUrl);

        const res = await axios.get(apiUrl);
        // console.log(res.data);

        await this.getBankDepositWait();
        await this.getBankDepositSuccess();
        await this.getBankDepositFail();
      } else {
        alert('กรุณาใส่รหัสให้ถูกต้อง 4 หลักเท่านั้น!!!!');
      }
    }
  };

  _handleKeyDown = async (e, bank_id) => {
    let captcha_text = e.target.value;
    if (e.key === 'Enter') {
      //   console.log('handleKeyDown');
      //   console.log('bank_id : ', bank_id);
      //   console.log('captcha_text : ', captcha_text);

      let apiUrl = `${SERVER_URL}/add_captcha.php?capt_text=${captcha_text}`;
      console.log(apiUrl);
      const res = axios.get(apiUrl);
      this.getBankStatus();
    }
  };

  render() {
    return (
      <>
        {/* Content Header (Page header) */}
        <div className='content-header'>
          <div className='container-fluid'>
            <div className='row mb-2'>
              <div className='col-sm-6'>
                <h1 className='m-0 text-dark'>ระบบธนาคาร</h1>
              </div>
              {/* /.col */}
              <div className='col-sm-6'>
                <ol className='breadcrumb float-sm-right'>
                  <li className='breadcrumb-item'>
                    <Link
                      to={{
                        pathname: '/Landing',
                      }}
                    >
                      Home
                    </Link>
                  </li>
                  <li className='breadcrumb-item active'>Bank</li>
                </ol>
              </div>
              {/* /.col */}
            </div>
            {/* /.row */}
          </div>
          {/* /.container-fluid */}
        </div>
        {/* /.content-header */}
        <section className='content'>
          <div className='content_main'>
            <div className='container-fluid'>
              <div className='row'>
                <div className='col-12'>
                  <div className='card card-content'>
                    <div className='card-header bg-10'>
                      <div className='d-flex justify-content-between align-items-center'>
                        <p className='card-text'>ธนาคาร</p>
                        <button
                          className='button status bg-2'
                          data-toggle='modal'
                          data-target='#AddModal'
                        >
                          เพิ่ม
                        </button>
                      </div>
                    </div>
                    <div className='card-body'>
                      <div className='row mb-4'>
                        <div className='col-12 col-lg-9 col-md-7 mb-4 mb-md-0'>
                          {/* <div className='dataTables_length'>
                        <label>Records per page:</label>
                        <select className='form-control custom-select'>
                          <option value={10}>10</option>
                          <option value={25}>25</option>
                          <option value={50}>50</option>
                          <option value={100}>100</option>
                        </select>
                      </div> */}
                        </div>
                        <div className='col-12 col-lg-3 col-md-5'>
                          {/* <form className='search-date d-flex align-items-center justify-content-end'>
                        <input
                          type='search'
                          className='form-control'
                          placeholder='Search...'
                        />
                        <button type='submit' className='btn btn-calendar bg-4'>
                          <i className='fas fa-search' />
                        </button>
                      </form> */}
                        </div>
                      </div>
                      <div className='table-responsive'>
                        <table id='sortby' className='table table-bordered'>
                          <thead className='thead-dark text-center'>
                            <tr>
                              <th scope='col' className='sortable asc'>
                                #
                              </th>
                              <th scope='col' className='sortable'>
                                ชื่อธนาคาร
                              </th>
                              <th scope='col' className='sortable'>
                                สัญลักษณ์
                              </th>
                              <th scope='col' className='sortable'>
                                ลายเซ็นต์ผู้เป็นเจ้าของบัตร
                              </th>
                              <th scope='col' className='sortable'>
                                หมายเลขบัญชีธนาคาร
                              </th>
                              <th scope='col' className='sortable'>
                                เงินเหลือบัญชี/ค่าการเตือนยอดคงเหลือ
                              </th>
                              <th scope='col' className='sortable'>
                                ปุ่มเปิด/ปิด
                              </th>
                              <th scope='col' className='sortable'>
                                สถานะ
                              </th>
                              <th scope='col' className='sortable'>
                                Captcha
                              </th>
                              <th scope='col' className='sortable'>
                                กรอกอักษร
                              </th>
                              <th scope='col' className='sortable'>
                                การจัดการ
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {this.state.data.map((bank, index) => (
                              <>
                                <tr key={index}>
                                  <td rowSpan={2} className='text-center'>
                                    {index + 1}
                                  </td>
                                  <td rowSpan={2}>{bank.NameOfBank}</td>
                                  <td rowSpan={2}>
                                    <div className='img_b'>
                                      <img
                                        src={bank.image}
                                        alt={bank.BankName}
                                      />
                                    </div>
                                  </td>
                                  <td>{bank.BankAccName}</td>
                                  <td className='text-center'>
                                    {bank.BankAccNo}
                                  </td>
                                  <td className='text-right'>
                                    {bank.total_balance}
                                  </td>
                                  <td rowSpan={2} className='text-center'>
                                    {parseInt(bank.flag) !== 0 && (
                                      <button
                                        className='button status bg-1'
                                        // data-toggle='modal'
                                        // data-target='#DeleteModal'
                                        onClick={() =>
                                          this.handleChange(
                                            parseInt(bank.id),
                                            'stop'
                                          )
                                        }
                                      >
                                        Stop
                                      </button>
                                    )}
                                    {parseInt(bank.flag) === 0 && (
                                      <button
                                        className='button status bg-3'
                                        // data-toggle='modal'
                                        // data-target='#DeleteModal'
                                        onClick={() =>
                                          this.handleChange(
                                            parseInt(bank.id),
                                            'start'
                                          )
                                        }
                                      >
                                        Start
                                      </button>
                                    )}
                                  </td>
                                  <td rowSpan={2} className='text-center'>
                                    {parseInt(bank.flag) === 0 && (
                                      <div class='txt-status-2 timescircle'>
                                        Stop
                                      </div>
                                    )}
                                    {parseInt(bank.flag) === 1 && (
                                      <div className='txt-status checkcircle'>
                                        Running
                                      </div>
                                    )}
                                  </td>
                                  <td rowSpan={2} className='text-center'>
                                    <img
                                      src={
                                        process.env.PUBLIC_URL +
                                        '/captcha/crop_file_ktb.png'
                                      }
                                      alt='logo'
                                    />
                                  </td>
                                  <td rowSpan={2} className='text-center'>
                                    <input
                                      type='text'
                                      className='form-control'
                                      id={`inputCaptcha`}
                                      placeholder='รหัส captcha'
                                      required
                                      onKeyDown={(e) => {
                                        this._handleKeyDown(
                                          e,
                                          parseInt(bank.id)
                                        );
                                      }}
                                    />
                                  </td>
                                  <td rowSpan={2} className='text-center'>
                                    <button
                                      className='button status bg-3'
                                      data-toggle='modal'
                                      data-target='#EditModal'
                                    >
                                      แก้ไข
                                    </button>
                                    <button
                                      className='button status bg-1'
                                      data-toggle='modal'
                                      data-target='#DeleteModal'
                                    >
                                      ลบ
                                    </button>
                                  </td>
                                </tr>
                                <tr key={bank.id + index}>
                                  <td colSpan={3} className='text-center'>
                                    เวลาที่อัพเดตครั้งสุดท้าย:{' '}
                                    {bank.date_updated}
                                  </td>
                                </tr>
                              </>
                            ))}
                          </tbody>
                        </table>
                      </div>
                      {/* <div className='row mt-4'>
                    <div className='col-sm-12 col-md-5 mb-4 mb-md-0'>
                      <div className='dataTables_info text-right text-md-left'>
                        Showing 1 to 10 of 57 entries
                      </div>
                    </div>
                    <div className='col-sm-12 col-md-7'>
                      <nav aria-label='Page navigation example'>
                        <ul className='pagination justify-content-end'>
                          <li className='page-item disabled'>
                            <a
                              className='page-link'
                              href='#'
                              tabIndex={-1}
                              aria-disabled='true'
                            >
                              Previous
                            </a>
                          </li>
                          <li className='page-item active'>
                            <a className='page-link' href='#'>
                              1
                            </a>
                          </li>
                          <li className='page-item'>
                            <a className='page-link' href='#'>
                              2
                            </a>
                          </li>
                          <li className='page-item'>
                            <a className='page-link' href='#'>
                              3
                            </a>
                          </li>
                          <li className='page-item'>
                            <a className='page-link' href='#'>
                              Next
                            </a>
                          </li>
                        </ul>
                      </nav>
                    </div>
                  </div> */}
                    </div>
                  </div>
                </div>
                {/* Modal */}
                <div className='modal_form modal fade' id='AddModal'>
                  <div className='modal-dialog modal-dialog-centered modal-dialog-scrollable'>
                    <div className='modal-content'>
                      <div className='modal-header'>
                        <h5
                          className='modal-title'
                          id='exampleModalCenterTitle'
                        >
                          เพิ่ม
                        </h5>
                      </div>
                      <div className='modal-body'>
                        <form>
                          <div className='form-row'>
                            <div className='form-group col-md-6'>
                              <label htmlFor='inputEmail4'>Email</label>
                              <input
                                type='email'
                                className='form-control'
                                id='inputEmail4'
                              />
                            </div>
                            <div className='form-group col-md-6'>
                              <label htmlFor='inputPassword4'>Password</label>
                              <input
                                type='password'
                                className='form-control'
                                id='inputPassword4'
                              />
                            </div>
                          </div>
                          <div className='form-group'>
                            <label htmlFor='inputAddress'>Address</label>
                            <input
                              type='text'
                              className='form-control'
                              id='inputAddress'
                              placeholder='1234 Main St'
                            />
                          </div>
                          <div className='form-group'>
                            <label htmlFor='inputAddress2'>Address 2</label>
                            <input
                              type='text'
                              className='form-control'
                              id='inputAddress2'
                              placeholder='Apartment, studio, or floor'
                            />
                          </div>
                          <div className='form-row'>
                            <div className='form-group col-md-6'>
                              <label htmlFor='inputCity'>City</label>
                              <input
                                type='text'
                                className='form-control'
                                id='inputCity'
                              />
                            </div>
                            <div className='form-group col-md-4'>
                              <label htmlFor='inputState'>State</label>
                              <select id='inputState' className='form-control'>
                                <option selected>Choose...</option>
                                <option>...</option>
                              </select>
                            </div>
                            <div className='form-group col-md-2'>
                              <label htmlFor='inputZip'>Zip</label>
                              <input
                                type='text'
                                className='form-control'
                                id='inputZip'
                              />
                            </div>
                          </div>
                          <div className='form-group'>
                            <div className='form-check'>
                              <input
                                className='form-check-input'
                                type='checkbox'
                                id='gridCheck'
                              />
                              <label
                                className='form-check-label'
                                htmlFor='gridCheck'
                              >
                                Check me out
                              </label>
                            </div>
                          </div>
                          <button type='submit' className='button btn-primary'>
                            Sign in
                          </button>
                        </form>
                      </div>
                      <div className='modal-footer'>
                        <button
                          type='button'
                          className='button bg-1'
                          data-dismiss='modal'
                        >
                          ปิด
                        </button>
                        <button className='button bg-3'>ยืนยัน</button>
                      </div>
                    </div>
                  </div>
                </div>
                {/* Modal */}
                <div className='modal_form modal fade' id='EditModal'>
                  <div className='modal-dialog modal-dialog-centered modal-dialog-scrollable'>
                    <div className='modal-content'>
                      <div className='modal-header'>
                        <h5
                          className='modal-title'
                          id='exampleModalCenterTitle'
                        >
                          แก้ไข
                        </h5>
                      </div>
                      <div className='modal-body'>
                        <form>
                          <div className='form-row'>
                            <div className='form-group col-md-6'>
                              <label htmlFor='inputEmail4'>Email</label>
                              <input
                                type='email'
                                className='form-control'
                                id='inputEmail4'
                              />
                            </div>
                            <div className='form-group col-md-6'>
                              <label htmlFor='inputPassword4'>Password</label>
                              <input
                                type='password'
                                className='form-control'
                                id='inputPassword4'
                              />
                            </div>
                          </div>
                          <div className='form-group'>
                            <label htmlFor='inputAddress'>Address</label>
                            <input
                              type='text'
                              className='form-control'
                              id='inputAddress'
                              placeholder='1234 Main St'
                            />
                          </div>
                          <div className='form-group'>
                            <label htmlFor='inputAddress2'>Address 2</label>
                            <input
                              type='text'
                              className='form-control'
                              id='inputAddress2'
                              placeholder='Apartment, studio, or floor'
                            />
                          </div>
                          <div className='form-row'>
                            <div className='form-group col-md-6'>
                              <label htmlFor='inputCity'>City</label>
                              <input
                                type='text'
                                className='form-control'
                                id='inputCity'
                              />
                            </div>
                            <div className='form-group col-md-4'>
                              <label htmlFor='inputState'>State</label>
                              <select id='inputState' className='form-control'>
                                <option selected>Choose...</option>
                                <option>...</option>
                              </select>
                            </div>
                            <div className='form-group col-md-2'>
                              <label htmlFor='inputZip'>Zip</label>
                              <input
                                type='text'
                                className='form-control'
                                id='inputZip'
                              />
                            </div>
                          </div>
                          <div className='form-group'>
                            <div className='form-check'>
                              <input
                                className='form-check-input'
                                type='checkbox'
                                id='gridCheck'
                              />
                              <label
                                className='form-check-label'
                                htmlFor='gridCheck'
                              >
                                Check me out
                              </label>
                            </div>
                          </div>
                          <button type='submit' className='button btn-primary'>
                            Sign in
                          </button>
                        </form>
                      </div>
                      <div className='modal-footer'>
                        <button
                          type='button'
                          className='button bg-1'
                          data-dismiss='modal'
                        >
                          ปิด
                        </button>
                        <button className='button bg-3'>ยืนยัน</button>
                      </div>
                    </div>
                  </div>
                </div>
                {/* Modal */}
                <div className='modal_form modal fade' id='DeleteModal'>
                  <div className='modal-dialog modal-dialog-centered modal-dialog-scrollable'>
                    <div className='modal-content'>
                      <div className='modal-header'>
                        <h1
                          className='modal-title'
                          id='exampleModalCenterTitle'
                        >
                          <div className='warning-sign'>
                            <i className='fas fa-exclamation' />
                          </div>
                        </h1>
                      </div>
                      <div className='modal-body'>
                        <h3 className='text-center'>ยืนยันการลบ?</h3>
                      </div>
                      <div className='modal-footer'>
                        <button
                          type='button'
                          className='button bg-10'
                          data-dismiss='modal'
                        >
                          ปิด
                        </button>
                        <button className='button bg-1'>ยืนยัน</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
    );
  }
}

export default BankKtb;
