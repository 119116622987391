import React, { Component } from 'react';
import DateTimeRangePicker from '@wojtekmaj/react-datetimerange-picker';
import axios from 'axios';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';
import cellEditFactory from 'react-bootstrap-table2-editor';
import ToolkitProvider, {
  Search,
  CSVExport,
} from 'react-bootstrap-table2-toolkit';
import * as ReactBootStrap from 'react-bootstrap';
import { Button, Modal } from 'react-bootstrap';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { myConfig } from '../../constants/Constants';
import { Link } from 'react-router-dom';

const SERVER_URL = myConfig.apiUrl;

const now = new Date();
const yesterdayBegin = new Date(
  now.getFullYear(),
  now.getMonth(),
  now.getDate(),
  now.getHours()
);
const todayNoon = new Date(
  now.getFullYear(),
  now.getMonth(),
  now.getDate(),
  now.getHours() + 1
);

toast.configure();
export class ReportCredit extends Component {
  constructor(props) {
    super(props);

    this.state = {
      value: [yesterdayBegin, todayNoon],
      data_rpt: [],
      data_rpt_withdraw: [],
      dataSuccess: [],
      dataTransAll: [],
      data: [],
      data_rpt_user: [],
      show: false,
      inputUsername: '',
      inputFullname: '',
      inputNickname: '',
      inputBankname: '',
      inputBankAccNo: '',
    };

    // var dateString = this.getCurrent(yesterdayBegin);
    // console.log(dateString);

    this.onChangeInput = this.onChangeInput.bind(this);
  }

  async componentDidMount() {
    this.setState({
      data_rpt: [],
      data_rpt_withdraw: [],
    });
    await this.get_report_dep_auto();
    await this.get_report_withdarw_auto();
    await this.getBankDepositSuccess();
    await this.getBankAll();
    await this.get_user_account();
  }

  onChange = (value) => {
    this.setState({ value });

    // var dateString = this.getCurrent(value[0]);
    // console.log(dateString);
  };

  dateFormat(date, fstr, utc) {
    utc = utc ? 'getUTC' : 'get';
    return fstr.replace(/%[YmdHMS]/g, function (m) {
      switch (m) {
        case '%Y':
          return date[utc + 'FullYear'](); // no leading zeros required
        case '%m':
          m = 1 + date[utc + 'Month']();
          break;
        case '%d':
          m = date[utc + 'Date']();
          break;
        case '%H':
          m = date[utc + 'Hours']();
          break;
        case '%M':
          m = date[utc + 'Minutes']();
          break;
        case '%S':
          m = date[utc + 'Seconds']();
          break;
        default:
          return m.slice(1); // unknown code, remove %
      }
      // add leading zero if required
      return ('0' + m).slice(-2);
    });
  }

  getCurrent(getDate) {
    // var date = new Date();
    var date = getDate;
    var aaaa = date.getFullYear();
    var gg = date.getDate();
    var mm = date.getMonth() + 1;

    if (gg < 10) gg = '0' + gg;

    if (mm < 10) mm = '0' + mm;

    var cur_day = aaaa + '-' + mm + '-' + gg;

    var hours = date.getHours();
    var minutes = date.getMinutes();
    var seconds = date.getSeconds();

    if (hours < 10) hours = '0' + hours;

    if (minutes < 10) minutes = '0' + minutes;

    if (seconds < 10) seconds = '0' + seconds;

    return cur_day + ' ' + hours + ':' + minutes + ':' + seconds;
  }

  get_report_dep_auto = async () => {
    let URL_ENDPOINT;

    URL_ENDPOINT = `${SERVER_URL}/get_rpt_credit.php`;

    const res = await axios.get(URL_ENDPOINT);
    // console.log('res.data : ', res.data);
    if (res.data.length > 0) {
      this.setState({
        data_rpt: res.data,
      });
    }
  };

  searchDataDep = async () => {
    // console.log('this.state.value : ', this.state.value);
    let dateFrom = this.getCurrent(this.state.value[0]);
    let dateTo = this.getCurrent(this.state.value[1]);

    if (dateFrom > dateTo) {
      alert('กรุณาใส่วันที่ให้ถูกต้อง');
    } else {
      let URL_ENDPOINT;

      URL_ENDPOINT = `${SERVER_URL}/get_rpt_credit_date.php?date_from=${dateFrom}&date_to=${dateTo}`;

      const res = await axios.get(URL_ENDPOINT);
      // console.log('res.data : ', res.data);
      if (res.data.length > 0) {
        this.setState({
          data_rpt: res.data,
        });
      }
    }
  };

  // Withdraw API
  get_report_withdarw_auto = async () => {
    let URL_ENDPOINT;

    URL_ENDPOINT = `${SERVER_URL}/get_rpt_withdraw.php`;

    const res = await axios.get(URL_ENDPOINT);
    // console.log('res.data : ', res.data);
    if (res.data.length > 0) {
      this.setState({
        data_rpt_withdraw: res.data,
      });
    }
  };

  searchDataWithdraw = async () => {
    // console.log('this.state.value : ', this.state.value);
    let dateFrom = this.getCurrent(this.state.value[0]);
    let dateTo = this.getCurrent(this.state.value[1]);

    if (dateFrom > dateTo) {
      alert('กรุณาใส่วันที่ให้ถูกต้อง');
    } else {
      let URL_ENDPOINT;

      URL_ENDPOINT = `${SERVER_URL}/get_rpt_withdraw_date.php?date_from=${dateFrom}&date_to=${dateTo}`;

      const res = await axios.get(URL_ENDPOINT);
      // console.log('res.data : ', res.data);
      if (res.data.length > 0) {
        this.setState({
          data_rpt_withdraw: res.data,
        });
      } else {
        this.setState({
          data_rpt_withdraw: [],
        });
      }
    }
  };

  // DoncProcess
  getBankDepositSuccess = async () => {
    let URL_ENDPOINT;

    URL_ENDPOINT = `${SERVER_URL}/bank_dep_suc.php`;

    const res = await axios.get(URL_ENDPOINT);
    // console.log('res.data : ', res.data);
    if (res.data.length > 0) {
      this.setState({
        dataSuccess: res.data,
      });
    }
  };

  searchData = async () => {
    // console.log('this.state.value : ', this.state.value);
    let dateFrom = this.getCurrent(this.state.value[0]);
    let dateTo = this.getCurrent(this.state.value[1]);

    if (dateFrom > dateTo) {
      alert('กรุณาใส่วันที่ให้ถูกต้อง');
    } else {
      let URL_ENDPOINT;

      URL_ENDPOINT = `${SERVER_URL}/bank_dep_suc_date.php?date_from=${dateFrom}&date_to=${dateTo}`;

      const res = await axios.get(URL_ENDPOINT);
      // console.log('res.data : ', res.data);
      if (res.data.length > 0) {
        this.setState({
          dataSuccess: res.data,
        });
      }
    }
  };

  // Bank Transaction
  getBankAll = async () => {
    let URL_ENDPOINT;

    URL_ENDPOINT = `${SERVER_URL}/bank_dep_suc.php`;

    const res = await axios.get(URL_ENDPOINT);
    // console.log('res.data : ', res.data);
    if (res.data.length > 0) {
      this.setState({
        dataTransAll: res.data,
      });
    }
  };

  searchDataBankAll = async () => {
    // console.log('this.state.value : ', this.state.value);
    let dateFrom = this.getCurrent(this.state.value[0]);
    let dateTo = this.getCurrent(this.state.value[1]);

    if (dateFrom > dateTo) {
      alert('กรุณาใส่วันที่ให้ถูกต้อง');
    } else {
      let URL_ENDPOINT;

      URL_ENDPOINT = `${SERVER_URL}/bank_all_transaction.php?date_from=${dateFrom}&date_to=${dateTo}`;

      // console.log(URL_ENDPOINT);
      const res = await axios.get(URL_ENDPOINT);
      // console.log('res.data : ', res.data);
      if (res.data.length > 0) {
        this.setState({
          dataTransAll: res.data,
        });
      }
    }
  };

  get_user_account = async () => {
    let URL_ENDPOINT;

    URL_ENDPOINT = `${SERVER_URL}/get_rpt_user_acc.php`;

    const res = await axios.get(URL_ENDPOINT);
    // console.log('res.data : ', res.data);
    if (res.data.length > 0) {
      this.setState({
        data_rpt_user: res.data,
      });
    }
  };

  sortBy(key, tbl) {
    if (tbl === 'tbl_1') {
      this.setState({
        data_rpt: this.state.data_rpt.sort((a, b) => a > b),
      });
    }
  }

  uploadFile = ({ target: { files } }) => {
    console.log(files[0]);

    let data = new FormData();
    data.append('file', files[0]);
  };

  handleShow = () => {
    this.setState({
      show: true,
    });
  };

  handleClose = () => {
    this.setState({
      show: false,
    });
  };

  onChangeInput = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  savePlayer = () => {
    // console.log('hey');
    // console.log('this.state.inputUsername : ', this.state.inputUsername);
    // console.log('this.state.inputFullname : ', this.state.inputFullname);
    // console.log('this.state.inputNickname : ', this.state.inputNickname);
    // console.log('this.state.inputBankname : ', this.state.inputBankname);
    // console.log('this.state.inputBankAccNo : ', this.state.inputBankAccNo);

    if (
      this.state.inputUsername.length === 0 &&
      this.state.inputFullname.length === 0 &&
      this.state.inputNickname === 0 &&
      this.state.inputBankname.length === 0 &&
      this.state.inputBankAccNo.length === 0
    ) {
      alert('กรุณากรอกข้อมูลให้ครบถ้วน');
    } else {
      let url = `${SERVER_URL}/player_map.php`;
      let param = JSON.stringify({
        username: this.state.inputUsername,
        fullname: this.state.inputFullname,
        nickname: this.state.inputNickname,
        bankname: this.state.inputBankname,
        bankaccno: this.state.inputBankAccNo,
      });

      // console.log(param);

      axios.post(url, param).then((res) => {
        console.log('res : ', res);

        if (res.data.status !== 0) {
          this.get_user_account();
          this.handleClose();
        } else {
          if (res.data.status_message === 'bank account number duplicate') {
            alert('มีข้อมูลเลขบัญชีธนาคารนี้ในระบบแล้ว');
          } else if (res.data.status_message === 'username duplicate') {
            alert("มีข้อมูล 'Username' นี้ในระบบแล้ว");
          }
        }

        // if (data) {
        //   console.log('data.userData.token : ', data.data);
        // }
      });
    }
  };

  updatePlayer = (oldValue, newValue, row, column) => {
    let player_id = parseInt(row.id);
    let player_username = row.username;
    let player_fullname = row.full_name;
    let player_nickname = row.nickname;
    let player_bankname = row.bank_name;
    let player_bankaccno = row.bank_account_no;

    // console.log("column : ", column)
    // console.log("player_username : ", player_username)
    // console.log("player_fullname : ", player_fullname)
    // console.log("player_nickname : ", player_nickname)
    // console.log("player_bankname : ", player_bankname)
    // console.log("player_bankaccno : ", player_bankaccno)

    if (oldValue !== newValue) {
      if (
        player_username.length === 0 &&
        player_fullname.length === 0 &&
        player_nickname === 0 &&
        player_bankname.length === 0 &&
        player_bankaccno.length === 0
      ) {
        alert('กรุณากรอกข้อมูลให้ครบถ้วน');
      } else {
        let url = `${SERVER_URL}/player_map_edit.php`;
        let param = JSON.stringify({
          id: player_id,
          username: player_username,
          fullname: player_fullname,
          nickname: player_nickname,
          bankname: player_bankname,
          bankaccno: player_bankaccno,
          dataField: column.dataField,
        });

        // console.log(param);

        axios.post(url, param).then((res) => {
          // console.log('res : ', res);

          if (res.data.status !== 0) {
            this.get_user_account();
            this.handleClose();
          } else {
            if (res.data.status_message === 'bank account number duplicate') {
              alert('มีข้อมูลเลขบัญชีธนาคารนี้ในระบบแล้ว');
            } else if (res.data.status_message === 'username duplicate') {
              alert("มีข้อมูล 'Username' นี้ในระบบแล้ว");
            }
          }

          // if (data) {
          //   console.log('data.userData.token : ', data.data);
          // }
        });
      }
    }
  };

  handlerGetInfo = async (member_id) => {
    let URL_ENDPOINT;

    URL_ENDPOINT = `${SERVER_URL}/get_last_credit_by_user.php?u_name=${this.state.g_member_username}`;

    const res = await axios.get(URL_ENDPOINT);
    // console.log('res.data_player_last_credit : ', res.data);
    if (res.data.length > 0) {
      this.setState({
        data_player_last_credit: res.data,
      });
    }
  };

  handlerGetInfo_deposit = async (member_id) => {
    let URL_ENDPOINT;

    URL_ENDPOINT = `${SERVER_URL}/get_last_credit_deposit_by_user.php?u_name=${member_id}`;

    const res = await axios.get(URL_ENDPOINT);
    // console.log('res.data_player_last_credit : ', res.data);
    if (res.data.length > 0) {
      this.setState({
        data_player_last_credit_deposit: res.data,
      });
    } else {
      this.setState({
        data_player_last_credit_deposit: [],
      });
    }
  };

  handlerGetInfo_withdraw = async (member_id) => {
    let URL_ENDPOINT;

    URL_ENDPOINT = `${SERVER_URL}/get_last_credit_withdraw_by_user.php?u_name=${member_id}`;

    const res = await axios.get(URL_ENDPOINT);
    // console.log('res.data_player_last_credit_withdraw : ', res.data);
    if (res.data.length > 0) {
      this.setState({
        data_player_last_credit_withdraw: res.data,
      });
    } else {
      this.setState({
        data_player_last_credit_withdraw: [],
      });
    }
  };

  handlerChangeAutoFlag = async (member_id, status_flag) => {
    let URL_ENDPOINT;

    URL_ENDPOINT = `${SERVER_URL}/upd_auto_withdraw_flag.php?u_name=${member_id}&status_flag=${status_flag}`;

    const res = await axios.get(URL_ENDPOINT);
    // console.log('res.handlerChangeAutoFlag : ', res.data);

    if (res.data.status === 1) {
      await this.get_user_account();

      toast.success('ดำเนินการรียบร้อยแล้ว ', {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 5000,
      });
    } else {
      toast.error('กรุณาตรวจสอบไม่สามารถแก้ไขได้ ', {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 5000,
      });
    }

    // if (res.data.status > 0) {
    //   this.setState({
    //     data_player_last_credit_withdraw: res.data,
    //   });
    // } else {
    //   this.setState({
    //     data_player_last_credit_withdraw: [],
    //   });
    // }
  };

  render() {
    const { value } = this.state;
    // console.log('this.state.data_rpt : ', this.state.data_rpt);

    const columnsAddCredit = [
      { dataField: 'id', text: 'id', hidden: true },
      { dataField: 'username', text: 'username', sort: true },
      {
        dataField: 'add_credit_amount',
        text: 'ยอดเพิ่มเครดิต',
        sort: true,
        formatter: AddCreditFormatter,
      },
      { dataField: 'last_credit_amount', text: 'ยอดเครดิต(เดิม)', sort: true },
      {
        dataField: 'new_credit_amount',
        text: 'ยอดเครดิตพนันรวม(ใหม่)',
        sort: true,
      },
      {
        dataField: 'total_bet_credit_amount',
        text: 'ยอดเครดิตพนันรวม(เดิม)',
        sort: true,
      },
      { dataField: 'bank_name', text: 'ธนาคาร', sort: true },
      { dataField: 'time_slip', text: 'เวลาแจ้ง(สลิป)', sort: true },
      { dataField: 'line_display_name', text: 'ชื่อคนทำรายการ', sort: true },
      { dataField: 'date_insert', text: 'เวลาทำรายการ', sort: true },
      { dataField: 'remark', text: 'หมายเหตุ', sort: true },
    ];

    const columnsAddWithdraw = [
      { dataField: 'id', text: 'id', hidden: true },
      { dataField: 'username', text: 'username', sort: true },
      {
        dataField: 'withdraw_amount',
        text: 'ยอดถอนเครดิต',
        sort: true,
      },
      {
        dataField: 'prev_today_credit_amount',
        text: 'ยอดปัจจุบัน(เดิม)',
        sort: true,
      },
      {
        dataField: 'new_today_credit_amount',
        text: 'ยอดปัจจุบัน(ใหม่)',
        sort: true,
      },
      {
        dataField: 'prev_ig_balance_amount',
        text: 'ยอดIG(เดิม)',
        sort: true,
      },
      {
        dataField: 'new_ig_balance_amount',
        text: 'ยอดIG(ใหม่)',
        sort: true,
      },
      {
        dataField: 'prev_total_bet_credit_amount',
        text: 'ยอดพนันรวม(เดิม)',
        sort: true,
      },
      {
        dataField: 'new_total_bet_credit_amount',
        text: 'ยอดพนันรวม(ใหม่)',
        sort: true,
      },
      {
        dataField: 'prev_credit_amount',
        text: 'ยอดเครดิต(เดิม)',
        sort: true,
      },
      {
        dataField: 'new_credit_amount',
        text: 'ยอดเครดิต(ใหม่)',
        sort: true,
      },
      {
        dataField: 'adjust_credit_bet',
        text: 'ยอดปรับปรุง',
        sort: true,
      },
      { dataField: 'bank_name', text: 'ธนาคาร', sort: true },
      { dataField: 'time_slip', text: 'เวลาแจ้ง(ถอน)', sort: true },
      { dataField: 'line_display_name', text: 'ชื่อคนทำรายการ', sort: true },
      { dataField: 'date_insert', text: 'เวลาทำรายการ', sort: true },
      { dataField: 'remark', text: 'หมายเหตุ', sort: true },
      { dataField: 'bank_transfer_name', text: 'ธนาคารโอน', sort: true },
      { dataField: 'error_msg', text: 'เลขที่อ้างอิง', sort: true },
      { dataField: 'flag_status', text: 'สถานะโอน', sort: true },
      { dataField: 'approve_by', text: 'Approve By', sort: true },
    ];

    const columnsBankDepositSuccess = [
      {
        dataField: 'bank_transaction_id',
        text: 'bank_transaction_id',
        hidden: true,
      },
      { dataField: 'NameOfBank', text: 'ธนาคาร', sort: true },
      {
        dataField: 'image',
        text: 'สัญลักษณ์',
        sort: true,
        hidden: true,
      },
      {
        dataField: 'transactionDT',
        text: 'เวลา',
        sort: true,
      },
      {
        dataField: 'channel',
        text: 'ช่องทางโอน',
        sort: true,
      },
      {
        dataField: 'withdraw',
        text: 'รายการถอน',
        sort: true,
        hidden: true,
      },
      {
        dataField: 'deposit',
        text: 'รายการฝาก',
        sort: true,
      },
      {
        dataField: 'account_no',
        text: 'บัญชีผู้ฝาก',
        sort: true,
      },
      {
        dataField: 'other_detail',
        text: 'ช่องทาง',
        sort: true,
      },
      {
        dataField: 'member_user',
        text: 'กรอกรหัสผู้ใช้งาน',
        sort: true,
      },
    ];

    const columnsBankAll = [
      {
        dataField: 'bank_transaction_id',
        text: 'bank_transaction_id',
        hidden: true,
      },
      { dataField: 'NameOfBank', text: 'ธนาคาร', sort: true },
      {
        dataField: 'image',
        text: 'สัญลักษณ์',
        sort: true,
        hidden: true,
      },
      {
        dataField: 'transactionDT',
        text: 'เวลา',
        sort: true,
      },
      {
        dataField: 'channel',
        text: 'ช่องทางโอน',
        sort: true,
      },
      {
        dataField: 'withdraw',
        text: 'รายการถอน',
        sort: true,
        hidden: false,
      },
      {
        dataField: 'deposit',
        text: 'รายการฝาก',
        sort: true,
      },
      {
        dataField: 'account_no',
        text: 'บัญชีผู้ฝาก',
        sort: true,
      },
      {
        dataField: 'other_detail',
        text: 'ช่องทาง',
        sort: true,
      },
      {
        dataField: 'member_user',
        text: 'กรอกรหัสผู้ใช้งาน',
        sort: true,
      },
      {
        dataField: 'member_user',
        text: 'กรอกรหัสผู้ใช้งาน',
        sort: true,
      },
    ];

    const columnsUserContact = [
      { dataField: 'id', text: 'id', hidden: true },
      { dataField: 'username', text: 'username', sort: true, editable: true },
      {
        dataField: 'full_name',
        text: 'ชื่อ-นามสกุล',
        sort: true,
        editable: true,
      },
      { dataField: 'nickname', text: 'ชื่อเรียก', sort: true, editable: true },
      { dataField: 'bank_name', text: 'ธนาคาร', sort: true, editable: true },
      {
        dataField: 'bank_account_no',
        text: 'เลขที่บัญชี (xxx123456x)',
        sort: true,
        editable: true,
      },
      {
        dataField: 'date_updated',
        text: 'Last updated',
        sort: true,
        editable: true,
      }
    ];

    const { SearchBar } = Search;

    const MyExportCSV = (props) => {
      const handleClick = () => {
        props.onExport();
      };
      return (
        <div>
          <button className='btn btn-success' onClick={handleClick}>
            Export to CSV
          </button>
        </div>
      );
    };

    function AddCreditFormatter(cell, row) {
      if (row.onSale) {
        return (
          <span>
            <strong style={{ color: 'red' }}>{cell})</strong>
          </span>
        );
      }

      return <span>{cell}</span>;
    }

    function priceFormatter(cell, row) {
      if (row.onSale) {
        return (
          <span>
            <strong style={{ color: 'red' }}>$ {cell} NTD(Sales!!)</strong>
          </span>
        );
      }

      return <span>$ {cell} NTD</span>;
    }

    return (
      <>
        {/* Content Header (Page header) */}
        <div className='content-header'>
          <div className='container-fluid'>
            <div className='row mb-2'>
              <div className='col-sm-6'>
                <h1 className='m-0 text-dark'>รายงาน</h1>
              </div>
              {/* /.col */}
              <div className='col-sm-6'>
                <ol className='breadcrumb float-sm-right'>
                  <li className='breadcrumb-item'>
                    <Link
                      to={{
                        pathname: '/Landing',
                      }}
                    >
                      Home
                    </Link>
                  </li>
                  <li className='breadcrumb-item active'>รายงานเครดิต</li>
                </ol>
              </div>
              {/* /.col */}
            </div>
            {/* /.row */}
          </div>
          {/* /.container-fluid */}
        </div>
        {/* /.content-header */}
        <section className='content'>
          <div className='content_main'>
            <div className='container-fluid'>
              <div className='row'>
                <div className='col-12'>
                  <div className='card card-content'>
                    <div className='card-header bg-10'>
                      <div className='d-flex justify-content-between align-items-center'>
                        <p className='card-text'>รายการ เพิ่ม/ถอน เครดิต</p>
                      </div>
                    </div>
                    <div className='card-body'>
                      <Tabs>
                        <TabList>
                          <Tab>รายงานเพิ่มเครดิต</Tab>
                          <Tab>รายงานถอนเครดิต</Tab>
                          <Tab>รายงานดำเนินการเสร็จ</Tab>
                          <Tab>รายงานความเคลื่อนไหวแบงค์</Tab>
                          <Tab>รายการผูกบัญชีลูกค้า</Tab>
                        </TabList>

                        <TabPanel>
                          <div className='row mb-4'>
                            <div className='col-12 col-lg-9 col-md-7 mb-4 mb-md-0'>
                              <div className='dataTables_length'>
                                <label>กรุณาเลือกวันที่ :</label>
                                <DateTimeRangePicker
                                  onChange={this.onChange}
                                  value={value}
                                  className='DateTime_RangePicker'
                                />
                                <button
                                  class='button status bg-2'
                                  data-toggle='modal'
                                  data-target='#AddModal'
                                  onClick={() => this.searchDataDep()}
                                >
                                  ค้นหา
                                </button>
                              </div>
                            </div>

                            <div className='col-12 col-lg-3 col-md-5 mb-4 mb-md-0'>
                              <div className='d-flex align-items-center justify-content-end'>
                                <ReactHTMLTableToExcel
                                  id='test-table-xls-button'
                                  className='btn btn-calendar bg-4 w-150'
                                  table='table-to-xls-1'
                                  filename='ReportAddCredit'
                                  sheet='Sheet 1'
                                  buttonText='Download as XLS'
                                />
                              </div>
                            </div>
                            {/* <div className='col-12 col-lg-3 col-md-5'>
                          <form className='search-date d-flex align-items-center justify-content-end'>
                            <input
                              type='search'
                              className='form-control'
                              placeholder='Search...'
                            />
                            <button
                              type='submit'
                              className='btn btn-calendar bg-4'
                            >
                              <i className='fas fa-search' />
                            </button>
                          </form>
                        </div> */}
                          </div>

                          <div className='table-responsive'>
                            <ToolkitProvider
                              keyField='id'
                              data={this.state.data_rpt}
                              columns={columnsAddCredit}
                              search
                              exportCSV
                              striped
                              hover
                              condensed
                            >
                              {(props) => (
                                <>
                                  <SearchBar {...props.searchProps} />
                                  {/* <hr /> */}
                                  {/* <MyExportCSV {...props.csvProps} />
                              <hr /> */}
                                  <BootstrapTable
                                    id='table-to-xls-1'
                                    {...props.baseProps}
                                  />
                                </>
                              )}
                            </ToolkitProvider>
                          </div>
                        </TabPanel>

                        <TabPanel>
                          <div className='row mb-4'>
                            <div className='col-12 col-lg-9 col-md-7 mb-4 mb-md-0'>
                              <div className='dataTables_length'>
                                <label>กรุณาเลือกวันที่ :</label>
                                <DateTimeRangePicker
                                  onChange={this.onChange}
                                  value={value}
                                />
                                <button
                                  class='button status bg-2'
                                  data-toggle='modal'
                                  data-target='#AddModal'
                                  onClick={() => this.searchDataWithdraw()}
                                >
                                  ค้นหา
                                </button>
                              </div>
                            </div>

                            <div className='col-12 col-lg-3 col-md-5 mb-4 mb-md-0'>
                              <div className='d-flex align-items-center justify-content-end'>
                                <ReactHTMLTableToExcel
                                  id='test-table-xls-button'
                                  className='btn btn-calendar bg-4 w-150'
                                  table='table-to-xls-2'
                                  filename='ReportWithdrawCredit'
                                  sheet='Sheet 1'
                                  buttonText='Download as XLS'
                                />
                              </div>
                            </div>
                            {/* <div className='col-12 col-lg-3 col-md-5'>
                          <form className='search-date d-flex align-items-center justify-content-end'>
                            <input
                              type='search'
                              className='form-control'
                              placeholder='Search...'
                            />
                            <button
                              type='submit'
                              className='btn btn-calendar bg-4'
                            >
                              <i className='fas fa-search' />
                            </button>
                          </form>
                        </div> */}
                          </div>

                          <div className='table-responsive'>
                            {/* <ReactHTMLTableToExcel
                              id='test-table-xls-button'
                              className='btn btn-calendar bg-4'
                              table='table-to-xls-2'
                              filename='ReportWithdrawCredit'
                              sheet='Sheet 1'
                              buttonText='Download as XLS'
                            /> */}
                            {/* <hr /> */}
                            <ToolkitProvider
                              keyField='id'
                              data={this.state.data_rpt_withdraw}
                              columns={columnsAddWithdraw}
                              search
                              exportCSV
                              striped
                              hover
                              condensed
                            >
                              {(props) => (
                                <>
                                  <SearchBar {...props.searchProps} />
                                  {/* <hr /> */}
                                  {/* <MyExportCSV {...props.csvProps} />
                              <hr /> */}
                                  <BootstrapTable
                                    id='table-to-xls-2'
                                    {...props.baseProps}
                                  />
                                </>
                              )}
                            </ToolkitProvider>
                          </div>
                        </TabPanel>

                        <TabPanel>
                          {/* <h2>ดำเนินการแล้ว</h2> */}
                          <div className='row mb-4'>
                            <div className='col-12 col-lg-9 col-md-7 mb-4 mb-md-0'>
                              <div className='dataTables_length'>
                                <label>กรุณาเลือกวันที่ :</label>
                                <DateTimeRangePicker
                                  onChange={this.onChange}
                                  value={value}
                                />
                                <button
                                  class='button status bg-2'
                                  data-toggle='modal'
                                  data-target='#AddModal'
                                  onClick={() => this.searchData()}
                                >
                                  ค้นหา
                                </button>
                              </div>
                            </div>

                            <div className='col-12 col-lg-3 col-md-5 mb-4 mb-md-0'>
                              <div className='d-flex align-items-center justify-content-end'>
                                <ReactHTMLTableToExcel
                                  id='test-table-xls-button'
                                  className='btn btn-calendar bg-4 w-150'
                                  table='table-to-xls-3'
                                  filename='ReportBankDepositAll'
                                  sheet='Sheet 1'
                                  buttonText='Download as XLS'
                                />
                              </div>
                            </div>
                          </div>

                          <div className='table-responsive'>
                            {/* <ReactHTMLTableToExcel
                              id='test-table-xls-button'
                              className='btn btn-calendar bg-4'
                              table='table-to-xls-3'
                              filename='ReportBankDepositAll'
                              sheet='Sheet 1'
                              buttonText='Download as XLS'
                            />
                            <hr /> */}
                            <ToolkitProvider
                              keyField='id'
                              data={this.state.dataSuccess}
                              columns={columnsBankDepositSuccess}
                              search
                              exportCSV
                              striped
                              hover
                              condensed
                            >
                              {(props) => (
                                <>
                                  <SearchBar {...props.searchProps} />
                                  {/* <hr /> */}
                                  {/* <MyExportCSV {...props.csvProps} />
                              <hr /> */}
                                  <BootstrapTable
                                    id='table-to-xls-3'
                                    {...props.baseProps}
                                  />
                                </>
                              )}
                            </ToolkitProvider>
                          </div>
                        </TabPanel>

                        <TabPanel>
                          {/* <h2>ดำเนินการแล้ว</h2> */}
                          <div className='row mb-4'>
                            <div className='col-12 col-lg-9 col-md-7 mb-4 mb-md-0'>
                              <div className='dataTables_length'>
                                <label>กรุณาเลือกวันที่ :</label>
                                <DateTimeRangePicker
                                  onChange={this.onChange}
                                  value={value}
                                />
                                <button
                                  class='button status bg-2'
                                  data-toggle='modal'
                                  data-target='#AddModal'
                                  onClick={() => this.searchDataBankAll()}
                                >
                                  ค้นหา
                                </button>
                              </div>
                            </div>
                            <div className='col-12 col-lg-3 col-md-5 mb-4 mb-md-0'>
                              <div className='d-flex align-items-center justify-content-end'>
                                <ReactHTMLTableToExcel
                                  id='test-table-xls-button'
                                  className='btn btn-calendar bg-4 w-150'
                                  table='table-to-xls-4'
                                  filename='ReportBankTransaction'
                                  sheet='Sheet 1'
                                  buttonText='Download as XLS'
                                />
                              </div>
                            </div>
                          </div>
                          {/* <hr /> */}
                          <div className='table-responsive'>
                            {/* <ReactHTMLTableToExcel
                              id='test-table-xls-button'
                              className='btn btn-calendar bg-4'
                              table='table-to-xls-4'
                              filename='ReportBankTransaction'
                              sheet='Sheet 1'
                              buttonText='Download as XLS'
                            /> */}

                            <ToolkitProvider
                              keyField='id'
                              data={this.state.dataTransAll}
                              columns={columnsBankAll}
                              search
                              exportCSV
                              striped
                              hover
                              condensed
                            >
                              {(props) => (
                                <>
                                  <SearchBar {...props.searchProps} />
                                  <BootstrapTable
                                    id='table-to-xls-4'
                                    {...props.baseProps}
                                  />
                                </>
                              )}
                            </ToolkitProvider>
                          </div>
                        </TabPanel>

                        <TabPanel>
                          <div
                            className='row mb-4'
                            style={{ justifyContent: 'flex-end' }}
                          >
                            <div className=''>
                              <button
                                class='button status bg-2'
                                // data-toggle='modal'
                                // data-target='#AddPlayerModal'
                                onClick={this.handleShow}
                              >
                                เพิ่ม
                              </button>
                            </div>

                            <div className='table-responsive'>
                              <ToolkitProvider
                                keyField='id'
                                data={this.state.data_rpt_user}
                                columns={columnsUserContact}
                                search
                                exportCSV
                                striped
                                hover
                                condensed
                              >
                                {(props) => (
                                  <>
                                    <SearchBar {...props.searchProps} />
                                    <BootstrapTable
                                      id='table-to-xls-usr-contact'
                                      cellEdit={cellEditFactory({
                                        mode: 'dbclick',
                                        // blurToSave: true,
                                        afterSaveCell: (
                                          oldValue,
                                          newValue,
                                          row,
                                          column
                                        ) => {
                                          this.updatePlayer(
                                            oldValue,
                                            newValue,
                                            row,
                                            column
                                          );
                                        },
                                      })}
                                      {...props.baseProps}
                                    />
                                  </>
                                )}
                              </ToolkitProvider>
                            </div>
                            <div
                              className='modal_form modal fade'
                              id='AddPlayerModal'
                            >
                              <div
                                className='modal-body'
                                style={{ zIndex: 12 }}
                              >
                                <Modal
                                  show={this.state.show}
                                  onHide={this.handleClose}
                                >
                                  <Modal.Header closeButton>
                                    <Modal.Title>เพิ่มบัญชีลูกค้า</Modal.Title>
                                  </Modal.Header>
                                  <Modal.Body>
                                    <div className='row'>
                                      <div className='col-12'>
                                        <form className='boder-style'>
                                          <div className='form-row mb-3'>
                                            <div className='form-group col-md-3'>
                                              <label htmlFor='inputUsername'>
                                                รหัสผู้ใช้(ลูกค้า)
                                              </label>
                                              <input
                                                type='text'
                                                className='form-control'
                                                id='inputUsername'
                                                placeholder='Username'
                                                name='inputUsername'
                                                value={this.state.inputUsername}
                                                onChange={this.onChangeInput}
                                                required
                                                data-error='Name is required'
                                              />
                                            </div>
                                            <div className='form-group col-md-6'>
                                              <label htmlFor='inputFullname'>
                                                ชื่อ-นามสกุล
                                              </label>
                                              <input
                                                type='text'
                                                className='form-control'
                                                id='inputFullname'
                                                placeholder='ชื่อ-นามสกุล ตรงกับเลขที่บัญชี'
                                                name='inputFullname'
                                                value={this.state.inputFullname}
                                                onChange={this.onChangeInput}
                                                required
                                                data-error='Name is required'
                                              />
                                            </div>
                                            <div className='form-group col-md-3'>
                                              <label htmlFor='inputNickname'>
                                                ชื่อเรียก
                                              </label>
                                              <input
                                                type='text'
                                                className='form-control'
                                                id='inputNickname'
                                                placeholder='ชื่อเรียก'
                                                name='inputNickname'
                                                value={this.state.inputNickname}
                                                onChange={this.onChangeInput}
                                                required
                                                data-error='Name is required'
                                              />
                                            </div>
                                          </div>

                                          <div className='form-row mb-3'>
                                            <div className='form-group col-md-6'>
                                              <label htmlFor='inputBankname'>
                                                ธนาคาร
                                              </label>
                                              <input
                                                type='text'
                                                className='form-control'
                                                id='inputBankname'
                                                placeholder='ชื่อธนาคาร'
                                                name='inputBankname'
                                                value={this.state.inputBankname}
                                                onChange={this.onChangeInput}
                                                required
                                                data-error='Name is required'
                                              />
                                            </div>
                                            <div className='form-group col-md-6'>
                                              <label htmlFor='inputBankAccNo'>
                                                เลขที่บัญชี (ไม่ต้องใส่ - )
                                              </label>
                                              <input
                                                type='text'
                                                className='form-control'
                                                id='inputBankAccNo'
                                                placeholder='เลขที่บัญชี xxxxxxxxxx'
                                                name='inputBankAccNo'
                                                value={
                                                  this.state.inputBankAccNo
                                                }
                                                onChange={this.onChangeInput}
                                                required
                                                data-error='Name is required'
                                              />
                                            </div>
                                          </div>
                                        </form>
                                      </div>
                                    </div>
                                  </Modal.Body>
                                  <Modal.Footer>
                                    <Button
                                      variant='secondary'
                                      onClick={this.handleClose}
                                    >
                                      ปิด
                                    </Button>
                                    <Button
                                      variant='primary'
                                      onClick={this.savePlayer}
                                    >
                                      บันทึก
                                    </Button>
                                  </Modal.Footer>
                                </Modal>
                              </div>
                            </div>
                          </div>
                        </TabPanel>
                      </Tabs>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
    );
  }
}

export default ReportCredit;
