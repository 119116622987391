import React, { Component } from 'react';
import axios from 'axios';
import MultiSelect from 'react-multi-select-component';
import { myConfig } from '../../constants/Constants';
import { Link } from 'react-router-dom';
import { CheckRole } from '../../components/Checkbox/CheckRole';

const SERVER_URL = myConfig.apiUrl;
const SERVER_URL_AUTH = myConfig.apiLoginUrl;

export class Member extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dataPermissionCode: [],
      dataGroup: [],
      dataUsers: [],
      selectedOption: null,
      options: [],
      optionsPermission: [],
      selectPermission: [],
      inputGroupName: '',
      roles: [],
      rolesEdit: [],
      inputUserNickname: '',
      inputUsername: '',
      inputUserPassword: '',
      returnMsg: '',
      returnUsernameMsg: '',
      dataEditUsers: [],
      editUserId: 0,
      inputUserNicknameEdit: '',
      inputUsernameEdit: '',
      inputUserPasswordEdit: '',
      checkPermissionList: [],
    };

    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleInputChangeEdit = this.handleInputChangeEdit.bind(this);
    this.onChangeInput = this.onChangeInput.bind(this);
    this.saveMemver = this.saveMemver.bind(this);
  }

  async componentDidMount() {
    await this.getPermissionCode();
    await this.getGroupPermisson();
    await this.getGroupPermissonSelect();
    await this.getUser();
    await this.getermissonCodeSelect();
  }

  getPermissionCode = async () => {
    // clearInterval(this.interval);
    let URL_ENDPOINT;

    URL_ENDPOINT = `${SERVER_URL}/get_permission.php`;

    const res = await axios.get(URL_ENDPOINT);
    // console.log('res.data : ', res.data);
    if (res.data.length > 0) {
      this.setState({
        dataPermissionCode: res.data,
      });
    }
  };

  getGroupPermisson = async () => {
    // clearInterval(this.interval);
    let URL_ENDPOINT;

    URL_ENDPOINT = `${SERVER_URL}/get_group_per.php`;

    const res = await axios.get(URL_ENDPOINT);
    // console.log('res.data : ', res.data);
    if (res.data.length > 0) {
      this.setState({
        dataGroup: res.data,
      });
    }
  };

  getGroupPermissonSelect = async () => {
    // clearInterval(this.interval);
    let URL_ENDPOINT;

    URL_ENDPOINT = `${SERVER_URL}/get_group_per_select.php`;

    const res = await axios.get(URL_ENDPOINT);
    // console.log('res.data : ', res.data);
    if (res.data.length > 0) {
      this.setState({
        options: res.data,
      });
    }
  };

  getermissonCodeSelect = async () => {
    // clearInterval(this.interval);
    let URL_ENDPOINT;

    URL_ENDPOINT = `${SERVER_URL}/get_permission_select.php`;

    const res = await axios.get(URL_ENDPOINT);
    // console.log('res.data : ', res.data);
    if (res.data.length > 0) {
      this.setState({
        optionsPermission: res.data,
      });
    }
  };

  getUser = async () => {
    // clearInterval(this.interval);
    let URL_ENDPOINT;

    URL_ENDPOINT = `${SERVER_URL}/get_users.php`;

    const res = await axios.get(URL_ENDPOINT);
    // console.log('res.data : ', res.data);
    if (res.data.length > 0) {
      this.setState({
        dataUsers: res.data,
      });
    }
  };

  handleChange = (selectedOption) => {
    this.setState({ selectedOption });
    // console.log(`Option selected:`, selectedOption);
  };

  handleChangePermissionCode = (selectPermission) => {
    this.setState({ selectPermission });
    // console.log(`Option selected permission code:`, selectPermission);
  };

  updateInputValueGroupName = (e) => {
    this.setState({
      inputGroupName: e.target.value,
    });
  };

  onChangeInput(e) {
    this.setState({ [e.target.name]: e.target.value });

    if (e.target.name === 'inputUserPassword') {
      let url = `${SERVER_URL}/u_check_uname.php`;
      let param = JSON.stringify({
        u_name: this.state.inputUsername,
      });

      axios.post(url, param).then((data) => {
        try {
          console.log('data : ', parseInt(data.data[0].id));
          let id = parseInt(data.data[0].id);

          if (id !== 0) {
            this.setState(
              {
                returnUsernameMsg: 'Username นี้ไม่สามารถใช้งานได้',
              },
              () => {
                // alert("Username นี้ไม่สามารถใช้งานได้")
              }
            );
          }
        } catch {
          this.setState({
            returnUsernameMsg: '',
          });
        }
      });
    }
  }

  saveGroupPermission() {
    // console.log(this.state.selectPermission);

    // const requestOptions = {
    //   method: 'POST',
    //   headers: { 'Content-Type': 'application/json' },
    //   body: JSON.stringify({
    //     groupName: this.state.inputGroupName,
    //     groupPermission: this.state.selectPermission,
    //   }),
    // };

    let url = `${SERVER_URL}/ins_user_group.php'`;
    let param = JSON.stringify({
      groupName: this.state.inputGroupName,
      groupPermission: this.state.selectPermission,
    });

    axios.post(url, param).then((data) => {
      // console.log('data : ', data);
      this.setState(
        {
          inputUserNickname: '',
          inputUsername: '',
          inputUserPassword: '',
          returnMsg: '',
          selectPermission: [],
        },
        () => {
          this.getUser();
          this.onCloseModal();
        }
      );
    });
  }

  onCloseModal = () => {
    console.log('onCloseModal');
    this.setState({ inputGroupName: '', selectPermission: [] });
  };

  handlerChangeFlag = async (flagStatus, user_id) => {
    // console.log('user_id : ', parseInt(user_id));
    let userId = parseInt(user_id);

    let url = `${SERVER_URL}/m_update_flag.php?flag=${flagStatus}&u_id=${userId}`;
    console.log('url : ', url);
    await axios.get(url);
    // console.log('res.data : ', res.data);

    setTimeout(() => {
      this.getUser();
    }, 500);
  };

  handleInputChange(e) {
    if (e.target.checked) {
      this.setState({
        roles: [...this.state.roles, e.target.value],
      });
    } else {
      let remove = this.state.roles.indexOf(e.target.value);
      this.setState({
        roles: this.state.roles.filter((_, i) => i !== remove),
      });
    }
  }

  handleInputChangeEdit(e) {
    if (e.target.checked) {
      this.setState({
        rolesEdit: [...this.state.rolesEdit, e.target.value],
      });
    } else {
      let remove = this.state.rolesEdit.indexOf(e.target.value);
      this.setState({
        rolesEdit: this.state.rolesEdit.filter((_, i) => i !== remove),
      });
    }

    setTimeout(() => {
      console.log("this.state.rolesEdit : ", this.state.rolesEdit)
    },1000)
  }

  saveMemver() {
    // https://www.finalgoal.online/s186aat/kt188/restful/signup

    if (
      this.state.inputUserNickname.length === 0 &&
      this.state.inputUsername.length === 0 &&
      this.state.inputUserPassword === 0 &&
      this.state.roles.length === 0
    ) {
      alert('กรุณากรอกข้อมูลให้ครบถ้วน');
    } else {
      let url = `${SERVER_URL_AUTH}signup`;
      let param = JSON.stringify({
        name: this.state.inputUserNickname,
        username: this.state.inputUsername,
        password: this.state.inputUserPassword,
        groupPermission: this.state.roles,
      });

      axios.post(url, param).then((data) => {
        // console.log('data : ', data);

        if (data) {
          // Check if user token found
          // console.log("data.userData.token : ", data.data);

          if (data.data.userData) {
            this.setState(
              {
                inputGroupName: '',
                selectPermission: [],
              },
              () => {
                this.getUser();
                this.onCloseModal();
              }
            );
          } else {
            if (data.data.error.text === 'Enter username is duplicate') {
              this.setState({
                returnMsg: 'Username ซ้ำ!! โปรดใช้ Username อื่น',
              });
            } else if (
              data.data.error.text ===
              'Enter valid data username & password not found'
            ) {
              this.setState({
                returnMsg: 'กรุณาระบุ Username และ Password',
              });
            }
          }
        }
      });
    }
  }

  editUser = async (e, user_id) => {
    // https://www.apirpt.com/s186aat/j888/get_users_edit.php?u_id=3
    // console.log('user_id : ', user_id);
    // console.log("e : ", e);

    let url = `${SERVER_URL}/get_users_edit.php?u_id=${user_id}`;
    // console.log(url);

    const res = await axios.get(url);
    // console.log('res.data : ', res.data);
    if (res.data.length > 0) {
      this.setState(
        {
          dataEditUsers: res.data,
          inputUserNicknameEdit: res.data[0].name,
          inputUsernameEdit: res.data[0].username,
          inputUserPasswordEdit: res.data[0].password,
          editUserId: parseInt(res.data[0].id),
          checkPermissionList: res.data[0].group_permission,
        }
      );

      let group_per = res.data[0].group_permission

      if (group_per.length > 0) {
        group_per.forEach(item => {
          if (item.isChecked === '1') {
            this.setState({
              rolesEdit: [...this.state.rolesEdit, item.CodeID],
            })
          }
        });
      }

    }
  };

  updateMember() {
    // console.log('updateMember');
    if (
      this.state.inputUserNicknameEdit.length === 0 &&
      this.state.inputUsernameEdit.length === 0 &&
      this.state.inputUserPasswordEdit === 0 &&
      this.state.rolesEdit.length === 0
    ) {
      alert('กรุณากรอกข้อมูลให้ครบถ้วน');
    } else {
      let url = `${SERVER_URL}/user_edit.php`;
      let param = JSON.stringify({
        name: this.state.inputUserNicknameEdit,
        username: this.state.inputUsernameEdit,
        password: this.state.inputUserPasswordEdit,
        groupPermission: this.state.rolesEdit,
        u_id: this.state.editUserId,
      });

      // console.log("param : ", param);

      axios.post(url, param).then((data) => {
        // console.log('data : ', data);

        if (data) {
         this.getUser();
        }
      });
    }
  }

  deleteUser = () => {
    // console.log("this.state.editUserId : ", this.state.editUserId)

    let url = `${SERVER_URL}/del_edit.php`;
      let param = JSON.stringify({
        u_id: this.state.editUserId,
      });

      axios.post(url, param).then((data) => {
        // console.log('data : ', data);

        if (data) {
         this.getUser();
        }
      });
  }

  render() {
    return (
      <>
        {/* Content Header (Page header) */}
        <div className='content-header'>
          <div className='container-fluid'>
            <div className='row mb-2'>
              <div className='col-sm-6'>
                <h1 className='m-0 text-dark'>ระบบสมาชิก</h1>
              </div>
              {/* /.col */}
              <div className='col-sm-6'>
                <ol className='breadcrumb float-sm-right'>
                  <li className='breadcrumb-item'>
                    <Link
                      to={{
                        pathname: '/Landing',
                      }}
                    >
                      Home
                    </Link>
                  </li>
                  <li className='breadcrumb-item active'>ระบบสมาชิก</li>
                </ol>
              </div>
              {/* /.col */}
            </div>
            {/* /.row */}
          </div>
          {/* /.container-fluid */}
        </div>
        {/* /.content-header */}
        <section className='content'>
          <div className='content_main'>
            <div className='container-fluid'>
              <div className='row'>
                <div className='col-12'>
                  <div className='card card-content'>
                    <div className='card-header bg-10'>
                      <div className='d-flex justify-content-between align-items-center'>
                        <p className='card-text'>จัดการสมาชิก</p>
                        {/* <button
                      className='button status bg-2'
                      data-toggle='modal'
                      data-target='#AddModal'
                    >
                      เพิ่ม
                    </button> */}
                      </div>
                    </div>
                    <div className='card-body'>
                      <div className='table-responsive'>
                        <div className='row mb-3'>
                          <div className='col-12 col-lg-9 col-md-7 mb-4 mb-md-0'></div>
                          <div className='col-12 col-lg-3 col-md-5'>
                            <div className='dataTables_length justify-content-end'>
                              <div
                                className='position-relative'
                                style={{ bottom: 0 }}
                              >
                                <button
                                  className='button status bg-2'
                                  data-toggle='modal'
                                  data-target='#AddUserModal'
                                >
                                  เพิ่ม
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>

                        <table id='sortby' className='table table-bordered'>
                          <thead className='thead-dark text-center'>
                            <tr>
                              <th scope='col'>#</th>
                              <th scope='col'>ชื่อเรียกแทน</th>
                              <th scope='col'>Username</th>
                              <th scope='col'>Password</th>
                              <th scope='col'>สถานะ</th>
                              <th scope='col'>รายละเอียดกลุ่ม</th>
                              <th scope='col'></th>
                            </tr>
                          </thead>
                          <tbody>
                            {this.state.dataUsers.map((item) => (
                              <tr key={item.user_id}>
                                <td className='text-center'>
                                  {parseInt(item.user_id)}
                                </td>
                                <td className='text-left'>{item.name}</td>
                                <td className='text-left'>{item.username}</td>
                                <td className='text-left'>ไม่แสดง</td>
                                {/* <td className='text-center'>{item.active}</td> */}
                                {item.active === 'Active' &&
                                  parseInt(item.user_id) === 1 && (
                                    <td className='text-center'>
                                      <button
                                        className='border border-info'
                                        style={{ borderRadius: '12px' }}
                                        onClick={() =>
                                          alert(
                                            'User นี้ไม่สามารถทำการแก้ไขได้'
                                          )
                                        }
                                      >
                                        ปกติ
                                      </button>
                                    </td>
                                  )}
                                {item.active === 'Active' &&
                                  parseInt(item.user_id) !== 1 && (
                                    <td className='text-center'>
                                      <button
                                        className='border border-info'
                                        style={{ borderRadius: '12px' }}
                                        onClick={() =>
                                          this.handlerChangeFlag(
                                            'disable',
                                            item.user_id
                                          )
                                        }
                                      >
                                        ปกติ
                                      </button>
                                    </td>
                                  )}
                                {item.active === 'Disable' && (
                                  <td className='text-center'>
                                    <button
                                      className='border border-danger'
                                      style={{ borderRadius: '12px' }}
                                      onClick={() =>
                                        this.handlerChangeFlag(
                                          'active',
                                          item.user_id
                                        )
                                      }
                                    >
                                      ห้ามใช้งาน
                                    </button>
                                  </td>
                                )}
                                <td className='text-left'>{item.RoleDetail}</td>
                                <td className='text-center'>
                                  {parseInt(item.user_id) !== 1 && (
                                    <>
                                      <button
                                        className='button status bg-3 mr-2'
                                        data-toggle='modal'
                                        data-target='#EditModal'
                                        onClick={(e) =>
                                          this.editUser(e, item.user_id)
                                        }
                                      >
                                        แก้ไข
                                      </button>
                                      <button
                                        className='button status bg-1'
                                        data-toggle='modal'
                                        data-target='#DeleteModal'
                                        onClick={() => {this.setState({ editUserId: item.user_id })}}
                                      >
                                        ลบ
                                      </button>
                                    </>
                                  )}
                                  {parseInt(item.user_id) === 1 && (
                                    <>
                                      <button
                                        className='button status bg-3 mr-2'
                                        data-toggle='modal'
                                        // data-target='#EditModal'
                                        onClick={() => {
                                          alert(
                                            'User นี้ไม่สามารถทำการแก้ไขได้'
                                          );
                                        }}
                                      >
                                        แก้ไข
                                      </button>
                                      <button
                                        className='button status bg-1'
                                        data-toggle='modal'
                                        // data-target='#DeleteModal'
                                        onClick={() => {
                                          alert(
                                            'User นี้ไม่สามารถทำการแก้ไขได้'
                                          );
                                        }}
                                      >
                                        ลบ
                                      </button>
                                    </>
                                  )}
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
                {/* User Add/Edit/Delete */}
                {/* Modal --- User Tab 1*/}
                <div className='modal_form modal fade' id='AddUserModal'>
                  <div className='modal-dialog modal-lg modal-dialog-centered'>
                    <div className='modal-content'>
                      <div className='modal-header'>
                        <h5
                          className='modal-title'
                          id='exampleModalCenterTitle'
                        >
                          เพิ่มสมาชิก
                        </h5>
                      </div>
                      <div className='modal-body' style={{ zIndex: 9 }}>
                        <div className='row'>
                          <div className='col-12 col-md-6'>
                            <form className='boder-style'>
                              <div className='form-group mb-3'>
                                <label htmlFor='inputAddress'>
                                  ชื่อเรียกแทน
                                </label>
                                <input
                                  type='text'
                                  className='form-control'
                                  id='inputUserNicknamet'
                                  placeholder='กรุณาระบุชื่อเรียก'
                                  name='inputUserNickname'
                                  value={this.state.inputUserNickname}
                                  onChange={this.onChangeInput}
                                  required
                                  data-error='Name is required'
                                />
                              </div>

                              <div className='form-row mb-3'>
                                <div className='form-group col-md-6'>
                                  <label htmlFor='inputEmail4'>
                                    รหัสผู้ใช้
                                  </label>
                                  <input
                                    type='text'
                                    className='form-control'
                                    id='inputUsernamet'
                                    placeholder='Username'
                                    name='inputUsername'
                                    value={this.state.inputUsername}
                                    onChange={this.onChangeInput}
                                    required
                                    data-error='Name is required'
                                  />
                                </div>
                                <div className='form-group col-md-6'>
                                  <label htmlFor='inputPassword4'>
                                    รหัสผ่าน
                                  </label>
                                  <input
                                    type='password'
                                    className='form-control'
                                    id='inputUserPassword'
                                    placeholder='Password'
                                    name='inputUserPassword'
                                    value={this.state.inputUserPassword}
                                    onChange={this.onChangeInput}
                                    required
                                    data-error='Name is required'
                                  />
                                </div>
                              </div>
                              {/* <div className='form-group'>
                            <label htmlFor='inputState'>เลือกกลุ่มการใช้งาน</label>
                           
                            <Select
                              value={selectedOption}
                              onChange={this.handleChange}
                              options={this.state.options}
                            />
                          </div> */}
                            </form>
                          </div>
                          {/* ฝั่ง Roles */}
                          <div className='col-12 col-md-6'>
                            <form className='boder-style'>
                              <h5 className='mb-3'>Roles</h5>
                              {this.state.optionsPermission.map((item, idx) => (
                                <div
                                  className='custom-control custom-checkbox checkbox-lg'
                                  key={idx}
                                >
                                  <input
                                    type='checkbox'
                                    className='custom-control-input'
                                    id={`checkbox-${parseInt(item.value)}`}
                                    value={parseInt(item.value)}
                                    onChange={this.handleInputChange}
                                  />
                                  <label
                                    className='custom-control-label'
                                    htmlFor={`checkbox-${parseInt(item.value)}`}
                                  >
                                    {item.label}
                                  </label>
                                </div>
                              ))}

                              {/* <h5 className="mt-3">***Agent ไม่ต้องเลือกในส่วนนี้***</h5>

                          <div className="form-group row">
                            <label for="inputPercent" className="col-sm-5 col-form-label">Add Percent</label>
                            <div className="col-sm-5">
                              <input type="number" className="form-control" id="inputPercent"/>
                            </div>
                          </div> */}
                            </form>
                          </div>
                        </div>
                      </div>
                      <div className='modal-footer'>
                        <button
                          className='button bg-3'
                          data-dismiss='modal'
                          onClick={this.saveMemver}
                        >
                          ยืนยัน
                        </button>
                        <button
                          type='button'
                          className='button bg-1'
                          data-dismiss='modal'
                        >
                          ปิด
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                {/* Modal "Edit" ---- User Tab 1 */}
                <div className='modal_form modal fade' id='EditModal'>
                  <div className='modal-dialog modal-lg modal-dialog-centered'>
                    <div className='modal-content'>
                      <div className='modal-header'>
                        <h5
                          className='modal-title'
                          id='exampleModalCenterTitle'
                        >
                          แก้ไขสมาชิก
                        </h5>
                      </div>
                      <div className='modal-body' style={{ zIndex: 9 }}>
                        <div className='row'>
                          <div className='col-12 col-md-6'>
                            <form className='boder-style'>
                              {this.state.dataEditUsers.map((editUser) => (
                                <>
                                  <div className='form-group mb-3'>
                                    <label htmlFor='inputUserNicknameEdit'>
                                      ชื่อเรียกแทน
                                    </label>
                                    <input
                                      type='text'
                                      className='form-control'
                                      id='inputUserNicknameEdit'
                                      placeholder='กรุณาระบุชื่อเรียก'
                                      name='inputUserNicknameEdit'
                                      value={this.state.inputUserNicknameEdit}
                                      onChange={this.onChangeInput}
                                      required
                                      data-error='Name is required'
                                    />
                                  </div>

                                  <div className='form-row mb-3'>
                                    <div className='form-group col-md-6'>
                                      <label htmlFor='inputUsernameEdit'>
                                        รหัสผู้ใช้
                                      </label>
                                      <input
                                        type='text'
                                        className='form-control'
                                        id='inputUsernameEdit'
                                        placeholder='Username'
                                        name='inputUsernameEdit'
                                        value={this.state.inputUsernameEdit}
                                        onChange={this.onChangeInput}
                                        required
                                        data-error='Name is required'
                                      />
                                    </div>
                                    <div className='form-group col-md-6'>
                                      <label htmlFor='inputUserPasswordEdit'>
                                        รหัสผ่าน (ห้ามแก้ไข)
                                      </label>
                                      <input
                                        type='password'
                                        className='form-control'
                                        id='inputUserPasswordEdit'
                                        placeholder='Password'
                                        name='inputUserPasswordEdit'
                                        value={this.state.inputUserPasswordEdit}
                                        onChange={this.onChangeInput}
                                        required
                                        // disabled
                                        data-error='Password is required'
                                      />
                                    </div>
                                  </div>
                                </>
                              ))}
                            </form>
                          </div>
                          {/* ฝั่ง Roles */}
                          <div className='col-12 col-md-6'>
                            <form className='boder-style'>
                              <h5 className='mb-3'>Roles</h5>
                              {this.state.dataEditUsers.map((editUser, idx) => (
                                <>
                                  {editUser.group_permission.map((chk) => (
                                    <div
                                      className='custom-control custom-checkbox checkbox-lg'
                                      key={editUser.CodeID}
                                    >
                                      <input
                                        key={chk.id}
                                        type='checkbox'
                                        className='custom-control-input'
                                        id={`checkboxedit-${parseInt(
                                          chk.CodeID
                                        )}`}
                                        defaultChecked={chk.isChecked === '1' ? true : false}
                                        value={chk.CodeID}
                                        onChange={this.handleInputChangeEdit}
                                      />
                                      <label
                                        className='custom-control-label'
                                        htmlFor={`checkboxedit-${parseInt(
                                          chk.CodeID
                                        )}`}
                                      >
                                        {chk.CodeName}
                                      </label>
                                    </div>
                                  ))}
                                </>
                              ))}

                              {/* <h5 className="mt-3">***Agent ไม่ต้องเลือกในส่วนนี้***</h5>

                          <div className="form-group row">
                            <label for="inputPercent" className="col-sm-5 col-form-label">Add Percent</label>
                            <div className="col-sm-5">
                              <input type="number" className="form-control" id="inputPercent"/>
                            </div>
                          </div> */}
                            </form>
                          </div>
                        </div>
                      </div>
                      <div className='modal-footer'>
                        <button
                          className='button bg-3'
                          data-dismiss='modal'
                          onClick={this.updateMember.bind(
                            this,
                            this.state.editUserId
                          )}
                        >
                          ยืนยัน
                        </button>
                        <button
                          type='button'
                          className='button bg-1'
                          data-dismiss='modal'
                        >
                          ปิด
                        </button>
                      </div>
                    </div>
                  </div>
                </div>

                {/* Modal */}
                <div className='modal_form modal fade' id='DeleteModal'>
                  <div className='modal-dialog modal-dialog-centered modal-dialog-scrollable'>
                    <div className='modal-content'>
                      <div className='modal-header'>
                        <h1
                          className='modal-title'
                          id='exampleModalCenterTitle'
                        >
                          <div className='warning-sign'>
                            <i className='fas fa-exclamation' />
                          </div>
                        </h1>
                      </div>
                      <div className='modal-body'>
                        <h3 className='text-center'>ยืนยันการลบ?</h3>
                      </div>
                      <div className='modal-footer'>
                        <button
                          type='button'
                          className='button bg-10'
                          data-dismiss='modal'
                        >
                          ปิด
                        </button>
                        <button className='button bg-1' data-dismiss='modal' onClick={this.deleteUser}>ยืนยัน</button>
                      </div>
                    </div>
                  </div>
                </div>

                {/* UserGroup Add/Edit/Delete */}
                <div className='modal_form modal fade' id='AddUserGroupModal'>
                  <div className='modal-dialog modal-dialog-centered'>
                    <div className='modal-content'>
                      <div className='modal-header'>
                        <h5
                          className='modal-title'
                          id='exampleModalCenterTitle'
                        >
                          เพิ่มกลุ่ม
                        </h5>
                      </div>
                      <div className='modal-body' style={{ zIndex: 9 }}>
                        <form className='boder-style'>
                          <div className='form-group mb-3'>
                            <label htmlFor='inputAddress'>ชื่อเรียกแทน</label>
                            <input
                              type='text'
                              className='form-control'
                              id='inputUserNickname'
                              placeholder='ชื่อเรียกแทน'
                              value={this.state.inputGroupName}
                              onChange={this.updateInputValueGroupName}
                            />
                          </div>
                          <div className='form-group'>
                            <label htmlFor='inputState'>
                              เลือกกลุ่มการใช้งาน
                            </label>
                            <MultiSelect
                              options={this.state.optionsPermission}
                              value={this.state.selectPermission}
                              onChange={this.handleChangePermissionCode}
                              labelledBy={'Select'}
                            />
                          </div>
                        </form>
                      </div>
                      <div className='modal-footer'>
                        <button
                          className='button bg-3'
                          onClick={this.saveGroupPermission.bind(this)}
                          data-dismiss='modal'
                        >
                          ยืนยัน
                        </button>
                        <button
                          type='button'
                          className='button bg-1'
                          data-dismiss='modal'
                          onClick={this.onCloseModal}
                        >
                          ยกเลิก
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
    );
  }
}

export default Member;
