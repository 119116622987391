import React from 'react';
import Header from '../../components/Header/Header';
import Menu from '../../components/Sidebar/Sidebar';
import Footer from '../../components/Footer/Footer';

export default ({ children }) => (
  <>
    <Header />
    <Menu />
    <div className='content-wrapper'>{children}</div>
    <Footer />
  </>
);
