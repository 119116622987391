import React, { Component } from 'react';
import axios from 'axios';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';

import { myConfig } from '../../constants/Constants';
import { Link } from 'react-router-dom';

const SERVER_URL = myConfig.apiUrl;

export class Setting extends Component {
  handleReboot = async () => {
    console.log('handleReboot Click ');

    let URL_ENDPOINT = `${SERVER_URL}/s_reboot.php`;

    console.log(URL_ENDPOINT);
    const res = await axios.get(URL_ENDPOINT);
    // console.log('res.data : ', res.data);
    if (res.data.length > 0) {
      alert('Reboot Server (Cyber) 3 นาที กรุณา refresh page');
    }
  };

  render() {
    return (
      <>
        {/* Content Header (Page header) */}
        <div className='content-header'>
          <div className='container-fluid'>
            <div className='row mb-2'>
              <div className='col-sm-6'>
                <h1 className='m-0 text-dark'>จัดการระบบ</h1>
              </div>
              {/* /.col */}
              <div className='col-sm-6'>
                <ol className='breadcrumb float-sm-right'>
                  <li className='breadcrumb-item'>
                    <Link
                      to={{
                        pathname: '/Landing',
                      }}
                    >
                      Home
                    </Link>
                  </li>
                  <li className='breadcrumb-item active'>จัดการระบบ</li>
                </ol>
              </div>
              {/* /.col */}
            </div>
            {/* /.row */}
          </div>
          {/* /.container-fluid */}
        </div>
        {/* /.content-header */}
        <section className='content'>
          <div className='content_main'>
            <div className='container-fluid'>
              <div className='row'>
                <div className='col-12'>
                  <Tabs>
                    <TabList>
                      <Tab>Reboot</Tab>
                    </TabList>
                    <TabPanel>
                      <div className='row mb-4'>
                        <div className='col-12 col-lg-9 col-md-7 mb-4 mb-md-0'></div>
                      </div>

                      <div className='table-responsive'>
                        <table id='sortby' className='table table-bordered'>
                          <thead className='thead-dark text-center'>
                            <tr>
                              <th scope='col'>รายการ</th>
                              <th scope='col'>Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>Reboot Cyber Auto Bank</td>
                              <td className='text-center'>
                                <button
                                  onClick={this.handleReboot}
                                  className='btn-RebootServer'
                                >
                                  Reboot Server
                                </button>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </TabPanel>
                  </Tabs>
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
    );
  }
}

export default Setting;
