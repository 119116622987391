import React, { Component } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { myConfig } from '../../constants/Constants';

const SERVER_URL = myConfig.apiUrl;

export class Auto extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      data_vip_bot: [],
      checked: false,
      changeFlag: 0,
      checkedItems: new Map(),
      value: false,
      count: 10,
    };
  }

  async componentDidMount() {
    await this.getAutoCreditStatus();
    await this.getAutoVIPStatus();

    this.interval = setInterval(() => {
      if (this.state.count <= 0) {
        // clearInterval(this.interval);
        this.setState({
          count: 10,
        });
        this.setState(this.getAutoCreditStatus());
      } else {
        this.setState((prevState) => ({ count: prevState.count - 1 }));
      }
    }, 1000);
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  getAutoCreditStatus = async () => {
    // clearInterval(this.interval);
    let URL_ENDPOINT;

    URL_ENDPOINT = `${SERVER_URL}/auto_status_all.php`;

    const res = await axios.get(URL_ENDPOINT);
    // console.log('res.data : ', res.data);
    if (res.data.length > 0) {
      this.setState({
        data: res.data,
      });
    }
  };

  getAutoVIPStatus = async () => {
    // clearInterval(this.interval);
    let URL_ENDPOINT;

    URL_ENDPOINT = `${SERVER_URL}/auto_vip_status_all.php`;

    const res = await axios.get(URL_ENDPOINT);
    console.log('res.data data_sbobet_bot : ', res.data);
    if (res.data.length > 0) {
      this.setState({
        data_vip_bot: res.data,
      });
    } else {
      this.setState({
        data_vip_bot: [],
      });
    }
  };

  handleChange(auto_id, status) {
    let apiUrl;

    console.log('auto_id : ', auto_id);
    console.log('status : ', status);

    if (status === 'stop') {
      apiUrl = `${SERVER_URL}/kill_auto_status.php?b_id=${parseInt(auto_id)}`;
    } else {
      apiUrl = `${SERVER_URL}/call_auto_status.php?b_id=${parseInt(auto_id)}`;
    }

    // console.log("apiUrl [Auto] : ", apiUrl);
    const res = axios.get(apiUrl);
    this.getAutoCreditStatus();
  }

  handleChange_vip(auto_id, status) {
    let apiUrl;

    // console.log('auto_id : ', auto_id);
    // console.log('status : ', status);

    if (status === 'stop') {
      apiUrl = `${SERVER_URL}/kill_auto_vip_bot.php?b_id=${parseInt(
        auto_id
      )}`;
    } else {
      apiUrl = `${SERVER_URL}/call_auto_vip_bot.php?b_id=${parseInt(
        auto_id
      )}`;
    }

    console.log('apiUrl [Auto] : ', apiUrl);
    const res = axios.get(apiUrl);

    setTimeout(() => {
      this.getAutoVIPStatus();
    }, 300)
    
  }


  render() {
    return (
      <>
        {/* Content Header (Page header) */}
        <div className='content-header'>
          <div className='container-fluid'>
            <div className='row mb-2'>
              <div className='col-sm-6'>
                <h1 className='m-0 text-dark'>ระบบจัดการ Auto Add Credit</h1>
              </div>
              {/* /.col */}
              <div className='col-sm-6'>
                <ol className='breadcrumb float-sm-right'>
                  <li className='breadcrumb-item'>
                    <Link
                      to={{
                        pathname: '/Landing',
                      }}
                    >
                      Home
                    </Link>
                  </li>
                  <li className='breadcrumb-item active'>Bank</li>
                </ol>
              </div>
              {/* /.col */}
            </div>
            {/* /.row */}
          </div>
          {/* /.container-fluid */}
        </div>
        {/* /.content-header */}
        <section className='content'>
          <div className='content_main'>
            <div className='container-fluid'>
              <div className='row'>
                <div className='col-12'>
                  <div className='card card-content'>
                    <div className='card-header bg-10'>
                      <div className='d-flex justify-content-between align-items-center'>
                        <p className='card-text'>เปิด/ปิด BOT (ธนาคารและVIP)</p>
                      </div>
                    </div>
                    <div className='card-body'>
                      <div className='row mb-4'>
                        <div className='col-12 col-lg-9 col-md-7 mb-4 mb-md-0'>
                          {/* <div className='dataTables_length'>
                              <label>Records per page:</label>
                              <select className='form-control custom-select'>
                                <option value={10}>10</option>
                                <option value={25}>25</option>
                                <option value={50}>50</option>
                                <option value={100}>100</option>
                              </select>
                            </div> */}
                        </div>
                        <div className='col-12 col-lg-3 col-md-5'>
                          {/* <form className='search-date d-flex align-items-center justify-content-end'>
                              <input
                                type='search'
                                className='form-control'
                                placeholder='Search...'
                              />
                              <button type='submit' className='btn btn-calendar bg-4'>
                                <i className='fas fa-search' />
                              </button>
                            </form> */}
                        </div>
                      </div>
                      <div className='table-responsive'>
                        <Tabs>
                          <TabList className='position-relative'>
                            <Tab>เปิด/ปิด Bot ธนาคาร</Tab>
                            <Tab>เปิด/ปิด Bot VIP</Tab>
                          </TabList>

                          <TabPanel>
                            <table id='sortby' className='table table-bordered'>
                              <thead className='thead-dark text-center'>
                                <tr>
                                  <th scope='col'>#</th>
                                  <th scope='col'>ชื่อธนาคาร</th>
                                  <th scope='col'>สัญลักษณ์</th>
                                  <th scope='col'>ปุ่มเปิด/ปิด</th>
                                  <th scope='col'>สถานะ</th>
                                </tr>
                              </thead>
                              <tbody>
                                {this.state.data.map((item, index) => (
                                  <>
                                    <tr key={index}>
                                      <td className='text-center'>
                                        {index + 1}
                                      </td>
                                      <td>{item.NameOfBank}</td>
                                      <td>
                                        <div className='img_b'>
                                          <img
                                            src={item.image}
                                            alt={item.BankName}
                                          />
                                        </div>
                                      </td>

                                      <td className='text-center'>
                                        {parseInt(item.flag) !== 0 && (
                                          <button
                                            className='button status bg-1'
                                            // data-toggle='modal'
                                            // data-target='#DeleteModal'
                                            onClick={() =>
                                              this.handleChange(
                                                parseInt(item.ca_id),
                                                'stop'
                                              )
                                            }
                                          >
                                            Stop
                                          </button>
                                        )}
                                        {parseInt(item.flag) === 0 && (
                                          <button
                                            className='button status bg-3'
                                            // data-toggle='modal'
                                            // data-target='#DeleteModal'
                                            onClick={() =>
                                              this.handleChange(
                                                parseInt(item.ca_id),
                                                'start'
                                              )
                                            }
                                          >
                                            Start
                                          </button>
                                        )}
                                      </td>
                                      <td className='text-center'>
                                        {parseInt(item.flag) === 0 && (
                                          <div class='txt-status-2 timescircle'>
                                            Stop
                                          </div>
                                        )}
                                        {parseInt(item.flag) === 1 && (
                                          <div className='txt-status checkcircle'>
                                            Running
                                          </div>
                                        )}
                                      </td>
                                    </tr>
                                  </>
                                ))}
                              </tbody>
                            </table>
                          </TabPanel>

                          <TabPanel>
                            <table id='sortby' className='table table-bordered'>
                              <thead className='thead-dark text-center'>
                                <tr>
                                  <th scope='col'>#</th>
                                  <th scope='col'>ชื่อบอท</th>
                                  <th scope='col'>สัญลักษณ์</th>
                                  <th scope='col'>สถานะ</th>
                                  <th scope='col'>ปุ่มเปิด/ปิด</th>
                                  {/* <th scope='col'>การจัดการ</th> */}
                                </tr>
                              </thead>
                              <tbody>
                                {this.state.data_vip_bot.map(
                                  (item, index) => (
                                    <>
                                      <tr key={index}>
                                        <td className='text-center'>
                                          {index + 1}
                                        </td>
                                        <td>{item.show_name}</td>
                                        <td>
                                          <div className='img_b'>
                                            <img
                                              src={item.image}
                                              alt={item.agent_no}
                                            />
                                          </div>
                                        </td>
                                        <td className='text-center'>
                                          {parseInt(item.flag) === 0 && (
                                            <div class='txt-status-2 timescircle'>
                                              Stop
                                            </div>
                                          )}
                                          {parseInt(item.flag) === 1 && (
                                            <div className='txt-status checkcircle'>
                                              Running
                                            </div>
                                          )}
                                        </td>
                                        <td className='text-center'>
                                          {parseInt(item.flag) === 1 && (
                                            <button
                                              className='button status bg-1'
                                              // data-toggle='modal'
                                              // data-target='#DeleteModal'
                                              onClick={() =>
                                                this.handleChange_vip(
                                                  parseInt(item.id),
                                                  'stop'
                                                )
                                              }
                                            >
                                              Stop
                                            </button>
                                          )}
                                          {parseInt(item.flag) === 0 && (
                                            <button
                                              className='button status bg-3'
                                              // data-toggle='modal'
                                              // data-target='#DeleteModal'
                                              onClick={() =>
                                                this.handleChange_vip(
                                                  parseInt(item.id),
                                                  'start'
                                                )
                                              }
                                            >
                                              Start
                                            </button>
                                          )}
                                        </td>

                                        {/* <td className='text-center'>
                                        <button
                                          className='button status bg-3'
                                          data-toggle='modal'
                                          data-target='#EditModal'
                                        >
                                          แก้ไข
                                        </button>
                                        <button
                                          className='button status bg-1'
                                          data-toggle='modal'
                                          data-target='#DeleteModal'
                                        >
                                          ลบ
                                        </button>
                                      </td> */}
                                      </tr>
                                    </>
                                  )
                                )}
                              </tbody>
                            </table>
                          </TabPanel>
                        </Tabs>
                      </div>
                    </div>
                  </div>
                </div>
                {/* Modal */}
                <div className='modal_form modal fade' id='AddModal'>
                  <div className='modal-dialog modal-dialog-centered modal-dialog-scrollable'>
                    <div className='modal-content'>
                      <div className='modal-header'>
                        <h5
                          className='modal-title'
                          id='exampleModalCenterTitle'
                        >
                          เพิ่ม
                        </h5>
                      </div>
                      <div className='modal-body'>
                        <form>
                          <div className='form-row'>
                            <div className='form-group col-md-6'>
                              <label htmlFor='inputEmail4'>Email</label>
                              <input
                                type='email'
                                className='form-control'
                                id='inputEmail4'
                              />
                            </div>
                            <div className='form-group col-md-6'>
                              <label htmlFor='inputPassword4'>Password</label>
                              <input
                                type='password'
                                className='form-control'
                                id='inputPassword4'
                              />
                            </div>
                          </div>
                          <div className='form-group'>
                            <label htmlFor='inputAddress'>Address</label>
                            <input
                              type='text'
                              className='form-control'
                              id='inputAddress'
                              placeholder='1234 Main St'
                            />
                          </div>
                          <div className='form-group'>
                            <label htmlFor='inputAddress2'>Address 2</label>
                            <input
                              type='text'
                              className='form-control'
                              id='inputAddress2'
                              placeholder='Apartment, studio, or floor'
                            />
                          </div>
                          <div className='form-row'>
                            <div className='form-group col-md-6'>
                              <label htmlFor='inputCity'>City</label>
                              <input
                                type='text'
                                className='form-control'
                                id='inputCity'
                              />
                            </div>
                            <div className='form-group col-md-4'>
                              <label htmlFor='inputState'>State</label>
                              <select id='inputState' className='form-control'>
                                <option selected>Choose...</option>
                                <option>...</option>
                              </select>
                            </div>
                            <div className='form-group col-md-2'>
                              <label htmlFor='inputZip'>Zip</label>
                              <input
                                type='text'
                                className='form-control'
                                id='inputZip'
                              />
                            </div>
                          </div>
                          <div className='form-group'>
                            <div className='form-check'>
                              <input
                                className='form-check-input'
                                type='checkbox'
                                id='gridCheck'
                              />
                              <label
                                className='form-check-label'
                                htmlFor='gridCheck'
                              >
                                Check me out
                              </label>
                            </div>
                          </div>
                          <button type='submit' className='button btn-primary'>
                            Sign in
                          </button>
                        </form>
                      </div>
                      <div className='modal-footer'>
                        <button
                          type='button'
                          className='button bg-1'
                          data-dismiss='modal'
                        >
                          ปิด
                        </button>
                        <button className='button bg-3'>ยืนยัน</button>
                      </div>
                    </div>
                  </div>
                </div>
                {/* Modal */}
                <div className='modal_form modal fade' id='EditModal'>
                  <div className='modal-dialog modal-dialog-centered modal-dialog-scrollable'>
                    <div className='modal-content'>
                      <div className='modal-header'>
                        <h5
                          className='modal-title'
                          id='exampleModalCenterTitle'
                        >
                          แก้ไข
                        </h5>
                      </div>
                      <div className='modal-body'>
                        <form>
                          <div className='form-row'>
                            <div className='form-group col-md-6'>
                              <label htmlFor='inputEmail4'>Email</label>
                              <input
                                type='email'
                                className='form-control'
                                id='inputEmail4'
                              />
                            </div>
                            <div className='form-group col-md-6'>
                              <label htmlFor='inputPassword4'>Password</label>
                              <input
                                type='password'
                                className='form-control'
                                id='inputPassword4'
                              />
                            </div>
                          </div>
                          <div className='form-group'>
                            <label htmlFor='inputAddress'>Address</label>
                            <input
                              type='text'
                              className='form-control'
                              id='inputAddress'
                              placeholder='1234 Main St'
                            />
                          </div>
                          <div className='form-group'>
                            <label htmlFor='inputAddress2'>Address 2</label>
                            <input
                              type='text'
                              className='form-control'
                              id='inputAddress2'
                              placeholder='Apartment, studio, or floor'
                            />
                          </div>
                          <div className='form-row'>
                            <div className='form-group col-md-6'>
                              <label htmlFor='inputCity'>City</label>
                              <input
                                type='text'
                                className='form-control'
                                id='inputCity'
                              />
                            </div>
                            <div className='form-group col-md-4'>
                              <label htmlFor='inputState'>State</label>
                              <select id='inputState' className='form-control'>
                                <option selected>Choose...</option>
                                <option>...</option>
                              </select>
                            </div>
                            <div className='form-group col-md-2'>
                              <label htmlFor='inputZip'>Zip</label>
                              <input
                                type='text'
                                className='form-control'
                                id='inputZip'
                              />
                            </div>
                          </div>
                          <div className='form-group'>
                            <div className='form-check'>
                              <input
                                className='form-check-input'
                                type='checkbox'
                                id='gridCheck'
                              />
                              <label
                                className='form-check-label'
                                htmlFor='gridCheck'
                              >
                                Check me out
                              </label>
                            </div>
                          </div>
                          <button type='submit' className='button btn-primary'>
                            Sign in
                          </button>
                        </form>
                      </div>
                      <div className='modal-footer'>
                        <button
                          type='button'
                          className='button bg-1'
                          data-dismiss='modal'
                        >
                          ปิด
                        </button>
                        <button className='button bg-3'>ยืนยัน</button>
                      </div>
                    </div>
                  </div>
                </div>
                {/* Modal */}
                <div className='modal_form modal fade' id='DeleteModal'>
                  <div className='modal-dialog modal-dialog-centered modal-dialog-scrollable'>
                    <div className='modal-content'>
                      <div className='modal-header'>
                        <h1
                          className='modal-title'
                          id='exampleModalCenterTitle'
                        >
                          <div className='warning-sign'>
                            <i className='fas fa-exclamation' />
                          </div>
                        </h1>
                      </div>
                      <div className='modal-body'>
                        <h3 className='text-center'>ยืนยันการลบ?</h3>
                      </div>
                      <div className='modal-footer'>
                        <button
                          type='button'
                          className='button bg-10'
                          data-dismiss='modal'
                        >
                          ปิด
                        </button>
                        <button className='button bg-1'>ยืนยัน</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
    );
  }
}

export default Auto;
