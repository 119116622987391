import React, { Component } from 'react';
import axios from 'axios';
import DateTimeRangePicker from '@wojtekmaj/react-datetimerange-picker';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import BootstrapTable from 'react-bootstrap-table-next';
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import * as ReactBootStrap from 'react-bootstrap';
import paginationFactory from 'react-bootstrap-table2-paginator';
import cellEditFactory from 'react-bootstrap-table2-editor';
import ToolkitProvider, {
  Search,
  CSVExport,
} from 'react-bootstrap-table2-toolkit';

import { css } from '@emotion/core';
import RotateLoader from 'react-spinners/RotateLoader';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import 'react-tabs/style/react-tabs.css';
import { myConfig } from '../../constants/Constants';
import { Link } from 'react-router-dom';

const SERVER_URL = myConfig.apiUrl;

const now = new Date();
const yesterdayBegin = new Date(
  now.getFullYear(),
  now.getMonth(),
  now.getDate(),
  now.getHours()
);
const todayNoon = new Date(
  now.getFullYear(),
  now.getMonth(),
  now.getDate(),
  now.getHours() + 1
);

const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;

toast.configure();
export class BankWithdraw extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      data_bank_transfer: [],
      bank_transfer_wait: [],
      bank_transfer_success: [],
      bank_transfer_cancel: [],
      bank_transfer_fail: [],
      data_rpt: [],
      data_rpt_sms_mobile_2: [],
      data_auto: [],
      data_bank_status: [],
      count: 10,
      loading: true,
      data_bank_kbank_upd: '',
      dataCountFailed: 0,
      getUid: localStorage.getItem('uid'),
      getName: localStorage.getItem('name'),
      data_wait_bank_transfer: [],
      data_wait_bank_success: [],
      data_wait_bank_cancel: [],
      data_wait_bank_fail: [],
      data_wait_bank_fail_count: 0,
    };
    // console.log("open : " + this.props.open);
  }

  async componentDidMount() {
    await this.getDataTransferWait();
    await this.getBankWithdrawWaitTransfer();
    await this.getDataTransferSuccess();
    await this.getDataTransferCancel();
    await this.getDataTransferFail();
    await this.getDataTransferFailCount();
    await this.getSmsAll();
    await this.getSmsAllMobile2();
    await this.getAutoCreditStatus();
    await this.getBankStatus();

    this.interval = setInterval(() => {
      if (this.state.count <= 0) {
        this.setState({
          count: 10,
        });
        this.getDataTransferWait();
        this.getBankWithdrawWaitTransfer();
        this.getDataTransferSuccess();
        this.getDataTransferCancel();
        this.getDataTransferFail();
        this.getDataTransferFailCount();
        this.getSmsAll();
        this.getSmsAllMobile2();
        this.getAutoCreditStatus();
        this.getBankStatus();
      } else {
        this.setState((prevState) => ({ count: prevState.count - 1 }));
      }
    }, 1000);

    this.setState({
      loading: false,
    });
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  // getBankStatus = async () => {
  //   // clearInterval(this.interval);
  //   let URL_ENDPOINT;

  //   URL_ENDPOINT = `${SERVER_URL}/bank_status.php`;

  //   const res = await axios.get(URL_ENDPOINT);
  //   // console.log('res.data : ', res.data);
  //   // console.log('res.data[0].date_updated : ', res.data[0].date_updated);
  //   if (res.data.length > 0) {
  //     this.setState({
  //       data_bank_status: res.data,
  //       data_bank_kbank_upd: res.data[0].date_updated,
  //       data_bank_kbank_money: res.data[0].total_balance,
  //     });
  //   }
  // };

  getBankStatus = async () => {
    // clearInterval(this.interval);
    let URL_ENDPOINT;

    URL_ENDPOINT = `${SERVER_URL}/bank_status_balance.php`;
    // console.log("URL_ENDPOINT : ", URL_ENDPOINT)

    const res = await axios.get(URL_ENDPOINT);
    // console.log('res.data [BankStatus] : ', res.data);
    // console.log('res.data[0].date_updated : ', res.data[0].date_updated);
    // console.log('res.data[0].total_balance : ', res.data[0].total_balance);
    if (res.data.length > 0) {
      this.setState({
        data_bank_status: res.data,
        // data_bank_kbank_upd: res.data[0].date_updated,
        // data_bank_kbank_bal: res.data[0].total_balance,
        // data_bank_scb_upd: res.data[2].date_updated,
        // data_bank_scb_bal: res.data[2].total_balance,
      });
    }
  };

  getDataTransferWait = async () => {
    // clearInterval(this.interval);
    let URL_ENDPOINT;

    URL_ENDPOINT = `${SERVER_URL}/bank_transfer_wait.php`;

    const res = await axios.get(URL_ENDPOINT);
    // console.log('res.data transfer : ', res.data);
    if (res.data.length > 0) {
      this.setState({
        bank_transfer_wait: res.data,
      });
    } else {
      this.setState({
        bank_transfer_wait: [],
      });
    }
  };

  getBankWithdrawWaitTransfer = async () => {
    let URL_ENDPOINT;

    URL_ENDPOINT = `${SERVER_URL}/bank_withdraw_transfer.php`;

    const res = await axios.get(URL_ENDPOINT);
    // console.log('res.data data_wait_bank_transfer : ', res.data);
    if (res.data.length > 0) {
      this.setState({
        data_wait_bank_transfer: res.data,
      });
    } else {
      this.setState({
        data_wait_bank_transfer: [],
      });
    }
  };

  getDataTransferSuccess = async () => {
    // clearInterval(this.interval);
    let URL_ENDPOINT;

    URL_ENDPOINT = `${SERVER_URL}/bank_transfer_success.php`;

    const res = await axios.get(URL_ENDPOINT);
    // console.log('res.data transfer : ', res.data);
    if (res.data.length > 0) {
      this.setState({
        bank_transfer_success: res.data,
      });
    } else {
      this.setState({
        bank_transfer_success: [],
      });
    }
  };

  getDataTransferCancel = async () => {
    // clearInterval(this.interval);
    let URL_ENDPOINT;

    URL_ENDPOINT = `${SERVER_URL}/bank_transfer_cancel.php`;

    const res = await axios.get(URL_ENDPOINT);
    // console.log('res.data transfer : ', res.data);
    if (res.data.length > 0) {
      this.setState({
        bank_transfer_cancel: res.data,
      });
    } else {
      this.setState({
        bank_transfer_cancel: [],
      });
    }
  };

  getDataTransferFail = async () => {
    // clearInterval(this.interval);
    let URL_ENDPOINT;

    URL_ENDPOINT = `${SERVER_URL}/bank_transfer_fail.php`;

    const res = await axios.get(URL_ENDPOINT);
    // console.log('res.data transfer : ', res.data);
    console.log('getDataTransferFail - res.data : ', res.data);
    if (res.data.length > 0) {
      this.setState({
        bank_transfer_fail: res.data,
      });
    } else {
      this.setState({
        bank_transfer_fail: [],
      });
    }
  };

  getDataTransferFailCount = async () => {
    // clearInterval(this.interval);
    let URL_ENDPOINT;

    URL_ENDPOINT = `${SERVER_URL}/bank_transfer_fail_count.php`;

    const res = await axios.get(URL_ENDPOINT);
    // console.log('res.data transfer fail : ', res.data);
    if (res.data.length > 0) {
      this.setState({
        dataCountFailed: res.data[0].total,
      });
    } else {
      this.setState({
        dataCountFailed: '',
      });
    }
  };

  getSmsAll = async () => {
    let URL_ENDPOINT;

    URL_ENDPOINT = `${SERVER_URL}/get_rpt_sms_otp.php`;

    const res = await axios.get(URL_ENDPOINT);
    // console.log('res.data : ', res.data);
    if (res.data.length > 0) {
      this.setState({
        data_rpt: res.data,
      });
    }
  };

  getSmsAllMobile2 = async () => {
    let URL_ENDPOINT;

    URL_ENDPOINT = `${SERVER_URL}/get_rpt_sms_otp_mobile_2.php`;

    const res = await axios.get(URL_ENDPOINT);
    // console.log('res.data : ', res.data);
    if (res.data.length > 0) {
      this.setState({
        data_rpt_sms_mobile_2: res.data,
      });
    }
  };

  searchDataSms = async () => {
    // console.log('this.state.value : ', this.state.value);
    let dateFrom = this.getCurrent(this.state.value[0]);
    let dateTo = this.getCurrent(this.state.value[1]);

    if (dateFrom > dateTo) {
      alert('กรุณาใส่วันที่ให้ถูกต้อง');
    } else {
      let URL_ENDPOINT;

      URL_ENDPOINT = `${SERVER_URL}/get_rpt_credit_date.php?date_from=${dateFrom}&date_to=${dateTo}`;

      const res = await axios.get(URL_ENDPOINT);
      // console.log('res.data : ', res.data);
      if (res.data.length > 0) {
        this.setState({
          data_rpt: res.data,
        });
      }
    }
  };

  getAutoCreditStatus = async () => {
    // clearInterval(this.interval);
    let URL_ENDPOINT;

    URL_ENDPOINT = `${SERVER_URL}/auto_status_all.php`;

    const res = await axios.get(URL_ENDPOINT);
    // console.log('res.data : ', res.data);
    if (res.data.length > 0) {
      this.setState({
        data_auto: res.data,
      });
    } else {
      this.setState({
        data_auto: [],
      });
    }
  };

  handleChange(auto_id, status) {
    let apiUrl;

    console.log('auto_id : ', auto_id);
    console.log('status : ', status);

    if (status === 'stop') {
      apiUrl = `${SERVER_URL}/kill_auto_status.php?b_id=${parseInt(auto_id)}`;
    } else {
      apiUrl = `${SERVER_URL}/call_auto_status.php?b_id=${parseInt(auto_id)}`;
    }

    // console.log("apiUrl [Auto] : ", apiUrl);
    const res = axios.get(apiUrl);
    setTimeout(() => {
      this.getAutoCreditStatus();
    }, 400);
  }

  onApproveClick = (wd_id, bank_name) => {
    // clearInterval(this.interval);

    let getUid = localStorage.getItem('uid');
    let getName = localStorage.getItem('name');

    let line_display_name = getName;
    let line_id = getUid;
    let lastTranId = parseInt(wd_id);

    let apiUrl;

    apiUrl = `${SERVER_URL}/withdraw_approve.php?wd_id=${lastTranId}&approve_by=${line_display_name}&b_name=${bank_name}`;

    console.log(apiUrl);

    // const res = axios.get(apiUrl);

    axios.get(apiUrl).then((res) => {
      console.log(res.data);

      if (res.data.status === 1) {
        toast.success('รายการอนุมัติเรียบร้อยแล้ว', {
          position: toast.POSITION.BOTTOM_RIGHT,
          autoClose: 5000,
        });

        setTimeout(() => {
          this.getDataTransferWait();
        }, 400);
      } else {
        toast.error('รายการไม่สำเร็จ กรุณาสั่งอีกครั้ง', {
          position: toast.POSITION.BOTTOM_RIGHT,
          autoClose: 5000,
        });
      }
    });
  };

  onTransferSuccess = (wd_id) => {
    // clearInterval(this.interval);

    let getUid = localStorage.getItem('uid');
    let getName = localStorage.getItem('name');

    let line_display_name = getName;
    let line_id = getUid;
    let lastTranId = parseInt(wd_id);

    let apiUrl;

    apiUrl = `${SERVER_URL}/withdraw_done.php?wd_id=${lastTranId}&approve_by=${line_display_name}`;

    console.log(apiUrl);

    // const res = axios.get(apiUrl);

    axios.get(apiUrl).then((res) => {
      console.log(res.data);

      if (res.data.status === 1) {
        toast.success('รายการยกเลิกเรียบร้อยแล้ว', {
          position: toast.POSITION.BOTTOM_RIGHT,
          autoClose: 5000,
        });

        setTimeout(() => {
          this.getDataTransferWait();
        }, 400);
      } else {
        toast.error('รายการยกเลิก ไม่สำเร็จ กรุณาสั่งอีกครั้ง', {
          position: toast.POSITION.BOTTOM_RIGHT,
          autoClose: 5000,
        });
      }
    });
  };

  onCancelClick = (wd_id) => {
    // clearInterval(this.interval);

    let getUid = localStorage.getItem('uid');
    let getName = localStorage.getItem('name');

    let line_display_name = getName;
    let line_id = getUid;
    let lastTranId = parseInt(wd_id);

    let apiUrl;

    apiUrl = `${SERVER_URL}/withdraw_cancel.php?wd_id=${lastTranId}&approve_by=${line_display_name}`;

    console.log(apiUrl);

    // const res = axios.get(apiUrl);

    axios.get(apiUrl).then((res) => {
      console.log(res.data);

      if (res.data.status === 1) {
        toast.success('รายการยกเลิกเรียบร้อยแล้ว', {
          position: toast.POSITION.BOTTOM_RIGHT,
          autoClose: 5000,
        });

        setTimeout(() => {
          this.getDataTransferWait();
        }, 400);
      } else {
        toast.error('รายการยกเลิก ไม่สำเร็จ กรุณาสั่งอีกครั้ง', {
          position: toast.POSITION.BOTTOM_RIGHT,
          autoClose: 5000,
        });
      }
    });
  };

  onDoneClick = (wd_id) => {
    // clearInterval(this.interval);

    let getUid = localStorage.getItem('uid');
    let getName = localStorage.getItem('name');

    let line_display_name = getName;
    let line_id = getUid;
    let lastTranId = parseInt(wd_id);

    let apiUrl;

    apiUrl = `${SERVER_URL}/withdraw_done.php?wd_id=${lastTranId}&approve_by=${line_display_name}`;

    console.log(apiUrl);

    // const res = axios.get(apiUrl);

    axios.get(apiUrl).then((res) => {
      console.log(res.data);

      if (res.data.status === 1) {
        toast.success('ทำรายการโอนเรียบร้อยแล้ว', {
          position: toast.POSITION.BOTTOM_RIGHT,
          autoClose: 5000,
        });

        setTimeout(() => {
          this.getDataTransferWait();
        }, 400);
      } else {
        toast.error('รายการ ไม่สำเร็จ กรุณาสั่งอีกครั้ง', {
          position: toast.POSITION.BOTTOM_RIGHT,
          autoClose: 5000,
        });
      }
    });
  };

  RetryProcess = async (withdraw_transaction_id, bank_name) => {
    console.log('bank_name : ', bank_name);

    let apiUrl = `${SERVER_URL}/withdraw_retry.php?wd_id=${withdraw_transaction_id}&g_create=${this.state.getName}&b_name=${bank_name}`;
    console.log(apiUrl);
    console.log('Transaction ID ::', withdraw_transaction_id);
    const res = axios.get(apiUrl);

    setTimeout(() => {
      this.getBankWithdrawWaitTransfer();
      this.getBankWithdrawFail();
      this.getBankWithdrawFailCount();
      this.getBankWithdrawSucess();
    }, 400);
  };

  withdraw_manual_success = async (withdraw_transaction_id) => {
    let apiUrl = `${SERVER_URL}/withdraw_manual_success.php?wd_id=${withdraw_transaction_id}&g_create=${this.state.getName}`;
    console.log(apiUrl);
    // console.log("Transaction ID ::", withdraw_transaction_id);
    const res = axios.get(apiUrl);

    setTimeout(() => {
      this.getDataTransferWait();
      this.getBankWithdrawWaitTransfer();
      this.getDataTransferSuccess();
      this.getDataTransferCancel();
      this.getDataTransferFail();
      this.getDataTransferFailCount();
    }, 400);
  };

  render() {
    const { SearchBar } = Search;

    const imgForBank = (cell, row, rowIndex) => {
      return (
        <div className='img_b'>
          <img src={row.image} alt={row.image} />
        </div>
      );
    };

    const WithdrawStatus = (cell, row, rowIndex) => {
      // console.log('cell : ', cell);
      // console.log('row : ', row);
      // console.log('rowIndex : ', rowIndex);
      return (
        <>
          <button
            className='button status bg-3'
            onClick={this.RetryProcess.bind(this, row.wd_id, 'kbank')}
          >
            KBANK
          </button>
          <button
            className='button status bg-3'
            onClick={this.RetryProcess.bind(this, row.wd_id, 'kplus')}
          >
            KPLUS
          </button>
          <button
            className='button status bg-3'
            onClick={this.RetryProcess.bind(this, row.wd_id, 'scb')}
          >
            SCB
          </button>
          <button
            className='button status bg-2'
            onClick={this.withdraw_manual_success.bind(this, row.wd_id)}
          >
            โอนมือ
          </button>
          {/* <button
            className='button status bg-1'
            onClick={this.CancelProcess.bind(this, row.wd_id)}
          >
            ยกเลิก
          </button> */}
        </>
      );
    };

    const flag_status = (cell, row, rowIndex) => {
      // console.log('cell : ', cell);
      // console.log('row : ', row);
      // console.log('rowIndex : ', rowIndex);

      if (
        row.flag_status === 'wait_bank_transfer' ||
        row.flag_status === 'approve'
      ) {
        return (
          <div className='txt-status-1 exclamationcircle'>อยู่ระหว่างรอโอน</div>
        );
        // } else if (row.flag_status === 'approve') {
        //   return (
        //     <div className='txt-status-1 exclamationcircle'>อยู่ระหว่างรอโอน</div>
        //   );
      } else if (row.flag_status === 'wait_bank_transfer_scb') {
        return (
          <div className='txt-status-1 exclamationcircle'>อยู่ระหว่างรอโอน(SCB)</div>
        );
      } else if (row.flag_status === 'success') {
        return <div className='txt-status checkcircle'>สำเร็จ</div>;
      } else if (row.flag_status === 'failed') {
        return <div className='txt-status-2 timescircle'>ไม่สำเร็จ</div>;
      } else if (row.flag_status === 'processing') {
        return <div className='txt-status-2 timescircle'>กำลังดำเนินการ</div>;
      }
    };

    const columnsTransfer = [
      { dataField: 'wd_id', text: 'wd_id', hidden: true },
      {
        dataField: 'NameOfBank',
        text: 'ธนาคาร(เวป)',
        headerAlign: 'center',
        sort: true,
        editable: true,
      },
      {
        dataField: 'image',
        text: 'สัญลักษณ์',
        headerAlign: 'center',
        sort: true,
        editable: true,
        formatter: imgForBank,
      },
      {
        dataField: 'withdraw_time',
        text: 'เวลา',
        headerAlign: 'center',
        align: 'center',
        sort: true,
        editable: true,
      },
      // {
      //   dataField: 'refer',
      //   text: 'อ้างอิง',
      //   headerAlign: 'center',
      //   align: 'center',
      //   sort: true,
      //   editable: true,
      // },
      {
        dataField: 'username',
        text: 'รหัสผู้ใช้',
        headerAlign: 'center',
        align: 'center',
      },
      {
        dataField: 'bank_account_no',
        text: 'เลขที่บัญชี',
        headerAlign: 'center',
        align: 'center',
        sort: true,
      },
      {
        dataField: 'full_name',
        text: 'ชื่อบัญชี',
        headerAlign: 'center',
        align: 'center',
        sort: true,
      },
      {
        dataField: 'withdraw_amount',
        text: 'จำนวนเงิน',
        headerAlign: 'center',
        align: 'center',
        sort: true,
      },
      {
        dataField: 'error_msg_2',
        text: 'หมายเหตุ',
        headerAlign: 'center',
        align: 'center',
        sort: true,
      },
      {
        dataField: 'approve_by',
        text: 'โดย',
        headerAlign: 'center',
        align: 'center',
        sort: true,
      },
      {
        dataField: 'flag_status',
        text: 'สถานะ',
        headerAlign: 'center',
        align: 'center',
        sort: true,
        formatter: flag_status,
      },
      {
        dataField: 'result',
        text: 'Action',
        headerAlign: 'center',
        align: 'center',
        sort: true,
        formatter: WithdrawStatus,
      },
    ];

    const sizePerPageOptionRenderer = ({ text, page, onSizePerPageChange }) => (
      <li key={text} role='presentation' className='dropdown-item'>
        <a
          href='#'
          tabIndex='-1'
          role='menuitem'
          data-page={page}
          onMouseDown={(e) => {
            e.preventDefault();
            onSizePerPageChange(page);
          }}
          style={{ color: 'red' }}
        >
          {text}
        </a>
      </li>
    );

    const options = {
      sizePerPageOptionRenderer,
    };

    return (
      <>
        {/* Content Header (Page header) */}
        <div className='content-header'>
          <div className='container-fluid'>
            <div className='row mb-2'>
              <div className='col-sm-6'>
                <h1 className='m-0 text-dark'>โอนเงินธนาคาร</h1>
              </div>
              {/* /.col */}
              <div className='col-sm-6'>
                <ol className='breadcrumb float-sm-right'>
                  <li className='breadcrumb-item'>
                    <Link
                      to={{
                        pathname: '/Landing',
                      }}
                    >
                      Home
                    </Link>
                  </li>
                  <li className='breadcrumb-item active'>โอนเงินธนาคาร</li>
                </ol>
              </div>
              {/* /.col */}
            </div>
            {/* /.row */}
          </div>
          {/* /.container-fluid */}
        </div>
        {/* /.content-header */}
        <section className='content'>
          <div className='content_main'>
            <div className='container-fluid'>
              <div className='row'>
                <div className='col-12'>
                  <div className='card card-content'>
                    <div className='card-header bg-10'>
                      {/* <div className='d-flex justify-content-between align-items-center'>
                        <p className='card-text'>รายการถอนเงิน / โอนเงิน</p>
                        <p
                          className='card-text'
                          style={{ color: 'red', fontSize: '16px' }}
                        >
                          KBANK - Update: {this.state.data_bank_kbank_upd}
                        </p>
                      </div>
                      <div className='d-flex justify-content-between align-items-center'>
                        <p className='card-text'></p>
                        <p
                          className='card-text'
                          style={{ color: 'red', fontSize: '16px' }}
                        >
                          คงเหลือ: {this.state.data_bank_kbank_money}
                        </p>
                      </div> */}
                    </div>
                    <div className='card-body'>
                      <div className='row mb-4'>
                        <div className='table-responsive height-auto'>
                          <table id='sortby' className='table table-bordered'>
                            <thead className='thead-dark text-center'>
                              <tr>
                                <th scope='col' className='sortable asc'>
                                  #
                                </th>
                                <th scope='col' className='sortable'>
                                  ธนาคาร(เวป)
                                </th>
                                <th scope='col' className='sortable'>
                                  สัญลักษณ์
                                </th>
                                <th scope='col' className='sortable'>
                                  เวลาล่าสุด
                                </th>
                                {/* <th scope='col' className='sortable'>
                                จำนวนครั้งที่โอน
                              </th> */}
                                <th scope='col' className='sortable'>
                                  ยอดเงินคงเหลือ
                                </th>
                                <th scope='col' className='sortable'>
                                  Action
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {this.state.data_bank_status.map((item, idx) => (
                                <tr key={idx}>
                                  <td className='text-center'>{idx + 1}</td>
                                  <td>{item.BankName}</td>
                                  <td>
                                    <div className='img_b'>
                                      <img
                                        style={{
                                          height: '35px',
                                          width: '35px',
                                        }}
                                        src={item.image}
                                        alt={item.image}
                                      />
                                    </div>
                                  </td>
                                  <td className='text-center'>
                                    {item.date_updated}
                                  </td>
                                  {/* <td className='text-center'>0</td> */}
                                  <td className='text-center'>
                                    {item.total_balance}
                                  </td>
                                  <td className='text-center'>
                                    {parseInt(item.c_a_flag) !== 0 ? (
                                      <button
                                        className='button status bg-1'
                                        // data-toggle='modal'
                                        // data-target='#DeleteModal'
                                        onClick={() =>
                                          this.handleChange(
                                            parseInt(item.c_a_id),
                                            'stop'
                                          )
                                        }
                                      >
                                        Stop
                                      </button>
                                    ) : (
                                      <button
                                        className='button status bg-3'
                                        // data-toggle='modal'
                                        // data-target='#DeleteModal'
                                        onClick={() =>
                                          this.handleChange(
                                            parseInt(item.c_a_id),
                                            'start'
                                          )
                                        }
                                      >
                                        Start
                                      </button>
                                    )}
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>
                        <div className='col-12 col-lg-9 col-md-7 mb-4 mb-md-0'>
                          {/* <div className='dataTables_length'>
                        <label>Records per page:</label>
                        <select className='form-control custom-select'>
                          <option value={10}>10</option>
                          <option value={25}>25</option>
                          <option value={50}>50</option>
                          <option value={100}>100</option>
                        </select>
                      </div> */}
                        </div>
                        <div className='col-12 col-lg-3 col-md-5'></div>
                      </div>

                      <div className='table-responsive'>
                        <Tabs>
                          <TabList className='position-relative'>
                            <Tab>ยังไม่ดำเนินการ</Tab>
                            <Tab>รอโอน</Tab>
                            <Tab>ดำเนินการแล้ว</Tab>
                            <Tab>ยกเลิก</Tab>
                            <Tab>
                              รายการไม่สำเร็จ{' '}
                              <span
                                class='text-span'
                                style={{
                                  fontSize: '20px',
                                  fontWeight: 'bolder',
                                }}
                              >
                                {this.state.dataCountFailed}
                              </span>
                            </Tab>
                            <Tab>รายการ SMS</Tab>
                            <Tab>รายการ SMS (2)</Tab>
                            <Tab>บอท Auto</Tab>

                            <div className='box-shadow'>
                              <span>Refresh : </span>
                              <span className='txt'> {this.state.count}</span>
                            </div>
                          </TabList>

                          <TabPanel>
                            {/* <h2>Any content 1</h2> */}
                            <table id='sortby' className='table table-bordered'>
                              <thead className='thead-dark text-center'>
                                <tr>
                                  <th scope='col' className='sortable asc'>
                                    #
                                  </th>
                                  <th scope='col' className='sortable'>
                                    ธนาคาร(เวป)
                                  </th>
                                  <th scope='col' className='sortable'>
                                    สัญลักษณ์
                                  </th>
                                  <th scope='col' className='sortable'>
                                    เวลา
                                  </th>
                                  <th scope='col' className='sortable'>
                                    รายการถอน
                                  </th>
                                  <th scope='col' className='sortable'>
                                    ชื่อคนทำรายการ
                                  </th>
                                  <th scope='col' className='sortable'>
                                    บัญชีผู้ถอน
                                  </th>
                                  <th scope='col' className='sortable'>
                                    ชื่อบัญชีลูกค้า
                                  </th>

                                  <th scope='col' className='sortable'>
                                    รหัสผู้เล่น
                                  </th>
                                  <th scope='col' className='sortable'>
                                    สถานะ
                                  </th>
                                  <th scope='col' className='sortable'>
                                    kill ค้าง
                                  </th>
                                  <th scope='col' className='sortable'>
                                    error
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                {this.state.bank_transfer_wait.map(
                                  (item, idx) => (
                                    <tr key={idx}>
                                      <td className='text-center'>{idx + 1}</td>
                                      <td>{item.NameOfBank}</td>
                                      <td>
                                        <div className='img_b'>
                                          <img
                                            src={item.image}
                                            alt={item.image}
                                          />
                                        </div>
                                      </td>
                                      <td className='text-center'>
                                        {item.withdraw_time}
                                      </td>
                                      <td>{item.withdraw_amount}</td>
                                      <td>{item.line_display_name}</td>
                                      <td>{item.bank_account_no}</td>
                                      <td
                                        style={{
                                          width: '400px',
                                          whiteSpace: 'normal',
                                        }}
                                      >
                                        {item.full_name}
                                      </td>
                                      <td>{item.username}</td>
                                      <td className='text-center'>
                                        {item.flag_status ===
                                          'wait process' && (
                                          <div className='txt-status-1 exclamationcircle'>
                                            อยู่ระหว่างรอ
                                          </div>
                                        )}
                                        {item.flag_status === 'processing' && (
                                          <div className='txt-status-3 executecircle'>
                                            กำลังดำเนินการ
                                          </div>
                                        )}
                                        {item.flag_status === 'approved' && (
                                          <div className='txt-status checkcircle'>
                                            Approved
                                          </div>
                                        )}
                                        {item.flag_status === 'success' && (
                                          <div className='txt-status checkcircle'>
                                            สำเร็จ
                                          </div>
                                        )}

                                        {item.flag_status === 'fail' && (
                                          <div className='txt-status-2 timescircle'>
                                            ไม่สำเร็จ
                                          </div>
                                        )}
                                      </td>

                                      <td>
                                        {item.process_id !== '0' && (
                                          <>
                                            <button
                                              className='button status bg-2'
                                              onClick={() =>
                                                this.kill_RerunProcess(
                                                  parseInt(
                                                    item.bank_transaction_id
                                                  )
                                                )
                                              }
                                            >
                                              ทำใหม่
                                            </button>
                                            <button
                                              className='button status bg-1'
                                              onClick={() =>
                                                this.onCancelClick(
                                                  parseInt(
                                                    item.bank_transaction_id
                                                  )
                                                )
                                              }
                                            >
                                              Kill
                                            </button>
                                          </>
                                        )}
                                        {item.process_id === '0' && (
                                          <>
                                            {item.flag_status !==
                                              'approved' && (
                                              <>
                                                <button
                                                  className='button status bg-2'
                                                  onClick={() =>
                                                    this.onApproveClick(
                                                      parseInt(item.wd_id),
                                                      'KBANK'
                                                    )
                                                  }
                                                >
                                                  KBANK
                                                </button>
                                                <button
                                                  className='button status bg-2'
                                                  onClick={() =>
                                                    this.onApproveClick(
                                                      parseInt(item.wd_id),
                                                      'KPLUS'
                                                    )
                                                  }
                                                >
                                                  KPLUS
                                                </button>
                                                <button
                                                  className='button status bg-2'
                                                  onClick={() =>
                                                    this.onApproveClick(
                                                      parseInt(item.wd_id),
                                                      'SCB'
                                                    )
                                                  }
                                                >
                                                  SCB
                                                </button>
                                              </>
                                            )}
                                            <button
                                              className='button status bg-3'
                                              onClick={() =>
                                                this.onDoneClick(
                                                  parseInt(parseInt(item.wd_id))
                                                )
                                              }
                                            >
                                              โอนมือ
                                            </button>
                                            <button
                                              className='button status bg-1'
                                              onClick={() =>
                                                this.onCancelClick(
                                                  parseInt(parseInt(item.wd_id))
                                                )
                                              }
                                            >
                                              ยกเลิก
                                            </button>
                                          </>
                                        )}
                                      </td>
                                      <td>{item.error_msg}</td>
                                    </tr>
                                  )
                                )}
                              </tbody>
                            </table>
                            <RotateLoader
                              css={override}
                              size={15}
                              color={'#FFDF00'}
                              loading={this.state.loading}
                            />
                          </TabPanel>
                          <TabPanel>
                            {/* <h2>รอโอน</h2> */}
                            {/* <div className='col-12 col-lg-9 col-md-7 mb-4 mb-md-0 mt-4'>
                              <div className='dataTables_length'>
                                <label>กรุณาเลือกวันที่ :</label>
                                <DateTimeRangePicker
                                  onChange={this.onChange}
                                  value={value}
                                />
                                <button
                                  class='button status bg-2'
                                  data-toggle='modal'
                                  data-target='#AddModal'
                                  onClick={() => this.searchData()}
                                >
                                  ค้นหา
                                </button>
                              </div>
                            </div> */}
                            <div className='table-responsive mt-4'>
                              <ToolkitProvider
                                keyField='id'
                                data={this.state.data_wait_bank_transfer}
                                columns={columnsTransfer}
                                search
                                exportCSV
                                striped
                                hover
                                condensed
                              >
                                {(props) => (
                                  <>
                                    <SearchBar {...props.searchProps} />
                                    <BootstrapTable
                                      id='table-to-xls-usr-contact'
                                      pagination={paginationFactory(options)}
                                      {...props.baseProps}
                                    />
                                  </>
                                )}
                              </ToolkitProvider>
                            </div>
                          </TabPanel>
                          <TabPanel>
                            <table id='sortby' className='table table-bordered'>
                              <thead className='thead-dark text-center'>
                                <tr>
                                  <th scope='col' className='sortable asc'>
                                    #
                                  </th>
                                  <th scope='col' className='sortable'>
                                    ธนาคาร(เวป)
                                  </th>
                                  <th scope='col' className='sortable'>
                                    สัญลักษณ์
                                  </th>
                                  <th scope='col' className='sortable'>
                                    เวลา
                                  </th>
                                  <th scope='col' className='sortable'>
                                    รายการถอน
                                  </th>
                                  <th scope='col' className='sortable'>
                                    ชื่อคนทำรายการ
                                  </th>
                                  <th scope='col' className='sortable'>
                                    บัญชีผู้ถอน
                                  </th>
                                  <th scope='col' className='sortable'>
                                    ชื่อบัญชีลูกค้า
                                  </th>

                                  <th scope='col' className='sortable'>
                                    รหัสผู้เล่น
                                  </th>
                                  <th scope='col' className='sortable'>
                                    สถานะ
                                  </th>
                                  {/* <th scope='col' className='sortable'>
                                    kill ค้าง
                                  </th> */}
                                  <th scope='col' className='sortable'>
                                    เลขอ้างอิง
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                {this.state.bank_transfer_success.map(
                                  (item, idx) => (
                                    <tr key={idx}>
                                      <td className='text-center'>{idx + 1}</td>
                                      <td>{item.NameOfBank}</td>
                                      <td>
                                        <div className='img_b'>
                                          <img
                                            src={item.image}
                                            alt={item.image}
                                          />
                                        </div>
                                      </td>
                                      <td className='text-center'>
                                        {item.withdraw_time}
                                      </td>
                                      <td>{item.withdraw_amount}</td>
                                      <td>{item.line_display_name}</td>
                                      <td>{item.bank_account_no}</td>
                                      <td
                                        style={{
                                          width: '400px',
                                          whiteSpace: 'normal',
                                        }}
                                      >
                                        {item.full_name}
                                      </td>
                                      <td>{item.username}</td>
                                      <td className='text-center'>
                                        {item.flag_status ===
                                          'wait process' && (
                                          <div className='txt-status-1 exclamationcircle'>
                                            อยู่ระหว่างรอ
                                          </div>
                                        )}
                                        {item.flag_status === 'processing' && (
                                          <div className='txt-status-3 executecircle'>
                                            กำลังดำเนินการ
                                          </div>
                                        )}
                                        {item.flag_status === 'approved' && (
                                          <div className='txt-status checkcircle'>
                                            Approved
                                          </div>
                                        )}
                                        {item.flag_status === 'success' && (
                                          <div className='txt-status checkcircle'>
                                            สำเร็จ
                                          </div>
                                        )}
                                        {item.flag_status === 'done' && (
                                          <div className='txt-status checkcircle'>
                                            สำเร็จ - (done)
                                          </div>
                                        )}
                                        {item.flag_status === 'success_manual' && (
                                          <div className='txt-status checkcircle'>
                                            สำเร็จ - (โอนมือ)
                                          </div>
                                        )}
                                        {item.flag_status === 'fail' && (
                                          <div className='txt-status-2 timescircle'>
                                            ไม่สำเร็จ
                                          </div>
                                        )}
                                      </td>

                                      {/* <td>
                                        {item.process_id !== '0' && (
                                          <>
                                            <button
                                              className='button status bg-2'
                                              onClick={() =>
                                                this.kill_RerunProcess(
                                                  parseInt(
                                                    item.bank_transaction_id
                                                  )
                                                )
                                              }
                                            >
                                              ทำใหม่
                                            </button>
                                            <button
                                              className='button status bg-1'
                                              onClick={() =>
                                                this.killProcess(
                                                  parseInt(
                                                    item.bank_transaction_id
                                                  )
                                                )
                                              }
                                            >
                                              Kill
                                            </button>
                                          </>
                                        )}
                                        {item.process_id === '0' && (
                                          <>
                                            {item.flag_status !==
                                              'approved' && (
                                              <button
                                                className='button status bg-2'
                                                onClick={() =>
                                                  this.onApproveClick(
                                                    parseInt(item.wd_id)
                                                  )
                                                }
                                              >
                                                Approve
                                              </button>
                                            )}

                                            <button
                                              className='button status bg-1'
                                              onClick={() =>
                                                this.onCancelClick(
                                                  parseInt(parseInt(item.wd_id))
                                                )
                                              }
                                            >
                                              ยกเลิก
                                            </button>
                                          </>
                                        )}
                                      </td> */}
                                      <td>{item.error_msg}</td>
                                    </tr>
                                  )
                                )}
                              </tbody>
                            </table>
                          </TabPanel>
                          <TabPanel>
                            <table id='sortby' className='table table-bordered'>
                              <thead className='thead-dark text-center'>
                                <tr>
                                  <th scope='col' className='sortable asc'>
                                    #
                                  </th>
                                  <th scope='col' className='sortable'>
                                    ธนาคาร(เวป)
                                  </th>
                                  <th scope='col' className='sortable'>
                                    สัญลักษณ์
                                  </th>
                                  <th scope='col' className='sortable'>
                                    เวลา
                                  </th>
                                  <th scope='col' className='sortable'>
                                    รายการถอน
                                  </th>
                                  <th scope='col' className='sortable'>
                                    ชื่อคนทำรายการ
                                  </th>
                                  <th scope='col' className='sortable'>
                                    บัญชีผู้ถอน
                                  </th>
                                  <th scope='col' className='sortable'>
                                    ชื่อบัญชีลูกค้า
                                  </th>

                                  <th scope='col' className='sortable'>
                                    รหัสผู้เล่น
                                  </th>
                                  <th scope='col' className='sortable'>
                                    สถานะ
                                  </th>
                                  <th scope='col' className='sortable'>
                                    kill ค้าง
                                  </th>
                                  <th scope='col' className='sortable'>
                                    error
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                {this.state.bank_transfer_cancel.map(
                                  (item, idx) => (
                                    <tr key={idx}>
                                      <td className='text-center'>{idx + 1}</td>
                                      <td>{item.NameOfBank}</td>
                                      <td>
                                        <div className='img_b'>
                                          <img
                                            src={item.image}
                                            alt={item.image}
                                          />
                                        </div>
                                      </td>
                                      <td className='text-center'>
                                        {item.withdraw_time}
                                      </td>
                                      <td>{item.withdraw_amount}</td>
                                      <td>{item.line_display_name}</td>
                                      <td>{item.bank_account_no}</td>
                                      <td
                                        style={{
                                          width: '400px',
                                          whiteSpace: 'normal',
                                        }}
                                      >
                                        {item.full_name}
                                      </td>
                                      <td>{item.username}</td>
                                      <td className='text-center'>
                                        {item.flag_status ===
                                          'wait process' && (
                                          <div className='txt-status-1 exclamationcircle'>
                                            อยู่ระหว่างรอ
                                          </div>
                                        )}
                                        {item.flag_status === 'processing' && (
                                          <div className='txt-status-3 executecircle'>
                                            กำลังดำเนินการ
                                          </div>
                                        )}
                                        {item.flag_status === 'approved' && (
                                          <div className='txt-status checkcircle'>
                                            Approved
                                          </div>
                                        )}
                                        {item.flag_status === 'success' && (
                                          <div className='txt-status checkcircle'>
                                            สำเร็จ
                                          </div>
                                        )}
                                        {item.flag_status === 'fail' && (
                                          <div className='txt-status-2 timescircle'>
                                            ไม่สำเร็จ
                                          </div>
                                        )}
                                        {item.flag_status === 'cancelled' && (
                                          <div className='txt-status-2 timescircle'>
                                            ยกเลิก
                                          </div>
                                        )}
                                      </td>

                                      <td>
                                        {item.process_id !== '0' && (
                                          <>
                                            <button
                                              className='button status bg-2'
                                              onClick={() =>
                                                this.kill_RerunProcess(
                                                  parseInt(
                                                    item.bank_transaction_id
                                                  )
                                                )
                                              }
                                            >
                                              ทำใหม่
                                            </button>
                                            <button
                                              className='button status bg-1'
                                              onClick={() =>
                                                this.killProcess(
                                                  parseInt(
                                                    item.bank_transaction_id
                                                  )
                                                )
                                              }
                                            >
                                              Kill
                                            </button>
                                          </>
                                        )}
                                        {item.process_id === '0' && (
                                          <>
                                            {item.flag_status !==
                                              'approved' && (
                                              <button
                                                className='button status bg-2'
                                                onClick={() =>
                                                  this.onApproveClick(
                                                    parseInt(item.wd_id),
                                                    'KBANK'
                                                  )
                                                }
                                              >
                                                ทำใหม่
                                              </button>
                                            )}

                                            {/* <button
                                              className='button status bg-1'
                                              onClick={() =>
                                                this.onCancelClick(
                                                  parseInt(parseInt(item.wd_id))
                                                )
                                              }
                                            >
                                              ยกเลิก
                                            </button> */}
                                          </>
                                        )}
                                      </td>
                                      <td>{item.error_msg}</td>
                                    </tr>
                                  )
                                )}
                              </tbody>
                            </table>
                          </TabPanel>
                          <TabPanel>
                            <table id='sortby' className='table table-bordered'>
                              <thead className='thead-dark text-center'>
                                <tr>
                                  <th scope='col' className='sortable asc'>
                                    #
                                  </th>
                                  <th scope='col' className='sortable'>
                                    ธนาคาร(เวป)
                                  </th>
                                  <th scope='col' className='sortable'>
                                    สัญลักษณ์
                                  </th>
                                  <th scope='col' className='sortable'>
                                    เวลา
                                  </th>
                                  <th scope='col' className='sortable'>
                                    รายการถอน
                                  </th>
                                  <th scope='col' className='sortable'>
                                    ชื่อคนทำรายการ
                                  </th>
                                  <th scope='col' className='sortable'>
                                    บัญชีผู้ถอน
                                  </th>
                                  <th scope='col' className='sortable'>
                                    ชื่อบัญชีลูกค้า
                                  </th>

                                  <th scope='col' className='sortable'>
                                    รหัสผู้เล่น
                                  </th>
                                  <th scope='col' className='sortable'>
                                    สถานะ
                                  </th>
                                  <th scope='col' className='sortable'>
                                    kill ค้าง
                                  </th>
                                  <th scope='col' className='sortable'>
                                    error
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                {this.state.bank_transfer_fail.map(
                                  (item, idx) => (
                                    <tr key={idx}>
                                      <td className='text-center'>{idx + 1}</td>
                                      <td>{item.NameOfBank}</td>
                                      <td>
                                        <div className='img_b'>
                                          <img
                                            src={item.image}
                                            alt={item.image}
                                          />
                                        </div>
                                      </td>
                                      <td className='text-center'>
                                        {item.withdraw_time}
                                      </td>
                                      <td>{item.withdraw_amount}</td>
                                      <td>{item.line_display_name}</td>
                                      <td>{item.bank_account_no}</td>
                                      <td
                                        style={{
                                          width: '400px',
                                          whiteSpace: 'normal',
                                        }}
                                      >
                                        {item.full_name}
                                      </td>
                                      <td>{item.username}</td>
                                      <td className='text-center'>
                                        {item.flag_status ===
                                          'wait process' && (
                                          <div className='txt-status-1 exclamationcircle'>
                                            อยู่ระหว่างรอ
                                          </div>
                                        )}
                                        {item.flag_status === 'processing' && (
                                          <div className='txt-status-3 executecircle'>
                                            กำลังดำเนินการ
                                          </div>
                                        )}
                                        {item.flag_status === 'approved' && (
                                          <div className='txt-status checkcircle'>
                                            Approved
                                          </div>
                                        )}
                                        {item.flag_status === 'success' && (
                                          <div className='txt-status checkcircle'>
                                            สำเร็จ
                                          </div>
                                        )}
                                        {item.flag_status === 'fail' && (
                                          <div className='txt-status-2 timescircle'>
                                            ไม่สำเร็จ
                                          </div>
                                        )}
                                        {item.flag_status === 'cancelled' && (
                                          <div className='txt-status-2 timescircle'>
                                            ยกเลิก
                                          </div>
                                        )}
                                      </td>

                                      <td>
                                        {item.process_id !== '0' && (
                                          <>
                                            <button
                                              className='button status bg-2'
                                              onClick={() =>
                                                this.kill_RerunProcess(
                                                  parseInt(
                                                    item.wd_id
                                                  )
                                                )
                                              }
                                            >
                                              ทำใหม่
                                            </button>
                                            <button
                                              className='button status bg-3'
                                              onClick={() =>
                                                this.onTransferSuccess(
                                                  parseInt(
                                                    item.wd_id
                                                  )
                                                )
                                              }
                                            >
                                              โอนแล้ว
                                            </button>
                                            <button
                                              className='button status bg-1'
                                              onClick={() =>
                                                this.onCancelClick(
                                                  parseInt(
                                                    item.wd_id
                                                  )
                                                )
                                              }
                                            >
                                              ยกเลิก
                                            </button>
                                          </>
                                        )}
                                        {item.process_id === '0' && (
                                          <>
                                            {item.flag_status !==
                                              'approved' && (
                                              <button
                                                className='button status bg-2'
                                                onClick={() =>
                                                  this.onApproveClick(
                                                    parseInt(item.wd_id)
                                                  )
                                                }
                                              >
                                                ทำใหม่
                                              </button>
                                            )}

                                            {/* <button
                                              className='button status bg-1'
                                              onClick={() =>
                                                this.onCancelClick(
                                                  parseInt(parseInt(item.wd_id))
                                                )
                                              }
                                            >
                                              ยกเลิก
                                            </button> */}
                                          </>
                                        )}
                                      </td>
                                      <td>{item.error_msg}</td>
                                    </tr>
                                  )
                                )}
                              </tbody>
                            </table>
                          </TabPanel>

                          <TabPanel>
                            <table id='sortby' className='table table-bordered'>
                              <thead className='thead-dark text-center'>
                                <tr>
                                  <th scope='col' className='sortable asc'>
                                    #
                                  </th>
                                  {/* <th scope='col' className='sortable'>
                                ธนาคาร(เวป)
                              </th> */}
                                  {/* <th scope='col' className='sortable'>
                                    ชื่อผู้ส่ง
                                  </th> */}
                                  <th scope='col' className='sortable'>
                                    เบอร์ผู้ส่ง
                                  </th>
                                  <th scope='col' className='sortable'>
                                    เนื้อหา
                                  </th>
                                  <th scope='col' className='sortable'>
                                    เวลาที่ได้รับ
                                  </th>
                                  {/* <th scope='col' className='sortable'>
                                    เวลาที่ส่ง
                                  </th> */}
                                  <th scope='col' className='sortable'>
                                    เบอร์ที่ใช้ส่ง
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                {this.state.data_rpt.map((dep, idx) => (
                                  <tr key={idx}>
                                    <td className='text-center'>{idx + 1}</td>
                                    {/* <td>{dep.addr_body}</td> */}
                                    <td className='text-center'>
                                      {dep.address}
                                    </td>
                                    {/* <td style={{width: "400px", whiteSpace: "normal"}}>{dep.body}</td> */}
                                    <td>{dep.body}</td>
                                    {/* <td>{dep.categoryList}</td> */}
                                    {/* <td>{dep.withdraw}</td> */}
                                    <td className='text-center'>
                                      {dep.sms_date}
                                    </td>
                                    {/* <td>{dep.sms_date_sent}</td> */}
                                    <td className='text-center'>
                                      {dep.service_center}
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          </TabPanel>

                          <TabPanel>
                            <table id='sortby' className='table table-bordered'>
                              <thead className='thead-dark text-center'>
                                <tr>
                                  <th scope='col' className='sortable asc'>
                                    #
                                  </th>
                                  {/* <th scope='col' className='sortable'>
                                ธนาคาร(เวป)
                              </th> */}
                                  {/* <th scope='col' className='sortable'>
                                    ชื่อผู้ส่ง
                                  </th> */}
                                  <th scope='col' className='sortable'>
                                    เบอร์ผู้ส่ง
                                  </th>
                                  <th scope='col' className='sortable'>
                                    เนื้อหา
                                  </th>
                                  <th scope='col' className='sortable'>
                                    เวลาที่ได้รับ
                                  </th>
                                  {/* <th scope='col' className='sortable'>
                                    เวลาที่ส่ง
                                  </th> */}
                                  <th scope='col' className='sortable'>
                                    เบอร์ที่ใช้ส่ง
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                {this.state.data_rpt_sms_mobile_2.map(
                                  (dep, idx) => (
                                    <tr key={idx}>
                                      <td className='text-center'>{idx + 1}</td>
                                      {/* <td>{dep.addr_body}</td> */}
                                      <td className='text-center'>
                                        {dep.address}
                                      </td>
                                      {/* <td style={{width: "400px", whiteSpace: "normal"}}>{dep.body}</td> */}
                                      <td>{dep.body}</td>
                                      {/* <td>{dep.categoryList}</td> */}
                                      {/* <td>{dep.withdraw}</td> */}
                                      <td className='text-center'>
                                        {dep.sms_date}
                                      </td>
                                      {/* <td>{dep.sms_date_sent}</td> */}
                                      <td className='text-center'>
                                        {dep.service_center}
                                      </td>
                                    </tr>
                                  )
                                )}
                              </tbody>
                            </table>
                          </TabPanel>

                          <TabPanel>
                            <div className='table-responsive'>
                              <table
                                id='sortby'
                                className='table table-bordered'
                              >
                                <thead className='thead-dark text-center'>
                                  <tr>
                                    <th scope='col'>#</th>
                                    <th scope='col'>ชื่อธนาคาร</th>
                                    <th scope='col'>สัญลักษณ์</th>
                                    <th scope='col'>ปุ่มเปิด/ปิด</th>
                                    <th scope='col'>สถานะ</th>
                                    {/* <th scope='col'>การจัดการ</th> */}
                                  </tr>
                                </thead>
                                <tbody>
                                  {this.state.data_auto.map((item, index) => (
                                    <>
                                      <tr key={index}>
                                        <td className='text-center'>
                                          {index + 1}
                                        </td>
                                        <td>{item.NameOfBank}</td>
                                        <td>
                                          <div className='img_b'>
                                            <img
                                              src={item.image}
                                              alt={item.BankName}
                                            />
                                          </div>
                                        </td>

                                        <td className='text-center'>
                                          {parseInt(item.flag) !== 0 && (
                                            <button
                                              className='button status bg-1'
                                              // data-toggle='modal'
                                              // data-target='#DeleteModal'
                                              onClick={() =>
                                                this.handleChange(
                                                  parseInt(item.ca_id),
                                                  'stop'
                                                )
                                              }
                                            >
                                              Stop
                                            </button>
                                          )}
                                          {parseInt(item.flag) === 0 && (
                                            <button
                                              className='button status bg-3'
                                              // data-toggle='modal'
                                              // data-target='#DeleteModal'
                                              onClick={() =>
                                                this.handleChange(
                                                  parseInt(item.ca_id),
                                                  'start'
                                                )
                                              }
                                            >
                                              Start
                                            </button>
                                          )}
                                        </td>
                                        <td className='text-center'>
                                          {parseInt(item.flag) === 0 && (
                                            <div class='txt-status-2 timescircle'>
                                              Stop
                                            </div>
                                          )}
                                          {parseInt(item.flag) === 1 && (
                                            <div className='txt-status checkcircle'>
                                              Running
                                            </div>
                                          )}
                                        </td>
                                        {/* <td className='text-center'>
                                          <button
                                            className='button status bg-3'
                                            data-toggle='modal'
                                            data-target='#EditModal'
                                          >
                                            แก้ไข
                                          </button>
                                          <button
                                            className='button status bg-1'
                                            data-toggle='modal'
                                            data-target='#DeleteModal'
                                          >
                                            ลบ
                                          </button>
                                        </td> */}
                                      </tr>
                                    </>
                                  ))}
                                </tbody>
                              </table>
                            </div>
                          </TabPanel>
                        </Tabs>
                      </div>
                      <div className='row mt-4'></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
    );
  }
}

export default BankWithdraw;
