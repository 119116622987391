import React, { Component } from 'react';
import axios, { post } from 'axios';
import './upload.css';
import { myConfig } from '../../constants/Constants';

const SERVER_URL = myConfig.apiUrl;

export class ReTransfer extends Component {

  constructor(props) {
    super(props);
    this.state = {
      file: null,
    };
    this.onSubmit = this.onSubmit.bind(this);
    this.onChange = this.onChange.bind(this);
    this.uploadFile = this.uploadFile.bind(this);
  }

  async onSubmit(e) {
    e.preventDefault();
    let res = await this.uploadFile(this.state.file);
    console.log(res.data);
  }

  onChange(e) {
    this.setState({ file: e.target.files[0] });
  }

  async uploadFile(file) {

    const UPLOAD_ENDPOINT = `${SERVER_URL}/upload_excel.php`;

    console.log("file : ", file);
    const formData = new FormData();

    formData.append('avatar', file);

    return await axios.post(UPLOAD_ENDPOINT, formData, {
      headers: {
        'content-type': 'multipart/form-data',
      },
    });
  }

  render() {
    return (
      <form onSubmit={this.onSubmit}>
        <h1> React File Upload Example</h1>
        <input type='file' onChange={this.onChange} />
        <button type='submit'>Upload File</button>
      </form>
    );
  }
}

export default ReTransfer;
