import React from 'react';
import { Route, withRouter } from 'react-router-dom';
import Login from './pages/Login/Login';
import DashboardLayout from './hoc/Layout/DashboardLayout';
import Landing from './pages/Landing/Landing';

const AppRoute = ({ component: Component, layout: Layout, group, tags, changeData, ...rest }) => (
  <Route
    {...rest}
    render={(props) => 
      // sessionStorage.getItem('userData') ? (
      localStorage.getItem('login') ? (
        group.find(item => parseInt(tags) == item.CodeID) ? (
          <Layout>
            <Component {...props} />
          </Layout>
          // <Login changeData={changeData} />
        ) : (
  
          <DashboardLayout>
            <Landing {...props} />
          </DashboardLayout>
          // this.props.history.push('/dashboard')
        )
      ) : (
        <Login changeData={changeData} />
      )
    }
  />
);

export default withRouter(AppRoute);
