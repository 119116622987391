import React, { Component } from 'react';
import axios from 'axios';
import DateTimeRangePicker from '@wojtekmaj/react-datetimerange-picker';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import BootstrapTable from 'react-bootstrap-table-next';
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import * as ReactBootStrap from 'react-bootstrap';

import 'react-tabs/style/react-tabs.css';
import { myConfig } from '../../constants/Constants';
import { Link } from 'react-router-dom';

const SERVER_URL = myConfig.apiUrl;

const now = new Date();
const yesterdayBegin = new Date(
  now.getFullYear(),
  now.getMonth(),
  now.getDate(),
  now.getHours()
);
const todayNoon = new Date(
  now.getFullYear(),
  now.getMonth(),
  now.getDate(),
  now.getHours() + 1
);

export class BankDeposit extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      dataSuccess: [],
      dataFailed: [],
      dataCountFailed: 0,
      value: [yesterdayBegin, todayNoon],
      count: 10,
      memUser: '',
      data_rpt: [],
      data_rpt_sms_mobile_2: [],
      data_rpt_sms_mobile_3: [],
      data_auto: [],
      data_bank_status: [],
      data_bank_kbank_upd: '',
      disabled: false,
    };
    // console.log("open : " + this.props.open);
  }

  async componentDidMount() {
    await this.getBankStatus();
    await this.getBankDepositWait();
    await this.getBankDepositSuccess();
    await this.getBankDepositFail();
    await this.getCountFailed();
    await this.getSmsAll();
    await this.getSmsAllMobile2();
    await this.getSmsAllMobile3();
    await this.getAutoCreditStatus();

    this.interval = setInterval(() => {
      if (this.state.count <= 0) {
        this.setState({
          count: 10,
        });
        this.getBankStatus();
        this.getBankDepositWait();
        this.getBankDepositSuccess();
        this.getBankDepositFail();
        this.getCountFailed();
        this.getSmsAll();
        this.getSmsAllMobile2();
        this.getSmsAllMobile3();
        this.getAutoCreditStatus();
      } else {
        this.setState((prevState) => ({ count: prevState.count - 1 }));
      }
    }, 1000);

    // this.interval = setInterval(() => {
    //   this.getBankDepositWait();
    //   this.getBankDepositSuccess();
    //   this.getBankDepositFail();
    //   this.getCountFailed();
    // }, 10000);
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  onChange = (value) => {
    this.setState({ value });
  };

  getBankStatus = async () => {
    // clearInterval(this.interval);
    let URL_ENDPOINT;

    URL_ENDPOINT = `${SERVER_URL}/bank_status.php`;

    const res = await axios.get(URL_ENDPOINT);
    // console.log('res.data : ', res.data);
    // console.log('res.data[0].date_updated : ', res.data[0].date_updated);
    if (res.data.length > 0) {
      this.setState({
        data_bank_status: res.data,
        data_bank_kbank_upd: res.data[0].date_updated,
      });
    }
  };

  getCurrent(getDate) {
    // var date = new Date();
    var date = getDate;
    var aaaa = date.getFullYear();
    var gg = date.getDate();
    var mm = date.getMonth() + 1;

    if (gg < 10) gg = '0' + gg;

    if (mm < 10) mm = '0' + mm;

    var cur_day = aaaa + '-' + mm + '-' + gg;

    var hours = date.getHours();
    var minutes = date.getMinutes();
    var seconds = date.getSeconds();

    if (hours < 10) hours = '0' + hours;

    if (minutes < 10) minutes = '0' + minutes;

    if (seconds < 10) seconds = '0' + seconds;

    return cur_day + ' ' + hours + ':' + minutes + ':' + seconds;
  }

  getBankDepositWait = async () => {
    let URL_ENDPOINT;

    URL_ENDPOINT = `${SERVER_URL}/bank_dep.php`;

    const res = await axios.get(URL_ENDPOINT);
    // console.log('res.data : ', res.data);
    if (res.data.length > 0) {
      this.setState({
        data: res.data,
      });
    } else {
      this.setState({
        data: [],
      });
    }
  };

  getBankDepositSuccess = async () => {
    let URL_ENDPOINT;

    URL_ENDPOINT = `${SERVER_URL}/bank_dep_suc.php`;

    const res = await axios.get(URL_ENDPOINT);
    // console.log('res.data : ', res.data);
    if (res.data.length > 0) {
      this.setState({
        dataSuccess: res.data,
      });
    } else {
      this.setState({
        dataSuccess: [],
      });
    }
  };

  getBankDepositFail = async () => {
    let URL_ENDPOINT;

    URL_ENDPOINT = `${SERVER_URL}/bank_dep_fail.php`;

    const res = await axios.get(URL_ENDPOINT);
    // console.log('res.data : ', res.data);
    if (res.data.length > 0) {
      this.setState({
        dataFailed: res.data,
      });
    } else {
      this.setState({
        dataFailed: [],
      });
    }
  };

  getCountFailed = async () => {
    let URL_ENDPOINT;

    URL_ENDPOINT = `${SERVER_URL}/bank_cnt_fail.php`;

    const res = await axios.get(URL_ENDPOINT);
    // console.log('res.data : ', res.data[0].count_failed);
    if (res.data.length > 0) {
      this.setState({
        dataCountFailed: res.data[0].count_failed,
      });
    } else {
      this.setState({
        dataCountFailed: 0,
      });
    }
  };

  getSmsAll = async () => {
    let URL_ENDPOINT;

    URL_ENDPOINT = `${SERVER_URL}/get_rpt_sms_otp.php`;

    const res = await axios.get(URL_ENDPOINT);
    // console.log('res.data : ', res.data);
    if (res.data.length > 0) {
      this.setState({
        data_rpt: res.data,
      });
    } else {
      this.setState({
        data_rpt: [],
      });
    }
  };

  getSmsAllMobile2 = async () => {
    let URL_ENDPOINT;

    URL_ENDPOINT = `${SERVER_URL}/get_rpt_sms_otp_mobile_2.php`;

    const res = await axios.get(URL_ENDPOINT);
    // console.log('res.data : ', res.data);
    if (res.data.length > 0) {
      this.setState({
        data_rpt_sms_mobile_2: res.data,
      });
    } else {
      this.setState({
        data_rpt_sms_mobile_2: [],
      });
    }
  };

  getSmsAllMobile3 = async () => {
    let URL_ENDPOINT;

    URL_ENDPOINT = `${SERVER_URL}/get_rpt_sms_otp_mobile_3.php`;

    const res = await axios.get(URL_ENDPOINT);
    // console.log('res.data : ', res.data);
    if (res.data.length > 0) {
      this.setState({
        data_rpt_sms_mobile_3: res.data,
      });
    } else {
      this.setState({
        data_rpt_sms_mobile_3: [],
      });
    }
  };

  searchDataSms = async () => {
    // console.log('this.state.value : ', this.state.value);
    let dateFrom = this.getCurrent(this.state.value[0]);
    let dateTo = this.getCurrent(this.state.value[1]);

    if (dateFrom > dateTo) {
      alert('กรุณาใส่วันที่ให้ถูกต้อง');
    } else {
      let URL_ENDPOINT;

      URL_ENDPOINT = `${SERVER_URL}/get_rpt_credit_date.php?date_from=${dateFrom}&date_to=${dateTo}`;

      const res = await axios.get(URL_ENDPOINT);
      // console.log('res.data : ', res.data);
      if (res.data.length > 0) {
        this.setState({
          data_rpt: res.data,
        });
      }
    }
  };

  _handleKeyDown = async (
    e,
    bank_transaction_id,
    transactionDT,
    deposit,
    ShortNameEn
  ) => {
    if (e.key === 'Enter') {
      clearInterval(this.interval);

      this.setState({
        disabled: !this.state.disabled,
      });

      let getUid = localStorage.getItem('uid');
      let getName = localStorage.getItem('name');

      console.log('do validate : ', e.target.value.toLowerCase());
      let getUsername = e.target.value.toLowerCase();
      let line_display_name = getName;
      let line_id = getUid;
      let tmpDeposit = deposit.split('.')[0];
      let tmpTranDt = transactionDT.split(' ')[1];
      let tmpTranDt2 = tmpTranDt.replace(':', '.');
      let lastTranId = parseInt(bank_transaction_id);
      let lastTranDt = tmpTranDt2.replace(':', '.');
      let lastDeposit = tmpDeposit.replace(',', '');
      let lastBankName = ShortNameEn;

      this.setState({
        memUser: e.target.value.toLowerCase(),
      });

      //   console.log('bank_transaction_id : ', parseInt(bank_transaction_id));
      //   console.log('transactionDT : ', transactionDT);
      //   console.log('deposit : ', lastDeposit);
      //   console.log('ShortNameEn : ', ShortNameEn);
      //   console.log('tmpTranDt : ', tmpTranDt);

      let apiUrl;

      if (getUsername.length === 4) {
        // console.log('handleKeyDown!!!!');

        if (getUsername !== 'done') {
          // https://www.finalgoal.online/bkoffice/api/credit/add_credit.php?a_usr=800a&credit=10&b_name=KBank&s_time=testing&ln=testing&l_id=testing
          apiUrl = `${SERVER_URL}/add_credit.php?a_usr=${getUsername}&credit=${lastDeposit}&b_name=${lastBankName}&s_time=${lastTranDt}&ln=${line_display_name}&l_id=${line_id}&b_trans_id=${lastTranId}`;
        } else {
          // console.log("Done")
          apiUrl = `${SERVER_URL}/add_credit2.php?a_usr=${getUsername}&credit=${lastDeposit}&b_name=${lastBankName}&s_time=${lastTranDt}&ln=${line_display_name}&l_id=${line_id}&b_trans_id=${lastTranId}`;
        }

        console.log(apiUrl);

        const res = await axios.get(apiUrl);
        // console.log(res.data);

        setTimeout(() => {
          this.getBankDepositWait();
        }, 400);

        // await this.getBankDepositSuccess();
        // await this.getBankDepositFail();

        this.setState({
          count: 10,
        });

        this.componentDidMount();

        // this.interval = setInterval(() => {
        //   if (this.state.count <= 0) {
        //     this.setState({
        //       count: 10,
        //     });
        //     this.getBankDepositWait();
        //     // this.getBankDepositSuccess();
        //     // this.getBankDepositFail();
        //     // this.getCountFailed();
        //   } else {
        //     this.setState((prevState) => ({ count: prevState.count - 1 }));
        //   }
        // }, 1000);
      } else {
        alert('กรุณาใส่รหัสให้ถูกต้อง 4 หลักเท่านั้น!!!!');
      }
    }
  };

  _handleKeyDown_failed = async (
    e,
    bank_transaction_id,
    transactionDT,
    deposit,
    ShortNameEn
  ) => {
    if (e.key === 'Enter') {
      console.log('do validate : ', e.target.value.toLowerCase());
      let getUsername = e.target.value.toLowerCase();
      let line_display_name = 'Web';
      let line_id = 'Web';
      let tmpDeposit = deposit.split('.')[0];
      let tmpTranDt = transactionDT.split(' ')[1];
      let tmpTranDt2 = tmpTranDt.replace(':', '.');
      let lastTranId = parseInt(bank_transaction_id);
      let lastTranDt = tmpTranDt2.replace(':', '.');
      let lastDeposit = tmpDeposit.replace(',', '');
      let lastBankName = ShortNameEn;

      this.setState({
        memUser: e.target.value.toLowerCase(),
      });

      //   console.log('bank_transaction_id : ', parseInt(bank_transaction_id));
      //   console.log('transactionDT : ', transactionDT);
      //   console.log('deposit : ', lastDeposit);
      //   console.log('ShortNameEn : ', ShortNameEn);
      //   console.log('tmpTranDt : ', tmpTranDt);

      let apiUrl;

      if (getUsername.length === 4) {
        // console.log('handleKeyDown!!!!');

        if (getUsername !== 'done') {
          // https://www.finalgoal.online/bkoffice/api/credit/add_credit.php?a_usr=800a&credit=10&b_name=KBank&s_time=testing&ln=testing&l_id=testing
          apiUrl = `${SERVER_URL}/add_credit.php?a_usr=${getUsername}&credit=${lastDeposit}&b_name=${lastBankName}&s_time=${lastTranDt}&ln=${line_display_name}&l_id=${line_id}&b_trans_id=${lastTranId}`;
        } else {
          // console.log("Done")
          apiUrl = `${SERVER_URL}/add_credit2.php?a_usr=${getUsername}&credit=${lastDeposit}&b_name=${lastBankName}&s_time=${lastTranDt}&ln=${line_display_name}&l_id=${line_id}&b_trans_id=${lastTranId}`;
        }

        console.log(apiUrl);

        const res = await axios.get(apiUrl);
        // console.log(res.data);

        // await this.getBankDepositWait();
        // await this.getBankDepositSuccess();
        await this.getBankDepositFail();
      } else {
        alert('กรุณาใส่รหัสให้ถูกต้อง 4 หลักเท่านั้น!!!!');
      }
    }
  };

  searchData = async () => {
    // console.log('this.state.value : ', this.state.value);
    let dateFrom = this.getCurrent(this.state.value[0]);
    let dateTo = this.getCurrent(this.state.value[1]);

    if (dateFrom > dateTo) {
      alert('กรุณาใส่วันที่ให้ถูกต้อง');
    } else {
      let URL_ENDPOINT;

      URL_ENDPOINT = `${SERVER_URL}/bank_dep_suc_date.php?date_from=${dateFrom}&date_to=${dateTo}`;

      const res = await axios.get(URL_ENDPOINT);
      // console.log('res.data : ', res.data);
      if (res.data.length > 0) {
        this.setState({
          dataSuccess: res.data,
        });
      }
    }
  };

  killProcess = async (bank_transaction_id) => {
    let apiUrl = `${SERVER_URL}/kill_py_process.php?trans_id=${bank_transaction_id}`;
    // console.log(apiUrl);
    const res = axios.get(apiUrl);
  };

  kill_RerunProcess = async (bank_transaction_id) => {
    let apiUrl = `${SERVER_URL}/kill_and_rerun_py_process.php?trans_id=${bank_transaction_id}`;
    // console.log(apiUrl);
    const res = axios.get(apiUrl);
  };

  getAutoCreditStatus = async () => {
    // clearInterval(this.interval);
    let URL_ENDPOINT;

    URL_ENDPOINT = `${SERVER_URL}/auto_status_all.php`;

    const res = await axios.get(URL_ENDPOINT);
    // console.log('res.data : ', res.data);
    if (res.data.length > 0) {
      this.setState({
        data_auto: res.data,
      });
    }
  };

  handleChange(auto_id, status) {
    let apiUrl;

    console.log('auto_id : ', auto_id);
    console.log('status : ', status);

    if (status === 'stop') {
      apiUrl = `${SERVER_URL}/kill_auto_status.php?b_id=${parseInt(auto_id)}`;
    } else {
      apiUrl = `${SERVER_URL}/call_auto_status.php?b_id=${parseInt(auto_id)}`;
    }

    // console.log("apiUrl [Auto] : ", apiUrl);
    const res = axios.get(apiUrl);
    setTimeout(() => {
      this.getAutoCreditStatus();
    }, 400);
  }

  onApproveClick = (
    bank_transaction_id,
    transactionDT,
    deposit,
    ShortNameEn
  ) => {
    clearInterval(this.interval);

    let getUid = localStorage.getItem('uid');
    let getName = localStorage.getItem('name');

    let getUsername = this.state.inputUsername;
    let line_display_name = getName;
    let line_id = getUid;
    let tmpDeposit = deposit.split('.')[0];
    let tmpTranDt = transactionDT.split(' ')[1];
    let tmpTranDt2 = tmpTranDt.replace(':', '.');
    let lastTranId = parseInt(bank_transaction_id);
    let lastTranDt = tmpTranDt2.replace(':', '.');
    let lastDeposit = tmpDeposit.replace(',', '');
    let lastBankName = ShortNameEn;

    this.setState({
      memUser: this.state.inputUsername,
    });

    //   console.log('bank_transaction_id : ', parseInt(bank_transaction_id));
    //   console.log('transactionDT : ', transactionDT);
    //   console.log('deposit : ', lastDeposit);
    //   console.log('ShortNameEn : ', ShortNameEn);
    //   console.log('tmpTranDt : ', tmpTranDt);

    let apiUrl;

    if (getUsername.length === 5) {
      // console.log('handleKeyDown!!!!');

      apiUrl = `${SERVER_URL}/add_credit.php?a_usr=${getUsername}&credit=${lastDeposit}&b_name=${lastBankName}&s_time=${transactionDT}&ln=${line_display_name}&l_id=${line_id}&b_trans_id=${lastTranId}`;

      console.log(apiUrl);

      const res = axios.get(apiUrl);
      // console.log(res.data);

      setTimeout(() => {
        this.getBankDepositWait();
      }, 400)
      
      // await this.getBankDepositSuccess();
      // await this.getBankDepositFail();

      this.componentDidMount();
    } else {
      alert('กรุณาใส่รหัสให้ถูกต้อง 5 หลักเท่านั้น!!!!');
    }
  };

  onCancelClick = (
    bank_transaction_id,
    transactionDT,
    deposit,
    ShortNameEn
  ) => {
    console.log('OnCancel Click!!!');
    clearInterval(this.interval);

    let getUid = localStorage.getItem('uid');
    let getName = localStorage.getItem('name');

    console.log('deposit : ', deposit);

    let getUsername = 'done';
    let line_display_name = getName;
    let line_id = getUid;
    let tmpDeposit = deposit.split('.')[0];
    let tmpTranDt = transactionDT.split(' ')[1];
    let tmpTranDt2 = tmpTranDt.replace(':', '.');
    let lastTranId = parseInt(bank_transaction_id);
    let lastTranDt = tmpTranDt2.replace(':', '.');
    let lastDeposit = tmpDeposit.replace(',', '');
    let lastBankName = ShortNameEn;

    let apiUrl;

    apiUrl = `${SERVER_URL}/add_credit2.php?a_usr=${getUsername}&credit=${lastDeposit}&b_name=${lastBankName}&s_time=${transactionDT}&ln=${line_display_name}&l_id=${line_id}&b_trans_id=${lastTranId}`;
    const res = axios.get(apiUrl);
    // console.log(res.data);
    this.getBankDepositWait();

    this.componentDidMount();
  };

  render() {
    const { value } = this.state;
    const { SearchBar } = Search;

    const columnsSmsOtp = [
      { dataField: 'id', text: 'id', hidden: true },
      { dataField: 'mobile_id', text: 'mobile_id', sort: true, hidden: true },
      {
        dataField: 'addr_body',
        text: 'ชื่อผู้ส่ง',
        sort: true,
        hidden: true,
      },
      { dataField: 'address', text: 'เบอร์ผู้ส่ง', sort: true },
      {
        dataField: 'body',
        text: 'เนื้อหา',
        sort: true,
      },
      {
        dataField: 'sms_date',
        text: 'เวลาที่รับ',
        sort: true,
        hidden: true,
      },
      {
        dataField: 'sms_date_sent',
        text: 'เวลาที่ส่ง',
        sort: true,
        hidden: true,
      },
      {
        dataField: 'service_center',
        text: 'เบอร์ที่ใช้ส่ง',
        sort: true,
        hidden: true,
      },
      {
        dataField: 'date_added',
        text: 'เวลาที่เพิ่ม',
        sort: true,
        hidden: true,
      },
      {
        dataField: 'date_updated',
        text: 'เวลาที่อัพเดต',
        sort: true,
        hidden: true,
      },
    ];

    return (
      <>
        {/* Content Header (Page header) */}
        <div className='content-header'>
          <div className='container-fluid'>
            <div className='row mb-2'>
              <div className='col-sm-6'>
                <h1 className='m-0 text-dark'>ยอดฝาก</h1>
              </div>
              {/* /.col */}
              <div className='col-sm-6'>
                <ol className='breadcrumb float-sm-right'>
                  <li className='breadcrumb-item'>
                    <Link
                      to={{
                        pathname: '/Landing',
                      }}
                    >
                      Home
                    </Link>
                  </li>
                  <li className='breadcrumb-item active'>ยอดฝาก</li>
                </ol>
              </div>
              {/* /.col */}
            </div>
            {/* /.row */}
          </div>
          {/* /.container-fluid */}
        </div>
        {/* /.content-header */}
        <section className='content'>
          <div className='content_main'>
            <div className='container-fluid'>
              <div className='row'>
                <div className='col-12'>
                  <div className='card card-content'>
                    <div className='card-header bg-10'>
                      <div className='d-flex justify-content-between align-items-center'>
                        <p className='card-text'>ยอดฝากเงิน</p>
                        <p
                          className='card-text'
                          style={{ color: 'red', fontSize: '16px' }}
                        >
                          Bank Update: {this.state.data_bank_kbank_upd}
                        </p>
                      </div>
                    </div>
                    <div className='card-body'>
                      <div className='row mb-4'>
                        <div className='col-12 col-lg-9 col-md-7 mb-4 mb-md-0'>
                          {/* <div className='dataTables_length'>
                        <label>Records per page:</label>
                        <select className='form-control custom-select'>
                          <option value={10}>10</option>
                          <option value={25}>25</option>
                          <option value={50}>50</option>
                          <option value={100}>100</option>
                        </select>
                      </div> */}
                        </div>
                        <div className='col-12 col-lg-3 col-md-5'>
                          {/* <form className='search-date d-flex align-items-center justify-content-end'>
                        <input
                          type='search'
                          className='form-control'
                          placeholder='Search...'
                        />
                        <button type='submit' className='btn btn-calendar bg-4'>
                          <i className='fas fa-search' />
                        </button>
                      </form> */}
                        </div>
                      </div>

                      <div className='table-responsive'>
                        <Tabs>
                          <TabList className='position-relative'>
                            <Tab>ยังไม่ได้ดำเนินการ</Tab>
                            <Tab>ดำเนินการแล้ว</Tab>
                            <Tab>
                              รายการไม่สำเร็จ{' '}
                              <span
                                class='text-span'
                                style={{
                                  fontSize: '20px',
                                  fontWeight: 'bolder',
                                }}
                              >
                                {this.state.dataCountFailed}
                              </span>
                            </Tab>
                            <Tab>รายการ SMS</Tab>
                            <Tab>รายการ SMS (2)</Tab>
                            <Tab>รายการ SMS (3)</Tab>
                            <Tab>บอท Auto</Tab>

                            <div className='box-shadow'>
                              <span>Refresh : </span>
                              <span className='txt'> {this.state.count}</span>
                            </div>
                          </TabList>

                          <TabPanel>
                            {/* <h2>Any content 1</h2> */}
                            <table id='sortby' className='table table-bordered'>
                              <thead className='thead-dark text-center'>
                                <tr>
                                  <th scope='col' className='sortable asc'>
                                    #
                                  </th>
                                  <th scope='col' className='sortable'>
                                    ธนาคาร(เวป)
                                  </th>
                                  <th scope='col' className='sortable'>
                                    สัญลักษณ์
                                  </th>
                                  <th scope='col' className='sortable'>
                                    เวลา
                                  </th>
                                  <th scope='col' className='sortable'>
                                    ช่องทาง(โอน)
                                  </th>
                                  {/* <th scope='col' className='sortable'>
                                รายละเอียด
                              </th> */}
                                  {/* <th scope='col' className='sortable'>
                                    รายการถอน
                                  </th> */}
                                  <th scope='col' className='sortable'>
                                    รายการฝาก
                                  </th>
                                  <th scope='col' className='sortable'>
                                    บัญชีผู้ฝาก
                                  </th>
                                  <th scope='col' className='sortable'>
                                    ช่องทาง
                                  </th>
                                  <th scope='col' className='sortable'>
                                    สถานะ
                                  </th>
                                  <th scope='col' className='sortable'>
                                    รหัสผู้เล่น
                                  </th>
                                  <th scope='col' className='sortable'>
                                    กรอกรหัสผู้ใช้งาน
                                  </th>
                                  <th scope='col' className='sortable'>
                                    kill ค้าง
                                  </th>
                                  <th scope='col' className='sortable'>
                                    error
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                {this.state.data.map((dep, idx) => (
                                  <tr key={idx}>
                                    <td className='text-center'>{idx + 1}</td>
                                    <td>{dep.NameOfBank}</td>
                                    <td>
                                      <div className='img_b'>
                                        <img src={dep.image} alt={dep.image} />
                                      </div>
                                    </td>
                                    <td className='text-center'>
                                      {dep.transactionDT}
                                    </td>
                                    <td>{dep.channel}</td>
                                    {/* <td>{dep.categoryList}</td> */}
                                    {/* <td>{dep.withdraw}</td> */}
                                    <td>{dep.deposit}</td>
                                    <td>{dep.account_no}</td>
                                    <td
                                      style={{
                                        width: '400px',
                                        whiteSpace: 'normal',
                                      }}
                                    >
                                      {dep.other_detail}
                                    </td>
                                    <td className='text-center'>
                                      {dep.status_process ===
                                        'wait process' && (
                                        <div className='txt-status-1 exclamationcircle'>
                                          อยู่ระหว่างรอ
                                        </div>
                                      )}
                                      {dep.status_process === 'processing' && (
                                        <div className='txt-status-3 executecircle'>
                                          กำลังดำเนินการ
                                        </div>
                                      )}
                                      {dep.status_process === 'success' && (
                                        <div className='txt-status checkcircle'>
                                          สำเร็จ
                                        </div>
                                      )}
                                      {dep.status_process === 'fail' && (
                                        <div className='txt-status-2 timescircle'>
                                          ไม่สำเร็จ
                                        </div>
                                      )}
                                    </td>
                                    <td>{dep.member_user}</td>
                                    <td className='text-center'>
                                      {dep.status_process === 'processing' && (
                                        <input
                                          type='text'
                                          className='form-control'
                                          id={`inputUsername_wait_${dep.bank_transaction_id}`}
                                          placeholder='รหัสผู้เล่น'
                                          required
                                          disabled
                                          value={dep.member_user}
                                          onKeyDown={(e) => {
                                            this._handleKeyDown(
                                              e,
                                              dep.bank_transaction_id,
                                              dep.transactionDT,
                                              dep.deposit,
                                              dep.ShortNameEn
                                            );
                                          }}
                                        />
                                      )}
                                      {dep.status_process !== 'processing' && (
                                        <input
                                          type='text'
                                          className='form-control'
                                          id={`inputUsername_wait_${dep.bank_transaction_id}`}
                                          placeholder='รหัสผู้เล่น'
                                          required
                                          value={dep.member_user}
                                          onKeyDown={(e) => {
                                            this._handleKeyDown(
                                              e,
                                              dep.bank_transaction_id,
                                              dep.transactionDT,
                                              dep.deposit,
                                              dep.ShortNameEn
                                            );
                                          }}
                                        />
                                      )}
                                    </td>
                                    <td>
                                      {dep.flag !== '0' && (
                                        <>
                                          <button
                                            className='button status bg-2'
                                            onClick={() =>
                                              this.kill_RerunProcess(
                                                parseInt(
                                                  dep.bank_transaction_id
                                                )
                                              )
                                            }
                                          >
                                            ทำใหม่
                                          </button>
                                          <button
                                            className='button status bg-1'
                                            onClick={() =>
                                              this.killProcess(
                                                parseInt(
                                                  dep.bank_transaction_id
                                                )
                                              )
                                            }
                                          >
                                            Kill
                                          </button>
                                        </>
                                      )}
                                      {dep.flag === '0' && (
                                        <>
                                          <button
                                            className='button status bg-2'
                                            onClick={() =>
                                              this.onApproveClick(
                                                parseInt(
                                                  dep.bank_transaction_id
                                                ),
                                                dep.transactionDT,
                                                dep.deposit,
                                                dep.ShortNameEn
                                              )
                                            }
                                          >
                                            เติมเครดิต
                                          </button>
                                          <button
                                            className='button status bg-1'
                                            onClick={() =>
                                              this.onCancelClick(
                                                parseInt(
                                                  dep.bank_transaction_id
                                                ),
                                                dep.transactionDT,
                                                dep.deposit,
                                                dep.ShortNameEn
                                              )
                                            }
                                          >
                                            ยกเลิก
                                          </button>
                                        </>
                                      )}
                                    </td>
                                    <td>{dep.error_msg}</td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          </TabPanel>
                          <TabPanel>
                            {/* <h2>ดำเนินการแล้ว</h2> */}
                            <div className='col-12 col-lg-9 col-md-7 mb-4 mb-md-0'>
                              <div className='dataTables_length'>
                                <label>กรุณาเลือกวันที่ :</label>
                                <DateTimeRangePicker
                                  onChange={this.onChange}
                                  value={value}
                                />
                                <button
                                  class='button status bg-2'
                                  data-toggle='modal'
                                  data-target='#AddModal'
                                  onClick={() => this.searchData()}
                                >
                                  ค้นหา
                                </button>
                              </div>
                            </div>
                            <hr />
                            <table id='sortby' className='table table-bordered'>
                              <thead className='thead-dark text-center'>
                                <tr>
                                  <th scope='col' className='sortable asc'>
                                    #
                                  </th>
                                  <th scope='col' className='sortable'>
                                    ธนาคาร(เวป)
                                  </th>
                                  <th scope='col' className='sortable'>
                                    สัญลักษณ์
                                  </th>
                                  <th scope='col' className='sortable'>
                                    เวลา
                                  </th>
                                  <th scope='col' className='sortable'>
                                    ช่องทาง(โอน)
                                  </th>
                                  {/* <th scope='col' className='sortable'>
                                รายละเอียด
                              </th> */}
                                  <th scope='col' className='sortable'>
                                    รายการถอน
                                  </th>
                                  <th scope='col' className='sortable'>
                                    รายการฝาก
                                  </th>
                                  <th scope='col' className='sortable'>
                                    บัญชีผู้ฝาก
                                  </th>
                                  <th scope='col' className='sortable'>
                                    ช่องทาง
                                  </th>
                                  <th scope='col' className='sortable'>
                                    สถานะ
                                  </th>
                                  <th scope='col' className='sortable'>
                                    กรอกรหัสผู้ใช้งาน
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                {this.state.dataSuccess.map((dep, idx) => (
                                  <tr key={idx}>
                                    <td className='text-center'>{idx + 1}</td>
                                    <td>{dep.NameOfBank}</td>
                                    <td>
                                      <div className='img_b'>
                                        <img src={dep.image} alt={dep.image} />
                                      </div>
                                    </td>
                                    <td className='text-center'>
                                      {dep.transactionDT}
                                    </td>
                                    <td>{dep.channel}</td>
                                    {/* <td>{dep.categoryList}</td> */}
                                    <td>{dep.withdraw}</td>
                                    <td>{dep.deposit}</td>
                                    <td>{dep.account_no}</td>
                                    <td
                                      style={{
                                        width: '400px',
                                        whiteSpace: 'normal',
                                      }}
                                    >
                                      {dep.other_detail}
                                    </td>
                                    <td className='text-center'>
                                      {dep.status_process ===
                                        'wait process' && (
                                        <div className='txt-status-1 exclamationcircle'>
                                          อยู่ระหว่างรอ
                                        </div>
                                      )}
                                      {dep.status_process === 'success' && (
                                        <div className='txt-status checkcircle'>
                                          สำเร็จ
                                        </div>
                                      )}
                                      {dep.status_process === 'fail' && (
                                        <div className='txt-status-2 timescircle'>
                                          ไม่สำเร็จ
                                        </div>
                                      )}
                                    </td>
                                    {dep.flag === '99999' && (
                                      <td
                                        className='text-center'
                                        style={{ backgroundColor: '#d8b121' }}
                                      >
                                        <input
                                          type='text'
                                          className='form-control'
                                          id={`inputUsername_success_${dep.bank_transaction_id}`}
                                          value={dep.member_user}
                                          disabled
                                        />
                                      </td>
                                    )}
                                    {dep.flag !== '99999' && (
                                      <td className='text-center'>
                                        <input
                                          type='text'
                                          className='form-control'
                                          id={`inputUsername_success_${dep.bank_transaction_id}`}
                                          value={dep.member_user}
                                          disabled
                                        />
                                      </td>
                                    )}
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          </TabPanel>
                          <TabPanel>
                            {/* <h2>ไม่สำเร็จ</h2> */}
                            <table id='sortby' className='table table-bordered'>
                              <thead className='thead-dark text-center'>
                                <tr>
                                  <th scope='col' className='sortable asc'>
                                    #
                                  </th>
                                  <th scope='col' className='sortable'>
                                    ธนาคาร(เวป)
                                  </th>
                                  <th scope='col' className='sortable'>
                                    สัญลักษณ์
                                  </th>
                                  <th scope='col' className='sortable'>
                                    เวลา
                                  </th>
                                  <th scope='col' className='sortable'>
                                    ช่องทาง(โอน)
                                  </th>
                                  {/* <th scope='col' className='sortable'>
                                รายละเอียด
                              </th> */}
                                  {/* <th scope='col' className='sortable'>
                                รายการถอน
                              </th> */}
                                  <th scope='col' className='sortable'>
                                    รายการฝาก
                                  </th>
                                  <th scope='col' className='sortable'>
                                    บัญชีผู้ฝาก
                                  </th>
                                  <th scope='col' className='sortable'>
                                    ช่องทาง
                                  </th>
                                  <th scope='col' className='sortable'>
                                    สถานะ
                                  </th>
                                  <th scope='col' className='sortable'>
                                    รายละเอียด
                                  </th>
                                  <th scope='col' className='sortable'>
                                    รหัสผู้เล่น(ผิดพลาด)
                                  </th>
                                  <th scope='col' className='sortable'>
                                    กรอกรหัสผู้ใช้งาน
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                {this.state.dataFailed.map((dep, idx) => (
                                  <tr key={idx}>
                                    <td className='text-center'>{idx + 1}</td>
                                    <td>{dep.NameOfBank}</td>
                                    <td>
                                      <div className='img_b'>
                                        <img src={dep.image} alt={dep.image} />
                                      </div>
                                    </td>
                                    <td className='text-center'>
                                      {dep.transactionDT}
                                    </td>
                                    <td>{dep.channel}</td>
                                    {/* <td>{dep.categoryList}</td> */}
                                    {/* <td>{dep.withdraw}</td> */}
                                    <td>{dep.deposit}</td>
                                    <td>{dep.account_no}</td>
                                    <td
                                      style={{
                                        width: '400px',
                                        whiteSpace: 'normal',
                                      }}
                                    >
                                      {dep.other_detail}
                                    </td>
                                    <td className='text-center'>
                                      {dep.status_process ===
                                        'wait process' && (
                                        <div className='txt-status-1 exclamationcircle'>
                                          อยู่ระหว่างรอ
                                        </div>
                                      )}
                                      {dep.status_process === 'success' && (
                                        <div className='txt-status checkcircle'>
                                          สำเร็จ
                                        </div>
                                      )}
                                      {dep.status_process === 'failed' && (
                                        <div className='txt-status-2 timescircle'>
                                          ไม่สำเร็จ
                                        </div>
                                      )}
                                    </td>
                                    <td>{dep.error_msg}</td>
                                    <td>{dep.member_user}</td>
                                    <td className='text-center'>
                                      {dep.status_process === 'processing' && (
                                        <input
                                          type='text'
                                          className='form-control'
                                          id={`inputUsername_wait_${dep.bank_transaction_id}`}
                                          placeholder='รหัสผู้เล่น'
                                          required
                                          disabled
                                          value={dep.member_user}
                                          onKeyDown={(e) => {
                                            this._handleKeyDown_failed(
                                              e,
                                              dep.bank_transaction_id,
                                              dep.transactionDT,
                                              dep.deposit,
                                              dep.ShortNameEn
                                            );
                                          }}
                                        />
                                      )}
                                      {dep.status_process !== 'processing' && (
                                        <input
                                          type='text'
                                          className='form-control'
                                          id={`inputUsername_wait_${dep.bank_transaction_id}`}
                                          placeholder='รหัสผู้เล่น'
                                          required
                                          // value={dep.member_user}
                                          onKeyDown={(e) => {
                                            this._handleKeyDown_failed(
                                              e,
                                              dep.bank_transaction_id,
                                              dep.transactionDT,
                                              dep.deposit,
                                              dep.ShortNameEn
                                            );
                                          }}
                                        />
                                      )}
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          </TabPanel>
                          <TabPanel>
                            <table id='sortby' className='table table-bordered'>
                              <thead className='thead-dark text-center'>
                                <tr>
                                  <th scope='col' className='sortable asc'>
                                    #
                                  </th>
                                  {/* <th scope='col' className='sortable'>
                                ธนาคาร(เวป)
                              </th> */}
                                  {/* <th scope='col' className='sortable'>
                                    ชื่อผู้ส่ง
                                  </th> */}
                                  <th scope='col' className='sortable'>
                                    เบอร์ผู้ส่ง
                                  </th>
                                  <th scope='col' className='sortable'>
                                    เนื้อหา
                                  </th>
                                  <th scope='col' className='sortable'>
                                    เวลาที่ได้รับ
                                  </th>
                                  {/* <th scope='col' className='sortable'>
                                    เวลาที่ส่ง
                                  </th> */}
                                  <th scope='col' className='sortable'>
                                    เบอร์ที่ใช้ส่ง
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                {this.state.data_rpt.map((dep, idx) => (
                                  <tr key={idx}>
                                    <td className='text-center'>{idx + 1}</td>
                                    {/* <td>{dep.addr_body}</td> */}
                                    <td className='text-center'>
                                      {dep.address}
                                    </td>
                                    {/* <td style={{width: "400px", whiteSpace: "normal"}}>{dep.body}</td> */}
                                    <td>{dep.body}</td>
                                    {/* <td>{dep.categoryList}</td> */}
                                    {/* <td>{dep.withdraw}</td> */}
                                    <td className='text-center'>
                                      {dep.sms_date}
                                    </td>
                                    {/* <td>{dep.sms_date_sent}</td> */}
                                    <td className='text-center'>
                                      {dep.service_center}
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          </TabPanel>
                          <TabPanel>
                            <table id='sortby' className='table table-bordered'>
                              <thead className='thead-dark text-center'>
                                <tr>
                                  <th scope='col' className='sortable asc'>
                                    #
                                  </th>
                                  {/* <th scope='col' className='sortable'>
                                ธนาคาร(เวป)
                              </th> */}
                                  {/* <th scope='col' className='sortable'>
                                    ชื่อผู้ส่ง
                                  </th> */}
                                  <th scope='col' className='sortable'>
                                    เบอร์ผู้ส่ง
                                  </th>
                                  <th scope='col' className='sortable'>
                                    เนื้อหา
                                  </th>
                                  <th scope='col' className='sortable'>
                                    เวลาที่ได้รับ
                                  </th>
                                  {/* <th scope='col' className='sortable'>
                                    เวลาที่ส่ง
                                  </th> */}
                                  <th scope='col' className='sortable'>
                                    เบอร์ที่ใช้ส่ง
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                {this.state.data_rpt_sms_mobile_2.map(
                                  (dep, idx) => (
                                    <tr key={idx}>
                                      <td className='text-center'>{idx + 1}</td>
                                      {/* <td>{dep.addr_body}</td> */}
                                      <td className='text-center'>
                                        {dep.address}
                                      </td>
                                      {/* <td style={{width: "400px", whiteSpace: "normal"}}>{dep.body}</td> */}
                                      <td>{dep.body}</td>
                                      {/* <td>{dep.categoryList}</td> */}
                                      {/* <td>{dep.withdraw}</td> */}
                                      <td className='text-center'>
                                        {dep.sms_date}
                                      </td>
                                      {/* <td>{dep.sms_date_sent}</td> */}
                                      <td className='text-center'>
                                        {dep.service_center}
                                      </td>
                                    </tr>
                                  )
                                )}
                              </tbody>
                            </table>
                          </TabPanel>
                          <TabPanel>
                            <table id='sortby' className='table table-bordered'>
                              <thead className='thead-dark text-center'>
                                <tr>
                                  <th scope='col' className='sortable asc'>
                                    #
                                  </th>
                                  {/* <th scope='col' className='sortable'>
                                ธนาคาร(เวป)
                              </th> */}
                                  {/* <th scope='col' className='sortable'>
                                    ชื่อผู้ส่ง
                                  </th> */}
                                  <th scope='col' className='sortable'>
                                    เบอร์ผู้ส่ง
                                  </th>
                                  <th scope='col' className='sortable'>
                                    เนื้อหา
                                  </th>
                                  <th scope='col' className='sortable'>
                                    เวลาที่ได้รับ
                                  </th>
                                  {/* <th scope='col' className='sortable'>
                                    เวลาที่ส่ง
                                  </th> */}
                                  <th scope='col' className='sortable'>
                                    เบอร์ที่ใช้ส่ง
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                {this.state.data_rpt_sms_mobile_3.map(
                                  (dep, idx) => (
                                    <tr key={idx}>
                                      <td className='text-center'>{idx + 1}</td>
                                      {/* <td>{dep.addr_body}</td> */}
                                      <td className='text-center'>
                                        {dep.address}
                                      </td>
                                      {/* <td style={{width: "400px", whiteSpace: "normal"}}>{dep.body}</td> */}
                                      <td>{dep.body}</td>
                                      {/* <td>{dep.categoryList}</td> */}
                                      {/* <td>{dep.withdraw}</td> */}
                                      <td className='text-center'>
                                        {dep.sms_date}
                                      </td>
                                      {/* <td>{dep.sms_date_sent}</td> */}
                                      <td className='text-center'>
                                        {dep.service_center}
                                      </td>
                                    </tr>
                                  )
                                )}
                              </tbody>
                            </table>
                          </TabPanel>

                          <TabPanel>
                            <div className='table-responsive'>
                              <table
                                id='sortby'
                                className='table table-bordered'
                              >
                                <thead className='thead-dark text-center'>
                                  <tr>
                                    <th scope='col'>#</th>
                                    <th scope='col'>ชื่อธนาคาร</th>
                                    <th scope='col'>สัญลักษณ์</th>
                                    <th scope='col'>ปุ่มเปิด/ปิด</th>
                                    <th scope='col'>สถานะ</th>
                                    {/* <th scope='col'>การจัดการ</th> */}
                                  </tr>
                                </thead>
                                <tbody>
                                  {this.state.data_auto.map((item, index) => (
                                    <>
                                      <tr key={index}>
                                        <td className='text-center'>
                                          {index + 1}
                                        </td>
                                        <td>{item.NameOfBank}</td>
                                        <td>
                                          <div className='img_b'>
                                            <img
                                              src={item.image}
                                              alt={item.BankName}
                                            />
                                          </div>
                                        </td>

                                        <td className='text-center'>
                                          {parseInt(item.flag) !== 0 && (
                                            <button
                                              className='button status bg-1'
                                              // data-toggle='modal'
                                              // data-target='#DeleteModal'
                                              onClick={() =>
                                                this.handleChange(
                                                  parseInt(item.ca_id),
                                                  'stop'
                                                )
                                              }
                                            >
                                              Stop
                                            </button>
                                          )}
                                          {parseInt(item.flag) === 0 && (
                                            <button
                                              className='button status bg-3'
                                              // data-toggle='modal'
                                              // data-target='#DeleteModal'
                                              onClick={() =>
                                                this.handleChange(
                                                  parseInt(item.ca_id),
                                                  'start'
                                                )
                                              }
                                            >
                                              Start
                                            </button>
                                          )}
                                        </td>
                                        <td className='text-center'>
                                          {parseInt(item.flag) === 0 && (
                                            <div class='txt-status-2 timescircle'>
                                              Stop
                                            </div>
                                          )}
                                          {parseInt(item.flag) === 1 && (
                                            <div className='txt-status checkcircle'>
                                              Running
                                            </div>
                                          )}
                                        </td>
                                        {/* <td className='text-center'>
                                          <button
                                            className='button status bg-3'
                                            data-toggle='modal'
                                            data-target='#EditModal'
                                          >
                                            แก้ไข
                                          </button>
                                          <button
                                            className='button status bg-1'
                                            data-toggle='modal'
                                            data-target='#DeleteModal'
                                          >
                                            ลบ
                                          </button>
                                        </td> */}
                                      </tr>
                                    </>
                                  ))}
                                </tbody>
                              </table>
                            </div>
                          </TabPanel>
                        </Tabs>
                      </div>
                      <div className='row mt-4'></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
    );
  }
}

export default BankDeposit;
