import React, { Component } from 'react';
import axios from 'axios';
import { HashRouter as Router, Switch, Route, Redirect, withRouter } from 'react-router-dom';
import './App.css';

import AppRoute from './AppRoute';

import Auxility from './hoc/Auxility/Auxility';
import AuthenLayout from './hoc/Layout/AuthenLayout';
import DashboardLayout from './hoc/Layout/DashboardLayout';
import Landing from './pages/Landing/Landing';
import Login from './pages/Login/Login';
import Dashboard from './pages/Dashboard/Dashboard';
import Bank from './pages/Bank/Bank';
import BankKtb from './pages/Bank/BankKtb';
import BankDeposit from './pages/Bank/BankDeposit';
import ReportCredit from './pages/Report/ReportCredit';
import Member from './pages/Member/Member';
import Setting from './pages/Setting/Setting';
import Auto from './pages/Auto/Auto';
import BankWithdraw from './pages/Bank/BankWithdraw';
import Credit from './pages/Credit/Credit';
import ReCredit from './pages/Transfer/ReCredit';
import ReTransfer from './pages/Transfer/ReTransfer';
import Transfer from './pages/Transfer/Transfer';
import ExcelDL from './pages/Transfer/ExcelDL';
import Cashout from './pages/Cashout/Cashout';

export class App extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data: [],
    };
  }

  async componentDidMount() {
    // console.log('APP didmount ');
    let getUid;
    if (localStorage.getItem('uid')){
      getUid = localStorage.getItem('uid');

      let url = `https://ufa9999.apirpt.com/s186aat/j888/get_group_user_per.php?u_id=${getUid}`;
      // console.log(url);

      const res = await axios.get(url);
      // console.log('res.data : ', res.data);
      if (res.data.length > 0) {
        this.setState({
          data: res.data,
        });
      }
    }
  }

  changeData = p_data =>{
    this.setState({
      data : p_data
    }, () => {
      console.log("app changedata : ", this.state.data)
    });
  }

    render() {
      return (
        <Auxility>
          {/* <div id='app'> */}
            <Router>
              <Switch>
                <Route exact path="/">
                    <Redirect to="/login" />
                </Route>
                <AppRoute path='/login' component={Login} layout={AuthenLayout} group={this.state.data} tags="0" changeData={this.changeData} />
                <AppRoute path='/landing' component={Landing} layout={DashboardLayout} group={this.state.data} tags="0"/>
                <AppRoute path='/dashboard' component={Dashboard} layout={DashboardLayout} group={this.state.data} tags="1"/>
                <AppRoute path='/bank' component={Bank} layout={DashboardLayout} group={this.state.data} tags="2"/>
                <AppRoute path='/deposit' component={BankDeposit} layout={DashboardLayout} group={this.state.data} tags="3"/>
                <AppRoute path='/withdraw' component={BankWithdraw} layout={DashboardLayout} group={this.state.data} tags="4"/>
                <AppRoute path='/credit' component={Credit} layout={DashboardLayout} group={this.state.data} tags="5"/>
                <AppRoute path='/reportcredit' component={ReportCredit} layout={DashboardLayout} group={this.state.data} tags="6"/>
                <AppRoute path='/auto' component={Auto} layout={DashboardLayout} group={this.state.data} tags="7"/>
                <AppRoute path='/member' component={Member} layout={DashboardLayout} group={this.state.data} tags="8"/>
                <AppRoute path='/setting' component={Setting} layout={DashboardLayout} group={this.state.data} tags="9"/>
                <AppRoute path='/re_credit' component={ReCredit} layout={DashboardLayout} group={this.state.data} tags="10"/>
                <AppRoute path='/re_transfer' component={ReTransfer} layout={DashboardLayout} group={this.state.data} tags="10"/>
                <AppRoute path='/transfer' component={Transfer} layout={DashboardLayout} group={this.state.data} tags="10"/>
                <AppRoute path='/cashout' component={Cashout} layout={DashboardLayout} group={this.state.data} tags="10"/>
                <AppRoute path='/excel' component={ExcelDL} layout={DashboardLayout} group={this.state.data} tags="10"/>
                <Route path="*" component={() => "404 NOT FOUND"} />
              </Switch>
            </Router>
          {/* </div> */}
        </Auxility>
      );
    }

}

export default withRouter(App);
