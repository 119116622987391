import React, { Component } from 'react'

export class Landing extends Component {
    render() {
        return (
            <div className='content_main d-flex justify-content-center align-items-center' style={{backgroundColor: "#1d1d1c"}}>
            <div style={{width: "30%", textAlign: "center"}}>
                  <img class='mx-auto d-block mb-3' src='assets/img/logo.png' alt="logo" />
    
                  <h1 style={{fontSize: "3rem"}}>Cyber Auto Bank - V.1</h1>
            </div>
          </div>
        )
    }
}

export default Landing
