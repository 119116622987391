import React, { Component } from 'react';
import axios from 'axios';
import { Link, withRouter, NavLink } from 'react-router-dom';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { myConfig } from '../../constants/Constants';

const SERVER_URL = myConfig.apiUrl;

toast.configure();
export class Sidebar extends Component {
  constructor(props) {
    super(props);

    this.state = {
      getName: '',
      dataGroupUsers: [],
    };
  }

  componentDidMount() {
    let showName = localStorage.getItem('name');

    this.setState({
      getName: showName,
    });

    this.getUserPermission();
  }

  logout() {
    localStorage.clear();
    sessionStorage.clear();

    toast.error('ออกจากระบบเรียบร้อย', {
      position: toast.POSITION.BOTTOM_RIGHT,
      autoClose: 5000,
    });

    this.props.history.push('/');
  }

  getUserPermission = async () => {
    let getUid = localStorage.getItem('uid');

    // let url = `https://www.apirpt.com/s186aat/j888/get_group_user_per.php?u_id=${getUid}`;
    let url = `${SERVER_URL}/get_group_user_per.php?u_id=${getUid}`;
    console.log(url);

    const res = await axios.get(url);
    // console.log('res.data : ', res.data);
    if (res.data.length > 0) {
      this.setState({
        dataGroupUsers: res.data,
      });
    }
  };

  render() {
    return (
      <aside className='main-sidebar elevation-4 bg-dark-pri'>
        {/* Brand Logo */}
        <Link
          className='brand-link'
          to={{
            pathname: '/landing',
          }}
        >
          <img
            // src='dist/img/AdminLTELogo.png'
            src='assets/img/logo.png'
            alt='AdminLTE Logo'
            // className='brand-image img-circle elevation-3'
            className=''
            // style={{width: "70%", margin: "0 auto"}}
            style={{ opacity: '.8', width: '70%', margin: '0 auto' }}
          />
          {/* <span className='brand-text font-weight-light'>Cyber Auto Bank</span> */}
        </Link>
        {/* Sidebar */}
        <div className='sidebar'>
          {/* Sidebar user panel (optional) */}
          <div className='user-panel mt-3 pb-3 mb-3 d-flex'>
            <div className='image'>
              <img
                src='dist/img/user2-160x160.jpg'
                className='img-circle elevation-2'
                alt='UserPhoto'
              />
            </div>
            <div className='info'>
              <a href='#' className='d-block'>
                <Link
                  className='d-block'
                  style={{ color: '#e8c01d' }}
                  to={{ pathname: '/landing' }}
                >
                  {localStorage.getItem('name')}
                </Link>
              </a>
            </div>
          </div>
          {/* Sidebar Menu */}
          <nav className='mt-2'>
            <ul
              className='nav nav-pills nav-sidebar flex-column'
              data-widget='treeview'
              role='menu'
              data-accordion='false'
            >
              {/* Add icons to the links using the .nav-icon class
         with font-awesome or any other icon font library */}

              {this.state.dataGroupUsers.map((item, idx) => {
                switch (parseInt(item.CodeID)) {
                  case 1:
                    return (
                      <li className='nav-item' key={idx}>
                        <NavLink
                          className='nav-link'
                          to={{
                            pathname: '/dashboard',
                          }}
                        >
                          <i className='nav-icon fas fa-tachometer-alt' />
                          <p>สรุปภาพรวม</p>
                        </NavLink>
                      </li>
                    );
                  case 2:
                    return (
                      <li className='nav-item' key={idx}>
                        <NavLink
                          className='nav-link'
                          to={{
                            pathname: '/bank',
                          }}
                        >
                          <i className='nav-icon fas fa-university' />
                          <p>ธนาคาร</p>
                        </NavLink>
                      </li>
                    );
                  case 3:
                    return (
                      <li className='nav-item' key={idx}>
                        <NavLink
                          className='nav-link'
                          to={{
                            pathname: '/deposit',
                          }}
                        >
                          <i className='nav-icon fas fa-plus' />
                          <p>ยอดฝาก</p>
                        </NavLink>
                      </li>
                    );
                  case 4:
                    return (
                      <li className='nav-item' key={idx}>
                        <NavLink
                          className='nav-link'
                          to={{
                            pathname: '/withdraw',
                          }}
                        >
                          <i className='nav-icon fas fa-hand-holding-usd' />
                          <p>ยอดถอน</p>
                        </NavLink>
                      </li>
                    );
                  case 5:
                    return (
                      <li className='nav-item' key={idx}>
                        <NavLink
                          className='nav-link'
                          to={{
                            pathname: '/credit',
                          }}
                        >
                          <i className='nav-icon fas fa-dollar-sign' />
                          <p>ฝาก/ถอน เครดิต</p>
                        </NavLink>
                      </li>
                    );
                  case 6:
                    return (
                      <li className='nav-item' key={idx}>
                        <NavLink
                          className='nav-link'
                          to={{
                            pathname: '/reportcredit',
                          }}
                        >
                          <i className='nav-icon fas fa-table' />
                          <p>รายงานเครดิต</p>
                        </NavLink>
                      </li>
                    );
                  case 7:
                    return (
                      <li className='nav-item' key={idx}>
                        <NavLink
                          className='nav-link'
                          to={{
                            pathname: '/auto',
                          }}
                        >
                          <i className='nav-icon fas fa-robot' />
                          <p>จัดการระบบ Auto</p>
                        </NavLink>
                      </li>
                    );
                  case 8:
                    return (
                      <li className='nav-item' key={idx}>
                        <NavLink
                          className='nav-link'
                          to={{
                            pathname: '/member',
                          }}
                        >
                          <i className='nav-icon fas fa-user' />
                          <p>จัดการสมาชิก</p>
                        </NavLink>
                      </li>
                    );
                  case 9:
                    return (
                      <li className='nav-item' key={idx}>
                        <NavLink
                          className='nav-link'
                          to={{
                            pathname: '/setting',
                          }}
                        >
                          <i className='nav-icon fas fa-tools' />
                          <p>จัดการระบบ</p>
                        </NavLink>
                      </li>
                    );
                  case 10:
                    return (
                      <li className='nav-item' key={idx}>
                        <NavLink
                          className='nav-link'
                          to={{
                            pathname: '/cashout',
                          }}
                        >
                          <i className='nav-icon fas fa-retweet' />
                          <p>Cashout</p>
                        </NavLink>
                      </li>
                    );
                  // case 10:
                  //   return (
                  //     <li className='nav-item has-treeview' key={idx}>
                  //       <a href className='nav-link'>
                  //         <i className='nav-icon fas fa-retweet' />
                  //         <p>
                  //           Transfer
                  //           <i className='right fas fa-angle-left' />
                  //         </p>
                  //       </a>
                  //       <ul className='nav nav-treeview'>
                  //         <li className='nav-item'>
                  //           <NavLink
                  //             className='nav-link'
                  //             to={{
                  //               pathname: '/re_credit',
                  //             }}
                  //           >
                  //             <i className='far fa-files nav-icon' />
                  //             <p>Re-Credit</p>
                  //           </NavLink>
                  //         </li>
                  //         <li className='nav-item'>
                  //           <NavLink
                  //             className='nav-link'
                  //             to={{
                  //               pathname: '/transfer',
                  //             }}
                  //           >
                  //             <i className='far fa-files nav-icon' />
                  //             <p>เวป-โอนยอด</p>
                  //           </NavLink>
                  //         </li>
                  //         <li className='nav-item'>
                  //           <NavLink
                  //             className='nav-link'
                  //             to={{
                  //               pathname: '/re_transfer',
                  //             }}
                  //           >
                  //             <i className='far fa-files nav-icon' />
                  //             <p>Re-Transfer</p>
                  //           </NavLink>
                  //         </li>
                  //         <li className='nav-item'>
                  //           <NavLink
                  //             className='nav-link'
                  //             to={{
                  //               pathname: '/excel',
                  //             }}
                  //           >
                  //             <i className='far fa-files nav-icon' />
                  //             <p>Excel</p>
                  //           </NavLink>
                  //         </li>
                  //       </ul>
                  //     </li>
                  //   );
                  default:
                    return null;
                }
              })}
              <li className='nav-header'></li>
              <li className='nav-item' onClick={() => this.logout()}>
                <Link className='nav-link'>
                  <i className='nav-icon fas fa-sign-out-alt' />
                  <p>ออกจากระบบ</p>
                </Link>
              </li>

              {/* <li className='nav-item'>
              <a href='pages/widgets.html' className='nav-link'>
                <i className='nav-icon fas fa-th' />
                <p>
                  Widgets
                  <span className='right badge badge-danger'>New</span>
                </p>
              </a>
            </li>
            <li className='nav-item has-treeview'>
              <a href='#' className='nav-link'>
                <i className='nav-icon fas fa-copy' />
                <p>
                  Layout Options
                  <i className='fas fa-angle-left right' />
                  <span className='badge badge-info right'>6</span>
                </p>
              </a>
              <ul className='nav nav-treeview'>
                <li className='nav-item'>
                  <a href='pages/layout/top-nav.html' className='nav-link'>
                    <i className='far fa-circle nav-icon' />
                    <p>Top Navigation</p>
                  </a>
                </li>
                <li className='nav-item'>
                  <a
                    href='pages/layout/top-nav-sidebar.html'
                    className='nav-link'
                  >
                    <i className='far fa-circle nav-icon' />
                    <p>Top Navigation + Sidebar</p>
                  </a>
                </li>
                <li className='nav-item'>
                  <a href='pages/layout/boxed.html' className='nav-link'>
                    <i className='far fa-circle nav-icon' />
                    <p>Boxed</p>
                  </a>
                </li>
                <li className='nav-item'>
                  <a
                    href='pages/layout/fixed-sidebar.html'
                    className='nav-link'
                  >
                    <i className='far fa-circle nav-icon' />
                    <p>Fixed Sidebar</p>
                  </a>
                </li>
                <li className='nav-item'>
                  <a href='pages/layout/fixed-topnav.html' className='nav-link'>
                    <i className='far fa-circle nav-icon' />
                    <p>Fixed Navbar</p>
                  </a>
                </li>
                <li className='nav-item'>
                  <a href='pages/layout/fixed-footer.html' className='nav-link'>
                    <i className='far fa-circle nav-icon' />
                    <p>Fixed Footer</p>
                  </a>
                </li>
                <li className='nav-item'>
                  <a
                    href='pages/layout/collapsed-sidebar.html'
                    className='nav-link'
                  >
                    <i className='far fa-circle nav-icon' />
                    <p>Collapsed Sidebar</p>
                  </a>
                </li>
              </ul>
            </li>
            <li className='nav-item has-treeview'>
              <a href='#' className='nav-link'>
                <i className='nav-icon fas fa-chart-pie' />
                <p>
                  Charts
                  <i className='right fas fa-angle-left' />
                </p>
              </a>
              <ul className='nav nav-treeview'>
                <li className='nav-item'>
                  <a href='pages/charts/chartjs.html' className='nav-link'>
                    <i className='far fa-circle nav-icon' />
                    <p>ChartJS</p>
                  </a>
                </li>
                <li className='nav-item'>
                  <a href='pages/charts/flot.html' className='nav-link'>
                    <i className='far fa-circle nav-icon' />
                    <p>Flot</p>
                  </a>
                </li>
                <li className='nav-item'>
                  <a href='pages/charts/inline.html' className='nav-link'>
                    <i className='far fa-circle nav-icon' />
                    <p>Inline</p>
                  </a>
                </li>
              </ul>
            </li>
            <li className='nav-item has-treeview'>
              <a href='#' className='nav-link'>
                <i className='nav-icon fas fa-tree' />
                <p>
                  UI Elements
                  <i className='fas fa-angle-left right' />
                </p>
              </a>
              <ul className='nav nav-treeview'>
                <li className='nav-item'>
                  <a href='pages/UI/general.html' className='nav-link'>
                    <i className='far fa-circle nav-icon' />
                    <p>General</p>
                  </a>
                </li>
                <li className='nav-item'>
                  <a href='pages/UI/icons.html' className='nav-link'>
                    <i className='far fa-circle nav-icon' />
                    <p>Icons</p>
                  </a>
                </li>
                <li className='nav-item'>
                  <a href='pages/UI/buttons.html' className='nav-link'>
                    <i className='far fa-circle nav-icon' />
                    <p>Buttons</p>
                  </a>
                </li>
                <li className='nav-item'>
                  <a href='pages/UI/sliders.html' className='nav-link'>
                    <i className='far fa-circle nav-icon' />
                    <p>Sliders</p>
                  </a>
                </li>
                <li className='nav-item'>
                  <a href='pages/UI/modals.html' className='nav-link'>
                    <i className='far fa-circle nav-icon' />
                    <p>Modals &amp; Alerts</p>
                  </a>
                </li>
                <li className='nav-item'>
                  <a href='pages/UI/navbar.html' className='nav-link'>
                    <i className='far fa-circle nav-icon' />
                    <p>Navbar &amp; Tabs</p>
                  </a>
                </li>
                <li className='nav-item'>
                  <a href='pages/UI/timeline.html' className='nav-link'>
                    <i className='far fa-circle nav-icon' />
                    <p>Timeline</p>
                  </a>
                </li>
                <li className='nav-item'>
                  <a href='pages/UI/ribbons.html' className='nav-link'>
                    <i className='far fa-circle nav-icon' />
                    <p>Ribbons</p>
                  </a>
                </li>
              </ul>
            </li>
            <li className='nav-item has-treeview'>
              <a href='#' className='nav-link'>
                <i className='nav-icon fas fa-edit' />
                <p>
                  Forms
                  <i className='fas fa-angle-left right' />
                </p>
              </a>
              <ul className='nav nav-treeview'>
                <li className='nav-item'>
                  <a href='pages/forms/general.html' className='nav-link'>
                    <i className='far fa-circle nav-icon' />
                    <p>General Elements</p>
                  </a>
                </li>
                <li className='nav-item'>
                  <a href='pages/forms/advanced.html' className='nav-link'>
                    <i className='far fa-circle nav-icon' />
                    <p>Advanced Elements</p>
                  </a>
                </li>
                <li className='nav-item'>
                  <a href='pages/forms/editors.html' className='nav-link'>
                    <i className='far fa-circle nav-icon' />
                    <p>Editors</p>
                  </a>
                </li>
                <li className='nav-item'>
                  <a href='pages/forms/validation.html' className='nav-link'>
                    <i className='far fa-circle nav-icon' />
                    <p>Validation</p>
                  </a>
                </li>
              </ul>
            </li>
            <li className='nav-item has-treeview'>
              <a href='#' className='nav-link'>
                <i className='nav-icon fas fa-table' />
                <p>
                  Tables
                  <i className='fas fa-angle-left right' />
                </p>
              </a>
              <ul className='nav nav-treeview'>
                <li className='nav-item'>
                  <a href='pages/tables/simple.html' className='nav-link'>
                    <i className='far fa-circle nav-icon' />
                    <p>Simple Tables</p>
                  </a>
                </li>
                <li className='nav-item'>
                  <a href='pages/tables/data.html' className='nav-link'>
                    <i className='far fa-circle nav-icon' />
                    <p>DataTables</p>
                  </a>
                </li>
                <li className='nav-item'>
                  <a href='pages/tables/jsgrid.html' className='nav-link'>
                    <i className='far fa-circle nav-icon' />
                    <p>jsGrid</p>
                  </a>
                </li>
              </ul>
            </li>
            <li className='nav-header'>EXAMPLES</li>
            <li className='nav-item'>
              <a href='pages/calendar.html' className='nav-link'>
                <i className='nav-icon far fa-calendar-alt' />
                <p>
                  Calendar
                  <span className='badge badge-info right'>2</span>
                </p>
              </a>
            </li>
            <li className='nav-item'>
              <a href='pages/gallery.html' className='nav-link'>
                <i className='nav-icon far fa-image' />
                <p>Gallery</p>
              </a>
            </li>
            <li className='nav-item has-treeview'>
              <a href='#' className='nav-link'>
                <i className='nav-icon far fa-envelope' />
                <p>
                  Mailbox
                  <i className='fas fa-angle-left right' />
                </p>
              </a>
              <ul className='nav nav-treeview'>
                <li className='nav-item'>
                  <a href='pages/mailbox/mailbox.html' className='nav-link'>
                    <i className='far fa-circle nav-icon' />
                    <p>Inbox</p>
                  </a>
                </li>
                <li className='nav-item'>
                  <a href='pages/mailbox/compose.html' className='nav-link'>
                    <i className='far fa-circle nav-icon' />
                    <p>Compose</p>
                  </a>
                </li>
                <li className='nav-item'>
                  <a href='pages/mailbox/read-mail.html' className='nav-link'>
                    <i className='far fa-circle nav-icon' />
                    <p>Read</p>
                  </a>
                </li>
              </ul>
            </li>
            <li className='nav-item has-treeview'>
              <a href='#' className='nav-link'>
                <i className='nav-icon fas fa-book' />
                <p>
                  Pages
                  <i className='fas fa-angle-left right' />
                </p>
              </a>
              <ul className='nav nav-treeview'>
                <li className='nav-item'>
                  <a href='pages/examples/invoice.html' className='nav-link'>
                    <i className='far fa-circle nav-icon' />
                    <p>Invoice</p>
                  </a>
                </li>
                <li className='nav-item'>
                  <a href='pages/examples/profile.html' className='nav-link'>
                    <i className='far fa-circle nav-icon' />
                    <p>Profile</p>
                  </a>
                </li>
                <li className='nav-item'>
                  <a href='pages/examples/e-commerce.html' className='nav-link'>
                    <i className='far fa-circle nav-icon' />
                    <p>E-commerce</p>
                  </a>
                </li>
                <li className='nav-item'>
                  <a href='pages/examples/projects.html' className='nav-link'>
                    <i className='far fa-circle nav-icon' />
                    <p>Projects</p>
                  </a>
                </li>
                <li className='nav-item'>
                  <a
                    href='pages/examples/project-add.html'
                    className='nav-link'
                  >
                    <i className='far fa-circle nav-icon' />
                    <p>Project Add</p>
                  </a>
                </li>
                <li className='nav-item'>
                  <a
                    href='pages/examples/project-edit.html'
                    className='nav-link'
                  >
                    <i className='far fa-circle nav-icon' />
                    <p>Project Edit</p>
                  </a>
                </li>
                <li className='nav-item'>
                  <a
                    href='pages/examples/project-detail.html'
                    className='nav-link'
                  >
                    <i className='far fa-circle nav-icon' />
                    <p>Project Detail</p>
                  </a>
                </li>
                <li className='nav-item'>
                  <a href='pages/examples/contacts.html' className='nav-link'>
                    <i className='far fa-circle nav-icon' />
                    <p>Contacts</p>
                  </a>
                </li>
              </ul>
            </li>
            <li className='nav-item has-treeview'>
              <a href='#' className='nav-link'>
                <i className='nav-icon far fa-plus-square' />
                <p>
                  Extras
                  <i className='fas fa-angle-left right' />
                </p>
              </a>
              <ul className='nav nav-treeview'>
                <li className='nav-item'>
                  <a href='pages/examples/login.html' className='nav-link'>
                    <i className='far fa-circle nav-icon' />
                    <p>Login</p>
                  </a>
                </li>
                <li className='nav-item'>
                  <a href='pages/examples/register.html' className='nav-link'>
                    <i className='far fa-circle nav-icon' />
                    <p>Register</p>
                  </a>
                </li>
                <li className='nav-item'>
                  <a
                    href='pages/examples/forgot-password.html'
                    className='nav-link'
                  >
                    <i className='far fa-circle nav-icon' />
                    <p>Forgot Password</p>
                  </a>
                </li>
                <li className='nav-item'>
                  <a
                    href='pages/examples/recover-password.html'
                    className='nav-link'
                  >
                    <i className='far fa-circle nav-icon' />
                    <p>Recover Password</p>
                  </a>
                </li>
                <li className='nav-item'>
                  <a href='pages/examples/lockscreen.html' className='nav-link'>
                    <i className='far fa-circle nav-icon' />
                    <p>Lockscreen</p>
                  </a>
                </li>
                <li className='nav-item'>
                  <a
                    href='pages/examples/legacy-user-menu.html'
                    className='nav-link'
                  >
                    <i className='far fa-circle nav-icon' />
                    <p>Legacy User Menu</p>
                  </a>
                </li>
                <li className='nav-item'>
                  <a
                    href='pages/examples/language-menu.html'
                    className='nav-link'
                  >
                    <i className='far fa-circle nav-icon' />
                    <p>Language Menu</p>
                  </a>
                </li>
                <li className='nav-item'>
                  <a href='pages/examples/404.html' className='nav-link'>
                    <i className='far fa-circle nav-icon' />
                    <p>Error 404</p>
                  </a>
                </li>
                <li className='nav-item'>
                  <a href='pages/examples/500.html' className='nav-link'>
                    <i className='far fa-circle nav-icon' />
                    <p>Error 500</p>
                  </a>
                </li>
                <li className='nav-item'>
                  <a href='pages/examples/pace.html' className='nav-link'>
                    <i className='far fa-circle nav-icon' />
                    <p>Pace</p>
                  </a>
                </li>
                <li className='nav-item'>
                  <a href='pages/examples/blank.html' className='nav-link'>
                    <i className='far fa-circle nav-icon' />
                    <p>Blank Page</p>
                  </a>
                </li>
                <li className='nav-item'>
                  <a href='starter.html' className='nav-link'>
                    <i className='far fa-circle nav-icon' />
                    <p>Starter Page</p>
                  </a>
                </li>
              </ul>
            </li>
            <li className='nav-header'>MISCELLANEOUS</li>
            <li className='nav-item'>
              <a href='https://adminlte.io/docs/3.0' className='nav-link'>
                <i className='nav-icon fas fa-file' />
                <p>Documentation</p>
              </a>
            </li>
            <li className='nav-header'>MULTI LEVEL EXAMPLE</li>
            <li className='nav-item'>
              <a href='#' className='nav-link'>
                <i className='fas fa-circle nav-icon' />
                <p>Level 1</p>
              </a>
            </li>
            <li className='nav-item has-treeview'>
              <a href='#' className='nav-link'>
                <i className='nav-icon fas fa-circle' />
                <p>
                  Level 1
                  <i className='right fas fa-angle-left' />
                </p>
              </a>
              <ul className='nav nav-treeview'>
                <li className='nav-item'>
                  <a href='#' className='nav-link'>
                    <i className='far fa-circle nav-icon' />
                    <p>Level 2</p>
                  </a>
                </li>
                <li className='nav-item has-treeview'>
                  <a href='#' className='nav-link'>
                    <i className='far fa-circle nav-icon' />
                    <p>
                      Level 2
                      <i className='right fas fa-angle-left' />
                    </p>
                  </a>
                  <ul className='nav nav-treeview'>
                    <li className='nav-item'>
                      <a href='#' className='nav-link'>
                        <i className='far fa-dot-circle nav-icon' />
                        <p>Level 3</p>
                      </a>
                    </li>
                    <li className='nav-item'>
                      <a href='#' className='nav-link'>
                        <i className='far fa-dot-circle nav-icon' />
                        <p>Level 3</p>
                      </a>
                    </li>
                    <li className='nav-item'>
                      <a href='#' className='nav-link'>
                        <i className='far fa-dot-circle nav-icon' />
                        <p>Level 3</p>
                      </a>
                    </li>
                  </ul>
                </li>
                <li className='nav-item'>
                  <a href='#' className='nav-link'>
                    <i className='far fa-circle nav-icon' />
                    <p>Level 2</p>
                  </a>
                </li>
              </ul>
            </li>
            <li className='nav-item'>
              <a href='#' className='nav-link'>
                <i className='fas fa-circle nav-icon' />
                <p>Level 1</p>
              </a>
            </li>
            <li className='nav-header'>LABELS</li>
            <li className='nav-item'>
              <a href='#' className='nav-link'>
                <i className='nav-icon far fa-circle text-danger' />
                <p className='text'>Important</p>
              </a>
            </li>
            <li className='nav-item'>
              <a href='#' className='nav-link'>
                <i className='nav-icon far fa-circle text-warning' />
                <p>Warning</p>
              </a>
            </li>
            <li className='nav-item'> 
              <a href='#' className='nav-link'>
                <i className='nav-icon far fa-circle text-info' />
                <p>Informational</p>
              </a>
            </li> */}
            </ul>
          </nav>
          {/* /.sidebar-menu */}
        </div>
        {/* /.sidebar */}
      </aside>
    );
  }
}

export default withRouter(Sidebar);
