import React from 'react';

export default function Footer() {
  return (
    <footer className='main-footer bg-dark-pri' style={{ position: "fixed", bottom: "0" , right: "0" }}>
      <strong>
        Copyright © 2020-2029 <a href='https://www.thanos.com'>Cyber Auto Bank</a>.
      </strong>
      All rights reserved.
      <div className='float-right d-none d-sm-inline-block'>
        <b>Version</b> 1.0.5
      </div>
    </footer>
  );
}
